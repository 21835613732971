import { Component, OnInit } from '@angular/core';
import { Accessory } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { AccessoriesService } from '@services/accessories/accessories.service';
import { BaseComponent } from '@shared/base/base.component';

@Component({
  selector: 'app-accessories',
  templateUrl: './accessories.component.html',
  styleUrls: ['./accessories.component.scss']
})
export class AccessoriesComponent extends BaseComponent implements OnInit {

  selectedAccessory: Accessory = null;
  accessories: Accessory[] = [];
  filteredAccessories: Accessory[] = [];
  createNewAccessory = false;
  currentAccessory: { value, from } = { value: '', from: '' };
  constructor(
    private readonly eventBusService: EventBusService,
    private readonly accessoriesService: AccessoriesService,
  ) {
    super();
    this.subscriptions.push(
      this.accessoriesService.getAccessories().subscribe((accessories: Accessory[]) => {
        this.accessories = accessories;
        this.filteredAccessories = this.accessories;
        this.filterAccessories(this.currentAccessory);
        if (this.selectedAccessory) {
          const id = this.selectedAccessory.id;
          this.selectedAccessory = this.accessories.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterAccessoriesEvent.subscribe((accessory) => this.filterAccessories(accessory))
    );

  }

  ngOnInit(): void {

  }

  selectAccessory(accessory) {
    this.createNewAccessory = false;
    this.selectedAccessory = accessory;
  }

  filterAccessories(accessory: { value, from }) {
    if (!accessory.from || !accessory.value) {
      this.filteredAccessories = this.accessories;
      return;
    }
    this.currentAccessory = accessory;
    this.filteredAccessories = this.accessories.filter((el) => {
      if (accessory.from === 'name') {
        return el.serialNumber.toLowerCase().includes(accessory.value.toLowerCase());
      }
      if (accessory.from === 'serialNumber') {
        return el.serialNumber.toLowerCase().includes(accessory.value.toLowerCase());
      }
      return true;
    });
  }


}
