import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { Client, Maintenance, Generator, ClientContact, UpdateMaintenanceInput } from '@app/graphql/generated/graphql';
import { NewWorkReportComponent } from '@pages/add/new-workReport/new-workReport.component';
import { EventBusService } from '@services/event-bus.service';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { Constants } from '@shared/constants';


@Component({
  selector: 'app-maintenance-event-detail',
  templateUrl: './maintenance-event-detail.component.html',
  styleUrls: ['./maintenance-event-detail.component.scss']
})
export class MaintenanceEventDetailComponent implements OnInit {
  client: Client = null;
  maintenance: Maintenance = null;
  generator: Generator = null;
  contacts: ClientContact[] = [];
  isMaintenanceCompleted: boolean;
  constructor(
    public dialogRef: MatDialogRef<MaintenanceEventDetailComponent>,
    private readonly maintenancesService: MaintenancesService,
    private readonly eventBusService: EventBusService,
    private router: Router,
    private dialogService: MatDialog,
    @Inject(MAT_DIALOG_DATA) public dialogData,
  ) {
    if (dialogData.selectedMaintenance) {
      this.maintenance = dialogData.selectedMaintenance;
      this.isMaintenanceCompleted = this.maintenance.completed;
      if (this.maintenance.generator) {
        this.generator = this.maintenance.generator;
        if (this.maintenance.generator.client) {
          this.client = this.maintenance.generator.client;
        }
        if (this.maintenance.generator && this.maintenance.generator.contacts) {
          this.contacts = this.maintenance.generator.contacts;
        }
      }
    }
  }

  ngOnInit(): void {
  }

  formatAddress(el: any) {
    return el.address + ', ' + el.city + ', ' + el.postCode;
  }

  getFilterCategory(el) {
    const categories = Constants.FILTER_CATEGORIES;
    return categories.filter((cat) => cat.value === el)[0].name;
  }

  getOperationName(operation) {
    const operations = Constants.TASK_OPERATIONS;
    return operations.filter((op) => op.value === operation)[0].name;
  }

  async changeMaintenanceStatus() {
    try {
      const payload = {
        completed: !this.isMaintenanceCompleted,
      };
      await this.updateMaintenance(this.maintenance, payload);
      this.isMaintenanceCompleted = !this.isMaintenanceCompleted;
    } catch (err) {
    }
  }

  createWorkReport() {
    // this.router.navigate(['/add/workReport'], {queryParams: {maintenanceId: this.maintenance.id}});
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = [''];
    dialogConfig.width = '70%';
      dialogConfig.data = {
        selectedWorkReport: this.maintenance.workReport ? this.maintenance.workReport : undefined,
        selectedMaintenanceId: this.maintenance.id,
      };
    const dialogRef = this.dialogService.open(
      NewWorkReportComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
      }
    });
    this.dialogRef.close();
  }

  async updateMaintenance(maintenance: Maintenance, payload: any) {
    payload.maintenanceId = maintenance.id;
    const mutation = this.maintenancesService.updateMaintenance(payload as UpdateMaintenanceInput);
    const message = 'Manutenzione aggiornata con successo';
    try {
      await mutation.toPromise();
      this.eventBusService.snackBarRequestEvent.next({
        message,
        style: Constants.SNACKBAR_SUCCESS
      });
    } catch (err) {
      console.log(err);
      this.eventBusService.snackBarRequestEvent.next({
        errorCode: 'Si è verificato un problema',
        style: Constants.SNACKBAR_ERROR
      });
    }
  }

}
