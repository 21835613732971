<div class="main-container w-100 h-100">
    <div class="select-maintenances-div">
        <app-select-maintenance></app-select-maintenance>
    </div>
    <div class="row no-margin maintenances-container">
        <div class="col-12 h-100 maintenance-card-div">
          <!-- <mat-card class="maintenance-card pointer" *ngFor="let maintenance of filteredMaintenances" (click)="selectMaintenance(maintenance)">{{ maintenance.client.name }} - {{ maintenance.rentalAmount | currency: 'EUR' }}
           </mat-card>-->
            <app-maintenances-calendar [maintenances]="filteredMaintenances" (changeIntervalDate)="changeIntervalDate($event)"></app-maintenances-calendar>
        </div>
    </div>
</div>