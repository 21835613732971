import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Generator, Maintenance } from '@app/graphql/generated/graphql';
import { NewMaintenanceComponent } from '@pages/add/new-maintenance/new-maintenance.component';

@Component({
  selector: 'app-maintenances-of-generator',
  templateUrl: './maintenances-of-generator.component.html',
  styleUrls: ['./maintenances-of-generator.component.scss']
})
export class MaintenancesOfGeneratorComponent implements OnInit {
  @Input() selectedGenerator: Generator = null;
  maintenances: Maintenance[] = [];
  displayedColumns: string[] = ['startFormattedDate', 'technicianNames'];
  columnLabels = ['Data', 'Tecnici'];
  modalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  constructor() { }

  ngOnInit(): void {
    if (this.selectedGenerator) {
      this.maintenances = this.formatMaintenances(this.selectedGenerator.maintenances);

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'new-maintenance-modal';
      dialogConfig.height = 'auto';
      dialogConfig.width = '70%';
      dialogConfig.data = {
        selectedGenerator: this.selectedGenerator,
      };
      this.modalConfig = {
        dialogConfig,
        key: 'selectedMaintenance',
        component: NewMaintenanceComponent
      };
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if ('selectedGenerator' in changes && changes.selectedGenerator.previousValue) {
      this.selectedGenerator = changes.selectedGenerator.currentValue;
      if(this.selectedGenerator.maintenances) {
        this.maintenances = this.selectedGenerator.maintenances;
        this.maintenances = this.formatMaintenances(this.maintenances);
      } else {
        this.maintenances = [];
      }
    }
  }

  formatMaintenances(maintenances: Maintenance[]) {
    maintenances = JSON.parse(JSON.stringify(maintenances)).map( el => {
      el.startFormattedDate = new DatePipe('en-US').transform(el.startDate, 'dd/MM/yyyy');
      el.technicianNames = el.technicians.map(technician => technician.firstName + ' ' + technician.lastName);
      return el;
    });
    return maintenances;
  }

}
