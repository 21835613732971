<div class="main-container h-100 w-100">
    <div class="select-carrier-div">
        <app-select-carrier></app-select-carrier>
    </div>

    <div class="row no-margin carriers-container">
        <div class="col-3">
            <button class="w-100 add-carrier-button" mat-button (click)="createNewCarrier = true; selectedCarrier = null">
                <mat-icon class="new-carrier-icon" svgIcon="plus"></mat-icon>
                INSERISCI VETTORE
            </button>
        </div>
        <div class="col-9">
        </div>
        <div class="col-3 h-100 carrier-card-div">
            <mat-card class="carrier-card pointer" *ngFor="let carrier of filteredCarriers" [ngClass]="{selected: selectedCarrier && selectedCarrier.id === carrier.id}" (click)="selectCarrier(carrier)">
                {{ carrier.name }}
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="createNewCarrier">
            <mat-card class="card">
                <app-new-carrier></app-new-carrier>
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="!createNewCarrier">
            <app-carrier-page [(selectedCarrier)]="selectedCarrier"></app-carrier-page>
        </div>
    </div>
</div>