import { Component, OnInit, SimpleChanges } from '@angular/core';

import { DDTsService } from '@services/ddts/ddts.service';
import { Ddt } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { environment } from '@env/environment';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-ddts',
  templateUrl: './ddts.component.html',
  styleUrls: ['./ddts.component.scss']
})
export class DDTsComponent extends BaseComponent implements OnInit {
  // ddts: Ddt[] = [];
  filteredDDTs: Ddt[] = [];
  selectedDDT: Ddt = null;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly ddtsService: DDTsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.ddtsService.getDDTs({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((ddts: Ddt[]) => {
        // this.ddts = ddts;
        this.filteredDDTs = ddts;
        // this.filterDDTs();
        if (this.selectedDDT) {
          const id = this.selectedDDT.id;
          this.selectedDDT = this.filteredDDTs.filter((el) => el.id === id)[0];
        }
        this.eventBusService.showSpinnerEvent.next(false);
      }),
      this.eventBusService.filterDDTsEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterDDTs()
      })
    );

  }

  ngOnInit(): void {
  }

  selectDDT(el) {
    this.selectedDDT = el;
  }


  filterDDTs() {
    this.eventBusService.showSpinnerEvent.next(true);
    this.selectedDDT = null;
    this.ddtsService.getDDTs(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((ddts: Ddt[]) => {
      this.filteredDDTs = ddts;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredDDTs.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredDDTs[this.filteredDDTs.length - 1].id;
      this.ddtsService.moreDDTs(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // TODO: remove
  // filterDDTs(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredDDTs = this.ddts;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredDDTs = this.ddts.filter((el) => {
  //     if (filter.from === 'carrierName') {
  //       return el.carrier.name.toLowerCase().includes(filter.value.toLowerCase());
  //     }
  //     return true;
  //   });
  // }


  download(id) {
    window.open(environment.apiUrlPdf + '/pdfs/ddts/' + id, '_blank');
    return true;
  }




}
