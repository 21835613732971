import { CurrencyPipe, DatePipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Client, Contract, Ddt, Generator, Invoice, Rental } from '@app/graphql/generated/graphql';
import { NewClientContactComponent } from '@pages/add/new-client-contact/new-client-contact.component';
import { NewContractComponent } from '@pages/add/new-contract/new-contract.component';
import { NewDDTComponent } from '@pages/add/new-ddt/new-ddt.component';
import { NewGeneratorComponent } from '@pages/add/new-generator/new-generator.component';
import { NewRentalComponent } from '@pages/add/new-rental/new-rental.component';
import { NewInvoiceComponent } from '@pages/other/invoices/new-invoice/new-invoice.component';
import { EventBusService } from '@services/event-bus.service';
import { InvoicesService } from '@services/invoices/invoices.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-client-page',
  templateUrl: './client-page.component.html',
  styleUrls: ['./client-page.component.scss']
})
export class ClientPageComponent extends BaseComponent implements OnInit {
  @Input() selectedClient: Client = null;
  currentTab = 0;
  generators: Generator[] = [];
  contracts: Contract[] = [];
  rentals: Rental[] = [];
  ddts: Ddt[] = [];
  invoices: Invoice[] = [];

  contactsDisplayedColumns: string[] = ['lastName', 'firstName', 'phone', 'email'];
  contactsColumnLabels = ['Cognome', 'Nome', 'Telefono', 'Email'];

  generatorsDisplayedColumns: string[] = ['generatorCode', 'serialNumber', 'formattedStatus', 'fullAddress'];
  generatorsColumnLabels = ['Codice', 'Numero seriale', 'Stato', 'Indirizzo'];

  contractsDisplayedColumns: string[] = ['formattedRentalDate', 'formattedGenerators', 'rentalDays', 'rentalAmount'];
  contractsColumnLabels = ['Data noleggio', 'Generatori', 'N. giorni noleggio', 'Totale noleggio'];

  rentalsDisplayedColumns: string[] = ['formattedDate', 'formattedGenerator', 'formattedStatus'];
  rentalsColumnLabels = ['Data', 'Generatore', 'Stato'];

  ddtsDisplayedColumns: string[] = ['formattedDate', 'fullAddress', 'formattedCarrier'];
  ddtsColumnLabels = ['Data', 'Indirizzo', 'Corriere'];

  invoicesDisplayedColumns: string[] = ['number', 'amount'];
  invoicesColumnLabels = ['Numero', 'Totale'];


  contactsModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  generatorsModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  contractsModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  rentalsModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  ddtsModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  invoicesModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;

  constructor(
    private readonly invoicesService: InvoicesService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.eventBusService.reloadInvoicesEvent.subscribe(() => {
        this.invoicesService.getInvoices(this.selectedClient.id).subscribe(res => {
          if (res && res.length > 0) {
            this.invoices = this.formatInvoices(res as Invoice[]);
          }
          this.setModal();
        });
      })
    );
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedClient' in changes && changes.selectedClient.currentValue) {
      this.selectedClient = changes.selectedClient.currentValue;
      this.generators = [];
      if (this.selectedClient.generators.length > 0) {
        this.generators = this.formatGenerators();
      }
      this.contracts = [];
      if (this.selectedClient.contracts.length > 0) {
        this.contracts = this.formatContracts();
      }
      this.rentals = [];
      if (this.selectedClient.rentals.length > 0) {
        this.rentals = this.formatRentals();
      }
      this.ddts = [];
      if (this.selectedClient.ddts.length > 0) {
        this.ddts = this.formatDdts();
      }
      this.invoices = [];
      this.invoicesService.getInvoices(this.selectedClient.id).subscribe(res => {
        if (res && res.length > 0) {
          this.invoices = this.formatInvoices(res as Invoice[]);
        }
        this.setModal();
      });
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
    this.setModal();
  }

  setModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'new-client-contact-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    switch (this.currentTab) {
      case 0:
        dialogConfig.data = {
          clientId: this.selectedClient.id,
        };
        this.contactsModalConfig = {
          dialogConfig,
          key: 'selectedContact',
          component: NewClientContactComponent
        };
        break;
      case 1:
        dialogConfig.data = {
        };
        this.generatorsModalConfig = {
          dialogConfig,
          key: 'selectedGenerator',
          component: NewGeneratorComponent
        };
        break;
      case 2:
        dialogConfig.data = {
        };
        this.contractsModalConfig = {
          dialogConfig,
          key: 'selectedContract',
          component: NewContractComponent
        };
        break;
      case 3:
        dialogConfig.data = {
        };
        this.rentalsModalConfig = {
          dialogConfig,
          key: 'selectedRental',
          component: NewRentalComponent
        };
        break;
      case 4:
        dialogConfig.data = {
        };
        this.ddtsModalConfig = {
          dialogConfig,
          key: 'selectedDDT',
          component: NewDDTComponent
        };
        break;
      case 5:
        dialogConfig.data = {
        };
        this.invoicesModalConfig = {
          dialogConfig,
          key: 'selectedInvoice',
          component: NewInvoiceComponent
        };
        break;
      default:
        break;
    }
  }

  formatGenerators() {
    return JSON.parse(JSON.stringify(this.selectedClient.generators)).map((el: any) => {
      const address = el.address ? el.address : 'N/A';
      const city = el.city ? el.city : 'N/A';
      const posteCode = el.postCode ? el.postCode : 'N/A';
      el.fullAddress = address + ', ' + posteCode + ', ' + city;
      el.formattedStatus = Constants.GENERATOR_STATES.filter(state => state.value === el.status)[0].name;
      return el;
    });
  }

  formatContracts() {
    return JSON.parse(JSON.stringify(this.selectedClient.contracts)).map((el: any) => {
      el.formattedRentalDate = new DatePipe('en-US').transform(el.rentalDate, 'dd/MM/yyyy');
      el.formattedGenerators = el.generators.map(gen => gen.serialNumber).join(',');
      el.client = this.selectedClient;
      return el;
    });
  }

  formatRentals() {
    return JSON.parse(JSON.stringify(this.selectedClient.rentals)).map((el: any) => {
      el.formattedDate = new DatePipe('en-US').transform(el.date, 'dd/MM/yyyy');
      el.client = this.selectedClient;
      el.formattedGenerator = el.generator.serialNumber;
      el.formattedStatus = Constants.RENTAL_STATUS.filter(state => state.value === el.status)[0].name;
      if (el.ddt) {
        el.ddt.client = this.selectedClient;
      }
      return el;
    });
  }

  formatDdts() {
    return JSON.parse(JSON.stringify(this.selectedClient.ddts)).map((el: any) => {
      const address = el.address ? el.address : 'N/A';
      const city = el.city ? el.city : 'N/A';
      const posteCode = el.postCode ? el.postCode : 'N/A';
      el.fullAddress = address + ', ' + posteCode + ', ' + city;
      el.formattedDate = new DatePipe('en-US').transform(el.date, 'dd/MM/yyyy');
      if (el.carrier) {
        el.formattedCarrier = el.carrier.name;
      }
      el.client = this.selectedClient;
      return el;
    });
  }

  formatInvoices(invoices: Invoice[]) {
    return JSON.parse(JSON.stringify(invoices)).map((el: any) => {
      el.amount = new CurrencyPipe('en-US').transform(el.amount, 'EUR');
      return el;
    });
  }

}
