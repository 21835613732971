<div class="main-container w-100 h-100">
    <div class="select-user-div">
        <app-select-client></app-select-client>
    </div>
    <div class="row no-margin clients-container">
        <div class="col-3 h-100 client-card-div"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
        >
           <mat-card class="client-card pointer" *ngFor="let client of filteredClients" (click)="selectClient(client)" [ngClass]="{selected: selectedClient && selectedClient.id === client.id}">{{ client.clientCode }} - {{ client.name }}</mat-card> 
        </div>
        <div class="col-9 h-100">
           <app-client-page [(selectedClient)]="selectedClient"></app-client-page> 
        </div>
    </div>
</div>