import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, Form } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';

import { CountriesService } from '@services/rest-countries/countries.service';
import { ClientsService } from '@services/clients/clients.service';
import { Client } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.scss']
})
export class NewClientComponent extends SubmitWithConfirmationComponent implements OnInit, OnChanges {
  @Input() selectedClient: Client = null;
  addClientForm: FormGroup;
  settingsControl = {
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    clientCode: new FormControl({value: '', disabled: true}, [Validators.required]),
    VATnumber: new FormControl('', [Validators.required]),
    fiscalCode: new FormControl('', []),
    address: new FormControl('', [Validators.required]),
    postCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    // region: new FormControl('', [Validators.required]),
    country: new FormControl('Italy', [Validators.required]),
    phone: new FormControl('', []),
    email: new FormControl('', [Validators.email]),
    pec: new FormControl('', [Validators.email]),
    sdiCode: new FormControl('', []),
  };
  countries: string[] = [];
  filteredCountries: Observable<string[]>;

  constructor(
    private formBuilder: FormBuilder,
    private readonly clientService: ClientsService,
    private readonly eventBusService: EventBusService,
    private readonly countriesService: CountriesService,
    private dialog: MatDialog,
  ) {
    super(dialog);
    this.addClientForm = formBuilder.group({
      name: this.settingsControl.name,
      clientCode: this.settingsControl.clientCode,
      VATnumber: this.settingsControl.VATnumber,
      fiscalCode: this.settingsControl.fiscalCode,
      address: this.settingsControl.address,
      postCode: this.settingsControl.postCode,
      city: this.settingsControl.city,
      province: this.settingsControl.province,
      // region: this.settingsControl.region,
      country: this.settingsControl.country,
      phone: this.settingsControl.phone,
      email: this.settingsControl.email,
      pec: this.settingsControl.pec,
      sdiCode: this.settingsControl.sdiCode,
    });
  }

  ngOnInit(): void {
    this.countriesService.getCountries().subscribe((countries: any[]) => {
      this.countries = countries.map((el) => el.name);
    });
    this.filteredCountries = this.settingsControl.country.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    if (this.selectedClient) {
      this.addClientForm.patchValue(this.selectedClient);
    }/* else {
      // FIXME: remove
      /*this.addClientForm.patchValue({
        name: 'Luzzi Sas',
        clientCode: '12300000',
        VATnumber: '362399999',
        fiscalCode: '347893292',
        address: 'Via Saffi',
        postCode: '40131',
        city: 'Bologna',
        province: 'Bologna',
        region: 'Emilia-Romagna',
        country: 'Italy',
        phone: '345678902',
        email: 'luzzi@luzzisas.it',
        pec: 'luzzi@pec.it',
        sdiCode: '12345'
      });
    }*/

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedClient' in changes) {
      this.selectedClient = changes.selectedClient.currentValue;
      this.addClientForm.patchValue(this.selectedClient);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  submit() {
    const payload = this.addClientForm.value;
    let mutation: Observable<any>;
    let message = 'Cliente inserito con successo';
    if (this.selectedClient) { // update
      payload.clientId = this.selectedClient.id; // manually add the clientCode
      mutation = this.clientService.updateClient(payload);
      message = 'Cliente aggiornato con successo';
    } else {
      mutation = this.clientService.addNewClient(payload);
      message = 'Cliente inserito con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedClient) {
          this.addClientForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedClient) { // form for adding a client
      return true;
    } else {
      const formValue = this.addClientForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedClient[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
      this.addClientForm.patchValue(this.selectedClient);
  }

}
