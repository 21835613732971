import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  TechniciansGQL,
  CreateTechnicianGQL,
  UpdateTechnicianGQL,
  CreateTechnicianInput,
  UpdateTechnicianInput,
  TechniciansDocument
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class TechniciansService {

  constructor(
    private readonly techniciansService: TechniciansGQL,
    private readonly createTechnicianService: CreateTechnicianGQL,
    private readonly updateTechnicianService: UpdateTechnicianGQL,
  ) { }

  /*queries*/
  getTechnicians() {
    return this.techniciansService.watch().valueChanges.pipe(map(val => {
      return val.data.technicians.edges.map(el => el.node);
    }));
  }

  /*mutations*/
  addNewTechnician(payload: CreateTechnicianInput) {
    return this.createTechnicianService.mutate({ data: payload }, {refetchQueries: [{query: TechniciansDocument}]});
    // return this.createFilterService.mutate({ data: payload });
  }

  updateTechnician(payload: UpdateTechnicianInput) {
    return this.updateTechnicianService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateTechnician;
    }));
  }

}
