import { Component, OnInit } from '@angular/core';
import { Filter } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { FiltersService } from '@services/filters/filters.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent extends BaseComponent implements OnInit {

  selectedFilter: Filter = null;
  // filters: Filter[] = [];
  filteredFilters: Filter[] = [];
  createNewFilter = false;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly eventBusService: EventBusService,
    private readonly filtersService: FiltersService,
  ) {
    super();
    this.subscriptions.push(
      this.filtersService.getFilters({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((filters: Filter[]) => {
        // this.filters = filters;
        this.filteredFilters = filters;
        // this.filterFilters(this.currentFilter);
        if (this.selectedFilter) {
          const id = this.selectedFilter.id;
          this.selectedFilter = this.filteredFilters.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterFiltersEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterFilters()
      })
    );

  }

  ngOnInit(): void {

  }

  selectFilter(filter) {
    this.createNewFilter = false;
    this.selectedFilter = filter;
  }

  filterFilters() {
  this.eventBusService.showSpinnerEvent.next(true);
    this.selectedFilter = null;
    this.filtersService.getFilters(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((filters: Filter[]) => {
      this.filteredFilters = filters;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredFilters.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredFilters[this.filteredFilters.length - 1].id;
      this.filtersService.moreFilters(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // TODO: remove
  // filterFilters(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredFilters = this.filters;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredFilters = this.filters.filter((el) => {
  //     if (filter.from === 'category' || filter.from === 'brand') {
  //       return el.brand === filter.value;
  //     }
  //     if (filter.from === 'code') {
  //       return el.code.toLowerCase().includes(filter.value.toLowerCase());
  //     }
  //     return true;
  //   });
  // }


}
