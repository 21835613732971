import { Component, OnInit } from '@angular/core';
import { Carrier } from '@app/graphql/generated/graphql';
import { CarriersService } from '@services/carriers/carriers.service';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';

@Component({
  selector: 'app-carriers',
  templateUrl: './carriers.component.html',
  styleUrls: ['./carriers.component.scss']
})
export class CarriersComponent extends BaseComponent implements OnInit {

  selectedCarrier: Carrier = null;
  carriers: Carrier[] = [];
  filteredCarriers: Carrier[] = [];
  createNewCarrier = false;
  currentCarrier: { value, from } = { value: '', from: '' };
  constructor(
    private readonly eventBusService: EventBusService,
    private readonly carriersService: CarriersService,
  ) {
    super();
    this.subscriptions.push(
      this.carriersService.getCarriers().subscribe((carriers: Carrier[]) => {
        this.carriers = carriers;
        this.filteredCarriers = this.carriers;
        this.filterCarriers(this.currentCarrier);
        if (this.selectedCarrier) {
          const id = this.selectedCarrier.id;
          this.selectedCarrier = this.carriers.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterCarriersEvent.subscribe((carrier) => this.filterCarriers(carrier))
    );

  }

  ngOnInit(): void {

  }

  selectCarrier(carrier) {
    this.createNewCarrier = false;
    this.selectedCarrier = carrier;
  }

  filterCarriers(carrier: { value, from }) {
    if (!carrier.from || !carrier.value) {
      this.filteredCarriers = this.carriers;
      return;
    }
    this.currentCarrier = carrier;
    this.filteredCarriers = this.carriers.filter((el) => {
      if (carrier.from === 'name') {
        return el.name.toLowerCase().includes(carrier.value.toLowerCase());
      }
      if (carrier.from === 'carrierCode') {
        return el.carrierCode === carrier.value;
      }
      return true;
    });
  }
}
