<div class="filters-div">
    <mat-accordion class="w-100" *ngFor="let cat of filterCategories; let ind = index" class="accordion">
        <mat-expansion-panel class="bg-grey" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="title">
                    FILTRI {{ cat.name | uppercase }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="no-filter" *ngIf="getFilterLinksArray(ind).controls.length === 0">Nessun filtro aggiunto</div>
            <form [formGroup]="filterLinksForms[ind]" *ngIf="getFilterLinksArray(ind).controls.length > 0">
                <div formArrayName="filterLinksArray">
                    <div *ngFor="let item of getFilterLinksArray(ind).controls; let i = index" [formGroupName]="i">
                        <mat-form-field appearance="outline" class="form-input filter-brand">
                            <mat-label>Filtro</mat-label>
                            <mat-select name="filterId" formControlName="filterId" (selectionChange)="makeFiltersList(ind)">
                                <mat-option *ngFor="let filter of filtersPerCategory[ind]" [value]="filter.id" [disabled]="alreadyTaken(ind, filter.id)">
                                    {{ filter.brand }} - {{ filter.code }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="form-input">
                            <mat-label>Quantità</mat-label>
                            <input matInput placeholder="" formControlName="quantity" name="quantity">
                        </mat-form-field>
                        <button class="remove-btn" (click)="removeItem(ind, i)" mat-button color="warn">
                            <mat-icon class="icons" color="warn" svgIcon="delete"></mat-icon>
                            ELIMINA FILTRO
                        </button>
                    </div>
                </div>
            </form>
            <div class="form-buttons-div">
                <button mat-flat-button color="primary" class="add-btn float-left" (click)="addItem(ind)">
                    <mat-icon class="icons" svgIcon="plus"></mat-icon>
                    AGGIUNGI FILTRO
                </button>
                <button mat-stroked-button color="accent" class="save-btn float-right" (click)="submit(ind)" [disabled]="!getFilterLinksArray(ind).valid || !checkDifference(ind)">SALVA</button>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>