import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  InvoicesGQL,
  CreateInvoiceGQL,
  UpdateInvoiceGQL,
  CreateInvoiceInput,
  UpdateInvoiceInput,
  InvoicesDocument,
  InvoiceGQL
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(
    private readonly invoicesService: InvoicesGQL,
    private readonly invoiceService: InvoiceGQL,
    private readonly createInvoiceService: CreateInvoiceGQL,
    private readonly updateInvoiceService: UpdateInvoiceGQL,
  ) { }

  /*queries*/
  getInvoices(clientId?: string, rentalId?: string) {
    return this.invoicesService.watch({clientId, rentalId}, { fetchPolicy: 'network-only' }).valueChanges.pipe(map(val => {
      if (val.data.invoices.edges.length === 0) {
        return val.data.invoices.edges;
      }
      return val.data.invoices.edges.map(el => el.node);
    }));
  }

  getInvoiceById(id: string) {
    return this.invoiceService.watch({id}, { fetchPolicy: 'network-only' }).valueChanges.pipe(map(val => {
      return val.data.invoice;
    }));
  }


  /*mutations*/
  addNewInvoice(payload: CreateInvoiceInput) {
    return this.createInvoiceService.mutate({ data: payload }, {refetchQueries: [{query: InvoicesDocument}]});
    // return this.createFilterService.mutate({ data: payload });
  }

  updateInvoice(payload: UpdateInvoiceInput) {
    return this.updateInvoiceService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateInvoice;
    }));
  }

}
