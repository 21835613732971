<div class="row">
  <div class="col-md-4">
    <div class="btn-group">
      <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="changeDate($event)">
        Precedente
      </div>
      <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
        Oggi 
      </div>
      <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
        (viewDateChange)="changeDate($event)">
        Prossimo
      </div>
    </div>
  </div>
  <div class="col-md-4 text-center">
    <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'it' | uppercase}}</h3>
  </div>
  <div class="col-md-4 text-right">
    <div class="btn-group">
      <div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">
        Mese
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">
        Settimana
      </div>
      <div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
        Giorno
      </div>
    </div>
  </div>
</div>
<br />
<div [ngSwitch]="view">
  <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events" locale="it"
    [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
    (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-month-view>
  <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" locale="it" [events]="events"
    [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-week-view>
  <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" locale="it" [events]="events"
    [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
    (eventTimesChanged)="eventTimesChanged($event)">
  </mwl-calendar-day-view>
</div>