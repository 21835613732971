import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateRentalGQL,
  UpdateRentalGQL,
  CreateRentalInput,
  UpdateRentalInput,
  RentalsGQL,
  RentalsDocument,
  RentalsListGQL
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class RentalsService {

  constructor(
    private readonly rentalsService: RentalsGQL,
    private readonly rentalsListService: RentalsListGQL,
    private readonly createRentalService: CreateRentalGQL,
    private readonly updateRentalService: UpdateRentalGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  getRentals(filter?: any, first?: number, after?: string) {
    return this.rentalsService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.rentals.edges.map(el => el.node);
    }));
  }

  getRentalsList(filter?: any, first?: number, after?: string) {
    return this.rentalsListService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.rentals.edges.map(el => el.node);
    }));
  }

  moreRentals(filter?: any, first?: number, after?: string) {
    return this.rentalsService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  moreRentalsList(filter?: any, first?: number, after?: string) {
    return this.rentalsListService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  /*mutations*/
  addNewRental(payload: CreateRentalInput) {
    // return this.createRentalService.mutate({ data: payload }, {refetchQueries: [{query: RentalsDocument}]});
    return this.createRentalService.mutate({ data: payload });
    // return this.createFilterService.mutate({ data: payload });
  }

  updateRental(payload: UpdateRentalInput) {
    return this.updateRentalService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateRental;
    }));
  }

}
