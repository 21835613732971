import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { InvoiceWorkReportLink, WorkReport } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { WorkReportsService } from '@services/workReports/workReports.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-work-reports',
  templateUrl: './add-workReports.component.html',
  styleUrls: ['./add-workReports.component.scss']
})
export class AddWorkReportsComponent extends BaseComponent implements OnInit {
  @Input() workReportsForms: FormGroup = null;
  @Input() value: InvoiceWorkReportLink[] = null;
  filteredWorkReports: WorkReport[] = [];
  filterWorkReports = {};
  private autocompleteWorkReportsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
    private readonly workReportsService: WorkReportsService,
  ) {
    super();
    this.eventBusService.showSpinnerEvent.next(true);
    this.subscriptions.push(
      this.workReportsService.getWorkReportsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((workReports: WorkReport[]) => {
        this.eventBusService.showSpinnerEvent.next(false);
        this.filteredWorkReports = workReports;
      }),
    );

    this.autocompleteWorkReportsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterWorkReports === '') {
        return;
      } else if (!value) {
        this.filterWorkReports = {};
      } else {
        this.filterWorkReports = { clientName: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.workReportsService.getWorkReportsList({ clientName: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((workReports: WorkReport[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredWorkReports = workReports;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
  }

  ngOnInit(): void {
    if (!this.workReportsForms) {
      this.workReportsForms = this.formBuilder.group({
        workReportsArray: this.formBuilder.array([])
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes && changes.value.currentValue) {
      //     this.workReportsForms.get('workReportsArray').patchValue(changes.value.currentValue);
      // this.workReportsForms.get('workReportsArray').setValue(this.formBuilder.array([]));
      const array = this.getWorkReportsArray();
      array.clear();
      for (const el of changes.value.currentValue) {
        this.getWorkReportsArray().push(
          this.formBuilder.group({
            workReportId: [el.workReport, [Validators.required]],
            amount: [el.amount, [Validators.required]],
          })
        );
      }
    }
  }

  getWorkReportsArray() {
    return this.workReportsForms.get('workReportsArray') as FormArray;
  }

  addItem() {
    this.getWorkReportsArray().push(
      this.formBuilder.group({
        workReportId: ['', [Validators.required]],
        amount: ['', [Validators.required]],
      })
    );
  }

  async removeItem(index: number) {
    this.getWorkReportsArray().removeAt(index);
  }

  moreWorkReports() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredWorkReports.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredWorkReports[this.filteredWorkReports.length - 1].id;
      this.workReportsService.moreWorkReports(this.filterWorkReports, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  autocompleteWorkReports(ev) {
    this.autocompleteWorkReportsSubject.next(ev.target.value);
  }

  displayFnWorkReports(workReport: WorkReport): string {
    console.log(workReport)
    if (!workReport) {
      return '';
    }
    return workReport.maintenance ?
      `${workReport.maintenance.generator.generatorCode} - ${workReport.client.name}  -
      ${moment(workReport.maintenance.startDate).format('DD/MM/YYYY')}` : workReport.client.name;
  }
}
