<div class="main-container h-100 w-100">
    <div class="select-accessory-div">
        <app-select-accessory></app-select-accessory>
    </div>

    <div class="row no-margin accessories-container">
        <div class="col-3">
            <button class="w-100 add-accessory-button" mat-button (click)="createNewAccessory = true; selectedAccessory = null">
                <mat-icon class="new-accessory-icon" svgIcon="plus"></mat-icon>
                INSERISCI ACCESSORIO
            </button>
        </div>
        <div class="col-9">
        </div>
        <div class="col-3 h-100 accessory-card-div">
            <mat-card class="accessory-card pointer" *ngFor="let accessory of filteredAccessories" [ngClass]="{selected: selectedAccessory && selectedAccessory.id === accessory.id}" (click)="selectAccessory(accessory)">
                {{ accessory.name }}
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="createNewAccessory">
            <mat-card class="card">
                <app-new-accessory></app-new-accessory>
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="!createNewAccessory">
            <app-accessory-page [(selectedAccessory)]="selectedAccessory"></app-accessory-page>
        </div>
    </div>
</div>