import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';


import { NavbarComponent } from '@app/navbar/navbar.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from '@pages/home/home.component';
import { LoginComponent } from '@app/login/login.component';
import { SignupComponent } from '@app/signup/signup.component';
import { SharedModule } from '@shared/shared.module';
import { GraphQLModule } from './graphql.module';
import { AppMaterialModule } from './app-material.module';
import { LeftSidebarComponent } from './left-sidebar/left-sidebar.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { MatIconRegistry } from '@angular/material/icon';
import { AddModule } from '@pages/add/add.module';
import { ClientsModule } from '@pages/clients/clients.module';
import { GeneratorsModule } from '@pages/generators/generators.module';
import { OtherModule } from '@pages/other/other.module';
import { RentalsModule } from '@pages/rentals/rentals.module';
import { DDTsModule } from '@pages/ddts/ddts.module';
import { ContractsModule } from '@pages/contracts/contracts.module';
import { WorkReportsModule } from '@pages/workReports/workReports.module';
import { MaintenancesModule } from '@pages/maintenances/maintenances.module';
import { RouterModule } from '@angular/router';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ShowHidePasswordModule,
    SharedModule,
    GraphQLModule,
    AppMaterialModule,
    AddModule,
    ClientsModule,
    RentalsModule,
    DDTsModule,
    GeneratorsModule,
    ContractsModule,
    WorkReportsModule,
    MaintenancesModule,
    OtherModule,
    RouterModule,
    NgxMatNativeDateModule,
    AppRoutingModule,
  ],
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    LeftSidebarComponent,
    RightSidebarComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
