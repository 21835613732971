import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateMaintenanceGQL,
  UpdateMaintenanceGQL,
  CreateMaintenanceInput,
  UpdateMaintenanceInput,
  MaintenancesGQL,
  MaintenancesDocument,
  MaintenancesListGQL,
  MaintenanceGQL,
  CloneMaintenancesInput,
  CloneMaintenancesGQL,
  MaintenancesListDocument,
  DeleteMaintenancesGQL,
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenancesService {
  fromDate: Date;
  toDate: Date;
  constructor(
    private readonly maintenancesService: MaintenancesGQL,
    private readonly maintenancesListService: MaintenancesListGQL,
    private readonly maintenanceService: MaintenanceGQL,
    private readonly createMaintenanceService: CreateMaintenanceGQL,
    private readonly updateMaintenanceService: UpdateMaintenanceGQL,
    private readonly cloneMaintenancesService: CloneMaintenancesGQL,
    private readonly deleteMaintenanceService: DeleteMaintenancesGQL,
    private readonly eventBusService: EventBusService,
  ) { }


  /*queries*/
  // getMaintenances(filter?: any, fromDate?: Date, toDate?: Date) {
  //   this.fromDate = fromDate;
  //   this.toDate = toDate;
  //   return this.maintenancesService.watch({...filter, fromDate, toDate}).valueChanges.pipe(map(val => {
  //     if (val.data.maintenances.edges.length === 0) {
  //       return val.data.maintenances.edges;
  //     }
  //     return val.data.maintenances.edges.map(el => el.node);
  //   }));
  // }

  getMaintenancesList(filter?: any, fromDate?: Date, toDate?: Date) {
    this.fromDate = fromDate;
    this.toDate = toDate;
    return this.maintenancesListService.watch({...filter, fromDate, toDate}).valueChanges.pipe(map(val => {
      if (val.data.maintenances.edges.length === 0) {
        return val.data.maintenances.edges;
      }
      return val.data.maintenances.edges.map(el => el.node);
    }));
  }

  moreMaintenances(filter?: any, fromDate?: Date, toDate?: Date) {
    if(fromDate) {
      this.fromDate = fromDate;
    }
    if (toDate) {
      this.toDate = toDate;
    }
    return this.maintenancesListService.watch().fetchMore({variables: {...filter, fromDate, toDate}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  getMaintenanceById(id: string) {
    return this.maintenanceService.watch({id}).valueChanges.pipe(map(val => {
      return val.data.maintenance;
    }));
  }


  addNewMaintenance(payload: CreateMaintenanceInput) {
    // return this.createMaintenanceService.mutate({ data: payload }, { refetchQueries: [{ query: MaintenancesDocument, variables: {fromDate: this.fromDate, toDate: this.toDate} }] });
    return this.createMaintenanceService.mutate({ data: payload });
  }

  updateMaintenance(payload: UpdateMaintenanceInput) {
    return this.updateMaintenanceService.mutate({ data: payload }, { refetchQueries: [{ query: MaintenancesListDocument, variables: {fromDate: this.fromDate, toDate: this.toDate} }] }).pipe(map(val => {
      return val.data.updateMaintenance;
    }));
  }

  cloneMaintenances(payload: CloneMaintenancesInput) {
    return this.cloneMaintenancesService.mutate({ data: payload }, { refetchQueries: [{ query: MaintenancesListDocument, variables: {fromDate: this.fromDate, toDate: this.toDate} }] }).pipe(map(val => {
      return val.data;
    }));
  }

  deleteMaintenance(payload: {id: string}) {
    return this.deleteMaintenanceService.mutate({ ...payload }, { refetchQueries: [{ query: MaintenancesListDocument, variables: {fromDate: this.fromDate, toDate: this.toDate} }] }).pipe(map(val => {
      return val.data.deleteMaintenance;
    }));
  }

}
