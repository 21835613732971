import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
};



export type Accessory = {
  __typename?: 'Accessory';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AccessoryConnection = {
  __typename?: 'AccessoryConnection';
  edges?: Maybe<Array<AccessoryEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type AccessoryEdge = {
  __typename?: 'AccessoryEdge';
  cursor: Scalars['String'];
  node: Accessory;
};

export type Auth = {
  __typename?: 'Auth';
  /** JWT access token */
  accessToken: Scalars['String'];
};

export type Carrier = {
  __typename?: 'Carrier';
  carrierCode: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  fiscalCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  regAlboCode: Scalars['String'];
  sdiCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  VATnumber: Scalars['String'];
};

export type CarrierConnection = {
  __typename?: 'CarrierConnection';
  edges?: Maybe<Array<CarrierEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type CarrierEdge = {
  __typename?: 'CarrierEdge';
  cursor: Scalars['String'];
  node: Carrier;
};

export type Client = {
  __typename?: 'Client';
  address: Scalars['String'];
  city: Scalars['String'];
  clientCode: Scalars['Int'];
  contacts: Array<ClientContact>;
  contracts: Array<Contract>;
  country: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ddts: Array<Ddt>;
  email?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  generators: Array<Generator>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  province: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  rentals: Array<Rental>;
  sdiCode?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  VATnumber: Scalars['String'];
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  edges?: Maybe<Array<ClientEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ClientContact = {
  __typename?: 'ClientContact';
  client: Client;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClientEdge = {
  __typename?: 'ClientEdge';
  cursor: Scalars['String'];
  node: Client;
};

export type CloneMaintenancesInput = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  translateBy: Scalars['Int'];
};

export type Contract = {
  __typename?: 'Contract';
  accessories?: Maybe<Array<Accessory>>;
  client: Client;
  createdAt: Scalars['DateTime'];
  extendedRental: Scalars['Boolean'];
  generatorInsuranceValue?: Maybe<Scalars['Float']>;
  generators?: Maybe<Array<Generator>>;
  id: Scalars['ID'];
  monthlyPaymentsAmount?: Maybe<Scalars['Float']>;
  paymentMode: PaymentMode;
  paymentTerms?: Maybe<PaymentTerm>;
  rentalAmount?: Maybe<Scalars['Float']>;
  rentalDate: Scalars['DateTime'];
  rentalDays: Scalars['Int'];
  securityDepositAmount?: Maybe<Scalars['Float']>;
  shippingAddress: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContractConnection = {
  __typename?: 'ContractConnection';
  edges?: Maybe<Array<ContractEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type ContractEdge = {
  __typename?: 'ContractEdge';
  cursor: Scalars['String'];
  node: Contract;
};

export type CreateAccessoryInput = {
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
};

export type CreateCarrierInput = {
  email: Scalars['String'];
  fiscalCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  regAlboCode: Scalars['String'];
  sdiCode?: Maybe<Scalars['String']>;
  VATnumber: Scalars['String'];
};

export type CreateClientContactInput = {
  clientId: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateClientInput = {
  address: Scalars['String'];
  city: Scalars['String'];
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode: Scalars['String'];
  province: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  sdiCode?: Maybe<Scalars['String']>;
  VATnumber: Scalars['String'];
};

export type CreateContractInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  clientId: Scalars['ID'];
  extendedRental: Scalars['Boolean'];
  generatorIds?: Maybe<Array<Scalars['ID']>>;
  generatorInsuranceValue?: Maybe<Scalars['Float']>;
  monthlyPaymentsAmount?: Maybe<Scalars['Float']>;
  paymentMode: PaymentMode;
  paymentTerms?: Maybe<PaymentTerm>;
  rentalAmount?: Maybe<Scalars['Float']>;
  rentalDate: Scalars['DateTime'];
  rentalDays: Scalars['Int'];
  securityDepositAmount?: Maybe<Scalars['Float']>;
  shippingAddress: Scalars['String'];
};

export type CreateDdtInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  address?: Maybe<Scalars['String']>;
  carrierId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  generatorIds?: Maybe<Array<Scalars['ID']>>;
  note?: Maybe<Scalars['String']>;
  otherGoods?: Maybe<Array<Scalars['JSONObject']>>;
  packageAppearance: Scalars['String'];
  packagesNumber?: Maybe<Scalars['Int']>;
  paymentPreference: PaymentPreference;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  shippingCharges?: Maybe<ShippingChargesType>;
  totalWeight?: Maybe<Scalars['Float']>;
};

export type CreateFilterGeneratorLinkInput = {
  filterId: Scalars['ID'];
  generatorId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type CreateFilterInput = {
  brand: Scalars['String'];
  category: Scalars['String'];
  code: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type CreateGeneratorInput = {
  address?: Maybe<Scalars['String']>;
  alternatorBrand?: Maybe<Scalars['String']>;
  alternatorSerialNumber?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['ID']>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  country?: Maybe<Scalars['String']>;
  electricityType?: Maybe<ElectricityType>;
  engineBrand?: Maybe<Scalars['String']>;
  engineSerialNumber?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelType>;
  kVA: Scalars['Float'];
  kW: Scalars['Float'];
  postCode?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseYear?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status?: Maybe<GeneratorStatus>;
  type?: Maybe<Scalars['String']>;
};

export type CreateInvoiceInput = {
  amount: Scalars['Float'];
  number: Scalars['String'];
};

export type CreateInvoiceRentalLinkInput = {
  amount: Scalars['Float'];
  invoiceId: Scalars['ID'];
  rentalId: Scalars['ID'];
};

export type CreateInvoiceWorkReportLinkInput = {
  amount: Scalars['Float'];
  invoiceId: Scalars['ID'];
  workReportId: Scalars['ID'];
};

export type CreateMaintenanceInput = {
  completed?: Maybe<Scalars['Boolean']>;
  generatorId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicianIds?: Maybe<Array<Scalars['ID']>>;
};

export type CreateRentalInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  clientId: Scalars['ID'];
  ddtId?: Maybe<Scalars['ID']>;
  generatorId: Scalars['ID'];
  inDate?: Maybe<Scalars['DateTime']>;
  inHours?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  outDate?: Maybe<Scalars['DateTime']>;
  outHours?: Maybe<Scalars['Int']>;
  status: RentalStatus;
};

export type CreateTechnicianInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
};

export type CreateWorkReportInput = {
  cisternFuelLevel?: Maybe<Scalars['Int']>;
  clientId: Scalars['ID'];
  generatorHours?: Maybe<Scalars['Float']>;
  lunchBreak?: Maybe<Scalars['Boolean']>;
  maintenanceId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  operationType: OperationType;
  positiveOperation: Scalars['Boolean'];
  registerCompiled: Scalars['Boolean'];
  registerNotCompiledReason?: Maybe<Scalars['String']>;
  tankFuelLevel?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicianIds: Array<Scalars['ID']>;
  totalKm?: Maybe<Scalars['Float']>;
  workHours?: Maybe<Scalars['Float']>;
};


export type Ddt = {
  __typename?: 'DDT';
  accessories: Array<Accessory>;
  address?: Maybe<Scalars['String']>;
  carrier?: Maybe<Carrier>;
  city?: Maybe<Scalars['String']>;
  client: Client;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  ddtCode: Scalars['Int'];
  generators: Array<Generator>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  otherGoods?: Maybe<Array<Scalars['JSONObject']>>;
  packageAppearance: Scalars['String'];
  packagesNumber?: Maybe<Scalars['Int']>;
  paymentPreference: PaymentPreference;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  shippingCharges?: Maybe<ShippingChargesType>;
  totalWeight?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type DdtConnection = {
  __typename?: 'DDTConnection';
  edges?: Maybe<Array<DdtEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type DdtEdge = {
  __typename?: 'DDTEdge';
  cursor: Scalars['String'];
  node: Ddt;
};

export type DeletedFilterGeneratorLink = {
  __typename?: 'DeletedFilterGeneratorLink';
  deletedFilterId: Scalars['String'];
  deletedGeneratorId: Scalars['String'];
};

export type DeletedInvoiceRentalLink = {
  __typename?: 'DeletedInvoiceRentalLink';
  deletedInvoiceId: Scalars['ID'];
  deletedRentalId: Scalars['ID'];
};

export type DeletedInvoiceWorkReportLink = {
  __typename?: 'DeletedInvoiceWorkReportLink';
  deletedInvoiceId: Scalars['ID'];
  deletedWorkReportId: Scalars['ID'];
};

export type DeleteFilterGeneratorLinkInput = {
  filterId: Scalars['ID'];
  generatorId: Scalars['ID'];
};

export type DeleteInvoiceRentalLinkInput = {
  invoiceId: Scalars['ID'];
  rentalId: Scalars['ID'];
};

export type DeleteInvoiceWorkReportLinkInput = {
  invoiceId: Scalars['ID'];
  workReportId: Scalars['ID'];
};

export enum ElectricityType {
  MonoPhase = 'MONO_PHASE',
  ThreePhase = 'THREE_PHASE'
}

export type Filter = {
  __typename?: 'Filter';
  brand: Scalars['String'];
  category: Scalars['String'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  price?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
};

export type FilterConnection = {
  __typename?: 'FilterConnection';
  edges?: Maybe<Array<FilterEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type FilterEdge = {
  __typename?: 'FilterEdge';
  cursor: Scalars['String'];
  node: Filter;
};

export type FilterGeneratorLink = {
  __typename?: 'FilterGeneratorLink';
  createdAt: Scalars['DateTime'];
  filter: Filter;
  filterId: Scalars['ID'];
  generator: Generator;
  generatorId: Scalars['ID'];
  quantity: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum FuelType {
  Diesel = 'DIESEL',
  Gasoline = 'GASOLINE',
  Gpl = 'GPL',
  Hybrid = 'HYBRID',
  Methane = 'METHANE'
}

export type Generator = {
  __typename?: 'Generator';
  address?: Maybe<Scalars['String']>;
  alternatorBrand?: Maybe<Scalars['String']>;
  alternatorSerialNumber?: Maybe<Scalars['String']>;
  brand: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Client>;
  contacts?: Maybe<Array<ClientContact>>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  electricityType?: Maybe<ElectricityType>;
  engineBrand?: Maybe<Scalars['String']>;
  engineSerialNumber?: Maybe<Scalars['String']>;
  filters: Array<FilterGeneratorLink>;
  fuelType?: Maybe<FuelType>;
  generatorCode: Scalars['Int'];
  id: Scalars['ID'];
  kVA: Scalars['Float'];
  kW: Scalars['Float'];
  maintenances: Array<Maintenance>;
  postCode?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseYear?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  serialNumber: Scalars['String'];
  status?: Maybe<GeneratorStatus>;
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type GeneratorConnection = {
  __typename?: 'GeneratorConnection';
  edges?: Maybe<Array<GeneratorEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type GeneratorEdge = {
  __typename?: 'GeneratorEdge';
  cursor: Scalars['String'];
  node: Generator;
};

export enum GeneratorStatus {
  Available = 'AVAILABLE',
  Goods = 'GOODS',
  Managed = 'MANAGED',
  Rented = 'RENTED'
}

export type Invoice = {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  number: Scalars['String'];
  rentals: Array<InvoiceRentalLink>;
  updatedAt: Scalars['DateTime'];
  workReports: Array<InvoiceWorkReportLink>;
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  edges?: Maybe<Array<InvoiceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  cursor: Scalars['String'];
  node: Invoice;
};

export type InvoiceRentalLink = {
  __typename?: 'InvoiceRentalLink';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  invoice: Invoice;
  invoiceId: Scalars['ID'];
  rental: Rental;
  rentalId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type InvoiceWorkReportLink = {
  __typename?: 'InvoiceWorkReportLink';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  invoice: Invoice;
  invoiceId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  workReport: WorkReport;
  workReportId: Scalars['ID'];
};


export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Maintenance = {
  __typename?: 'Maintenance';
  completed?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['DateTime'];
  endDate?: Maybe<Scalars['DateTime']>;
  generator: Generator;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicians: Array<Technician>;
  updatedAt: Scalars['DateTime'];
  workReport?: Maybe<WorkReport>;
};

export type MaintenanceConnection = {
  __typename?: 'MaintenanceConnection';
  edges?: Maybe<Array<MaintenanceEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type MaintenanceEdge = {
  __typename?: 'MaintenanceEdge';
  cursor: Scalars['String'];
  node: Maintenance;
};

export type Mutation = {
  __typename?: 'Mutation';
  cloneMaintenances: Scalars['Boolean'];
  createAccessory: Accessory;
  createCarrier: Carrier;
  createClient: Client;
  createClientContact: ClientContact;
  createContract: Contract;
  createDDT: Ddt;
  createFilter: Filter;
  createFilterGeneratorLink: FilterGeneratorLink;
  createGenerator: Generator;
  createInvoice: Invoice;
  createInvoiceRentalLink: InvoiceRentalLink;
  createInvoiceWorkReportLink: InvoiceWorkReportLink;
  createMaintenance: Maintenance;
  createRental: Rental;
  createTechnician: Technician;
  createWorkReport: WorkReport;
  deleteFilterGeneratorLink: DeletedFilterGeneratorLink;
  deleteInvoiceRentalLink: DeletedInvoiceRentalLink;
  deleteInvoiceWorkReportLink: DeletedInvoiceWorkReportLink;
  deleteMaintenance: Maintenance;
  login: Auth;
  signup: Auth;
  updateAccessory: Accessory;
  updateCarrier: Carrier;
  updateClient: Client;
  updateClientContact: ClientContact;
  updateContract: Contract;
  updateDDT: Ddt;
  updateFilter: Filter;
  updateFilterGeneratorLink: FilterGeneratorLink;
  updateGenerator: Generator;
  updateInvoice: Invoice;
  updateInvoiceRentalLink: InvoiceRentalLink;
  updateInvoiceWorkReportLink: InvoiceWorkReportLink;
  updateMaintenance: Maintenance;
  updateRental: Rental;
  updateTechnician: Technician;
  updateWorkReport: WorkReport;
};


export type MutationCloneMaintenancesArgs = {
  data: CloneMaintenancesInput;
};


export type MutationCreateAccessoryArgs = {
  data: CreateAccessoryInput;
};


export type MutationCreateCarrierArgs = {
  data: CreateCarrierInput;
};


export type MutationCreateClientArgs = {
  data: CreateClientInput;
};


export type MutationCreateClientContactArgs = {
  data: CreateClientContactInput;
};


export type MutationCreateContractArgs = {
  data: CreateContractInput;
};


export type MutationCreateDdtArgs = {
  data: CreateDdtInput;
};


export type MutationCreateFilterArgs = {
  data: CreateFilterInput;
};


export type MutationCreateFilterGeneratorLinkArgs = {
  data: CreateFilterGeneratorLinkInput;
};


export type MutationCreateGeneratorArgs = {
  data: CreateGeneratorInput;
};


export type MutationCreateInvoiceArgs = {
  data: CreateInvoiceInput;
};


export type MutationCreateInvoiceRentalLinkArgs = {
  data: CreateInvoiceRentalLinkInput;
};


export type MutationCreateInvoiceWorkReportLinkArgs = {
  data: CreateInvoiceWorkReportLinkInput;
};


export type MutationCreateMaintenanceArgs = {
  data: CreateMaintenanceInput;
};


export type MutationCreateRentalArgs = {
  data: CreateRentalInput;
};


export type MutationCreateTechnicianArgs = {
  data: CreateTechnicianInput;
};


export type MutationCreateWorkReportArgs = {
  data: CreateWorkReportInput;
};


export type MutationDeleteFilterGeneratorLinkArgs = {
  data: DeleteFilterGeneratorLinkInput;
};


export type MutationDeleteInvoiceRentalLinkArgs = {
  data: DeleteInvoiceRentalLinkInput;
};


export type MutationDeleteInvoiceWorkReportLinkArgs = {
  data: DeleteInvoiceWorkReportLinkInput;
};


export type MutationDeleteMaintenanceArgs = {
  id: Scalars['String'];
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationSignupArgs = {
  data: SignupInput;
};


export type MutationUpdateAccessoryArgs = {
  data: UpdateAccessoryInput;
};


export type MutationUpdateCarrierArgs = {
  data: UpdateCarrierInput;
};


export type MutationUpdateClientArgs = {
  data: UpdateClientInput;
};


export type MutationUpdateClientContactArgs = {
  data: UpdateClientContactInput;
};


export type MutationUpdateContractArgs = {
  data: UpdateContractInput;
};


export type MutationUpdateDdtArgs = {
  data: UpdateDdtInput;
};


export type MutationUpdateFilterArgs = {
  data: UpdateFilterInput;
};


export type MutationUpdateFilterGeneratorLinkArgs = {
  data: UpdateFilterGeneratorLinkInput;
};


export type MutationUpdateGeneratorArgs = {
  data: UpdateGeneratorInput;
};


export type MutationUpdateInvoiceArgs = {
  data: UpdateInvoiceInput;
};


export type MutationUpdateInvoiceRentalLinkArgs = {
  data: UpdateInvoiceRentalLinkInput;
};


export type MutationUpdateInvoiceWorkReportLinkArgs = {
  data: UpdateInvoiceWorkReportLinkInput;
};


export type MutationUpdateMaintenanceArgs = {
  data: UpdateMaintenanceInput;
};


export type MutationUpdateRentalArgs = {
  data: UpdateRentalInput;
};


export type MutationUpdateTechnicianArgs = {
  data: UpdateTechnicianInput;
};


export type MutationUpdateWorkReportArgs = {
  data: UpdateWorkReportInput;
};

export enum OperationType {
  Extraordinary = 'EXTRAORDINARY',
  Ordinary = 'ORDINARY'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export enum PaymentMode {
  BankCheque = 'BANK_CHEQUE',
  BankDraft = 'BANK_DRAFT',
  BankReceipt = 'BANK_RECEIPT',
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH'
}

export enum PaymentPreference {
  Account = 'ACCOUNT',
  Balance = 'BALANCE'
}

export enum PaymentTerm {
  Half = 'HALF',
  Postpayment = 'POSTPAYMENT',
  Prepayment = 'PREPAYMENT'
}

export enum PurchaseStatus {
  New = 'NEW',
  Used = 'USED'
}

export type Query = {
  __typename?: 'Query';
  accessories: AccessoryConnection;
  carriers: CarrierConnection;
  client: Client;
  clients: ClientConnection;
  contract: Contract;
  contracts: ContractConnection;
  ddt: Ddt;
  ddts: DdtConnection;
  filters: FilterConnection;
  generator: Generator;
  generators: GeneratorConnection;
  invoice: Invoice;
  invoices: InvoiceConnection;
  maintenance: Maintenance;
  maintenances: MaintenanceConnection;
  rental: Rental;
  rentals: RentalConnection;
  stats: Stats;
  technicians: TechnicianConnection;
  viewer: User;
  workReport: WorkReport;
  workReports: WorkReportConnection;
};


export type QueryAccessoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCarriersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryClientArgs = {
  id: Scalars['String'];
};


export type QueryClientsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientCode?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryContractArgs = {
  id: Scalars['String'];
};


export type QueryContractsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  rentalAmount?: Maybe<Scalars['Float']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryDdtArgs = {
  id: Scalars['String'];
};


export type QueryDdtsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryFiltersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryGeneratorArgs = {
  id: Scalars['String'];
};


export type QueryGeneratorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  kVA?: Maybe<Scalars['Float']>;
  last?: Maybe<Scalars['Int']>;
  serialNumber?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
  status?: Maybe<GeneratorStatus>;
};


export type QueryInvoiceArgs = {
  id: Scalars['String'];
};


export type QueryInvoicesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  rentalId?: Maybe<Scalars['ID']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryMaintenanceArgs = {
  id: Scalars['String'];
};


export type QueryMaintenancesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  generatorId?: Maybe<Scalars['ID']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryRentalArgs = {
  id: Scalars['String'];
};


export type QueryRentalsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  status?: Maybe<RentalStatus>;
};


export type QueryTechniciansArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  lastName?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryWorkReportArgs = {
  id: Scalars['String'];
};


export type QueryWorkReportsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  generatorCode?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type Rental = {
  __typename?: 'Rental';
  accessories: Array<Accessory>;
  client: Client;
  createdAt: Scalars['DateTime'];
  ddt?: Maybe<Ddt>;
  generator: Generator;
  id: Scalars['ID'];
  inDate?: Maybe<Scalars['DateTime']>;
  inHours?: Maybe<Scalars['Int']>;
  invoices: Array<InvoiceRentalLink>;
  note?: Maybe<Scalars['String']>;
  outDate?: Maybe<Scalars['DateTime']>;
  outHours?: Maybe<Scalars['Int']>;
  status: RentalStatus;
  updatedAt: Scalars['DateTime'];
};

export type RentalConnection = {
  __typename?: 'RentalConnection';
  edges?: Maybe<Array<RentalEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type RentalEdge = {
  __typename?: 'RentalEdge';
  cursor: Scalars['String'];
  node: Rental;
};

export enum RentalStatus {
  In = 'IN',
  Invoice = 'INVOICE',
  Out = 'OUT'
}

export enum ShippingChargesType {
  AssignedPort = 'ASSIGNED_PORT',
  FreePort = 'FREE_PORT'
}

export type SignupInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Stats = {
  __typename?: 'Stats';
  generatorsCount: Scalars['Int'];
  maintenancesCount: Scalars['Int'];
  nearMaintenanceGeneratorsCount: Scalars['Int'];
  rentalsCount: Scalars['Int'];
  rentedGeneratorsCount: Scalars['Int'];
  thisMonthMaintenancesCount: Scalars['Int'];
  tobeInvoicedRentalsCount: Scalars['Int'];
  withoutWorkReportMaintenancesCount: Scalars['Int'];
};

export type Technician = {
  __typename?: 'Technician';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  phone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type TechnicianConnection = {
  __typename?: 'TechnicianConnection';
  edges?: Maybe<Array<TechnicianEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type TechnicianEdge = {
  __typename?: 'TechnicianEdge';
  cursor: Scalars['String'];
  node: Technician;
};

export type UpdateAccessoryInput = {
  accessoryId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
};

export type UpdateCarrierInput = {
  carrierId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  regAlboCode?: Maybe<Scalars['String']>;
  sdiCode?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
};

export type UpdateClientContactInput = {
  clientContactId: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type UpdateClientInput = {
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientId: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fiscalCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pec?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sdiCode?: Maybe<Scalars['String']>;
  VATnumber?: Maybe<Scalars['String']>;
};

export type UpdateContractInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  clientId?: Maybe<Scalars['ID']>;
  contractId: Scalars['ID'];
  extendedRental?: Maybe<Scalars['Boolean']>;
  generatorIds?: Maybe<Array<Scalars['ID']>>;
  generatorInsuranceValue?: Maybe<Scalars['Float']>;
  monthlyPaymentsAmount?: Maybe<Scalars['Float']>;
  paymentMode?: Maybe<PaymentMode>;
  paymentTerms?: Maybe<PaymentTerm>;
  rentalAmount?: Maybe<Scalars['Float']>;
  rentalDate?: Maybe<Scalars['DateTime']>;
  rentalDays?: Maybe<Scalars['Int']>;
  securityDepositAmount?: Maybe<Scalars['Float']>;
  shippingAddress?: Maybe<Scalars['String']>;
};

export type UpdateDdtInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  address?: Maybe<Scalars['String']>;
  carrierId?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  ddtId: Scalars['ID'];
  generatorIds?: Maybe<Array<Scalars['ID']>>;
  note?: Maybe<Scalars['String']>;
  otherGoods?: Maybe<Array<Scalars['JSONObject']>>;
  packageAppearance?: Maybe<Scalars['String']>;
  packagesNumber?: Maybe<Scalars['Int']>;
  paymentPreference?: Maybe<PaymentPreference>;
  postCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  shippingCharges?: Maybe<ShippingChargesType>;
  totalWeight?: Maybe<Scalars['Float']>;
};

export type UpdateFilterGeneratorLinkInput = {
  filterId: Scalars['ID'];
  generatorId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type UpdateFilterInput = {
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  filterId: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
};

export type UpdateGeneratorInput = {
  address?: Maybe<Scalars['String']>;
  alternatorBrand?: Maybe<Scalars['String']>;
  alternatorSerialNumber?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['ID']>;
  contactIds?: Maybe<Array<Scalars['ID']>>;
  country?: Maybe<Scalars['String']>;
  electricityType?: Maybe<ElectricityType>;
  engineBrand?: Maybe<Scalars['String']>;
  engineSerialNumber?: Maybe<Scalars['String']>;
  fuelType?: Maybe<FuelType>;
  generatorId: Scalars['String'];
  kVA?: Maybe<Scalars['Float']>;
  kW?: Maybe<Scalars['Float']>;
  postCode?: Maybe<Scalars['String']>;
  productionYear?: Maybe<Scalars['Int']>;
  province?: Maybe<Scalars['String']>;
  purchasePrice?: Maybe<Scalars['Float']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseYear?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<GeneratorStatus>;
  type?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceInput = {
  amount?: Maybe<Scalars['Float']>;
  invoiceId: Scalars['ID'];
  number?: Maybe<Scalars['String']>;
};

export type UpdateInvoiceRentalLinkInput = {
  amount: Scalars['Float'];
  invoiceId: Scalars['ID'];
  rentalId: Scalars['ID'];
};

export type UpdateInvoiceWorkReportLinkInput = {
  amount: Scalars['Float'];
  invoiceId: Scalars['ID'];
  workReportId: Scalars['ID'];
};

export type UpdateMaintenanceInput = {
  completed?: Maybe<Scalars['Boolean']>;
  endDate?: Maybe<Scalars['DateTime']>;
  maintenanceId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicianIds?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateRentalInput = {
  accessoryIds?: Maybe<Array<Scalars['ID']>>;
  clientId?: Maybe<Scalars['ID']>;
  ddtId?: Maybe<Scalars['ID']>;
  generatorId?: Maybe<Scalars['ID']>;
  inDate?: Maybe<Scalars['DateTime']>;
  inHours?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  outDate?: Maybe<Scalars['DateTime']>;
  outHours?: Maybe<Scalars['Int']>;
  rentalId: Scalars['ID'];
  status?: Maybe<RentalStatus>;
};

export type UpdateTechnicianInput = {
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  technicianId: Scalars['String'];
};

export type UpdateWorkReportInput = {
  cisternFuelLevel?: Maybe<Scalars['Int']>;
  clientId?: Maybe<Scalars['ID']>;
  generatorHours?: Maybe<Scalars['Float']>;
  lunchBreak?: Maybe<Scalars['Boolean']>;
  maintenanceId?: Maybe<Scalars['ID']>;
  note?: Maybe<Scalars['String']>;
  operationType?: Maybe<OperationType>;
  positiveOperation?: Maybe<Scalars['Boolean']>;
  registerCompiled?: Maybe<Scalars['Boolean']>;
  registerNotCompiledReason?: Maybe<Scalars['String']>;
  tankFuelLevel?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicianIds?: Maybe<Array<Scalars['ID']>>;
  totalKm?: Maybe<Scalars['Float']>;
  workHours?: Maybe<Scalars['Float']>;
  workReportId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type WorkReport = {
  __typename?: 'WorkReport';
  cisternFuelLevel?: Maybe<Scalars['Int']>;
  client: Client;
  createdAt: Scalars['DateTime'];
  generatorHours?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  invoices: Array<InvoiceWorkReportLink>;
  lunchBreak?: Maybe<Scalars['Boolean']>;
  maintenance?: Maybe<Maintenance>;
  note?: Maybe<Scalars['String']>;
  operationType: OperationType;
  positiveOperation: Scalars['Boolean'];
  registerCompiled: Scalars['Boolean'];
  registerNotCompiledReason?: Maybe<Scalars['String']>;
  tankFuelLevel?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Scalars['JSONObject']>>;
  technicians: Array<Technician>;
  totalKm?: Maybe<Scalars['Float']>;
  updatedAt: Scalars['DateTime'];
  workHours?: Maybe<Scalars['Float']>;
  workReportCode: Scalars['Int'];
};

export type WorkReportConnection = {
  __typename?: 'WorkReportConnection';
  edges?: Maybe<Array<WorkReportEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
};

export type WorkReportEdge = {
  __typename?: 'WorkReportEdge';
  cursor: Scalars['String'];
  node: WorkReport;
};

export type AccessoryDetailFragment = (
  { __typename?: 'Accessory' }
  & Pick<Accessory, 'note' | 'name' | 'serialNumber' | 'createdAt' | 'id' | 'updatedAt'>
);

export type CarrierDetailFragment = (
  { __typename?: 'Carrier' }
  & Pick<Carrier, 'createdAt' | 'id' | 'updatedAt' | 'name' | 'regAlboCode' | 'VATnumber' | 'fiscalCode' | 'sdiCode' | 'carrierCode' | 'email' | 'pec' | 'phone'>
);

export type ClientContactDetailFragment = (
  { __typename?: 'ClientContact' }
  & Pick<ClientContact, 'id' | 'firstName' | 'lastName' | 'phone' | 'email'>
);

export type ClientDetailFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'address' | 'city' | 'country' | 'province' | 'sdiCode' | 'createdAt' | 'email' | 'fiscalCode' | 'id' | 'name' | 'pec' | 'phone' | 'postCode' | 'region' | 'clientCode' | 'updatedAt' | 'VATnumber'>
  & { contacts: Array<(
    { __typename?: 'ClientContact' }
    & ClientContactDetailFragment
  )>, ddts: Array<(
    { __typename?: 'DDT' }
    & DdtForClientDetailFragment
  )>, generators: Array<(
    { __typename?: 'Generator' }
    & GeneratorForClientDetailFragment
  )>, contracts: Array<(
    { __typename?: 'Contract' }
    & ContractForClientDetailFragment
  )>, rentals: Array<(
    { __typename?: 'Rental' }
    & RentalForClientDetailFragment
  )> }
);

export type ClientListFragment = (
  { __typename?: 'Client' }
  & Pick<Client, 'address' | 'city' | 'country' | 'province' | 'sdiCode' | 'createdAt' | 'email' | 'fiscalCode' | 'id' | 'name' | 'pec' | 'phone' | 'postCode' | 'region' | 'clientCode' | 'updatedAt' | 'VATnumber'>
);

export type ContractDetailFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'createdAt' | 'rentalDate' | 'extendedRental' | 'id' | 'updatedAt' | 'monthlyPaymentsAmount' | 'paymentMode' | 'paymentTerms' | 'rentalAmount' | 'rentalDays' | 'securityDepositAmount' | 'shippingAddress' | 'generatorInsuranceValue'>
  & { accessories?: Maybe<Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>>, client: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ), generators?: Maybe<Array<(
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  )>> }
);

export type ContractForClientDetailFragment = (
  { __typename?: 'Contract' }
  & Pick<Contract, 'createdAt' | 'rentalDate' | 'extendedRental' | 'id' | 'updatedAt' | 'monthlyPaymentsAmount' | 'paymentMode' | 'paymentTerms' | 'rentalAmount' | 'rentalDays' | 'securityDepositAmount' | 'generatorInsuranceValue' | 'shippingAddress'>
  & { accessories?: Maybe<Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>>, generators?: Maybe<Array<(
    { __typename?: 'Generator' }
    & GeneratorForClientDetailFragment
  )>> }
);

export type DdtDetailFragment = (
  { __typename?: 'DDT' }
  & Pick<Ddt, 'createdAt' | 'id' | 'updatedAt' | 'address' | 'city' | 'country' | 'date' | 'note' | 'postCode' | 'province' | 'region' | 'reason' | 'paymentPreference' | 'packageAppearance' | 'packagesNumber' | 'totalWeight' | 'shippingCharges' | 'otherGoods' | 'ddtCode'>
  & { client: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ), accessories: Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>, carrier?: Maybe<(
    { __typename?: 'Carrier' }
    & CarrierDetailFragment
  )>, generators: Array<(
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  )> }
);

export type DdtForClientDetailFragment = (
  { __typename?: 'DDT' }
  & Pick<Ddt, 'createdAt' | 'id' | 'updatedAt' | 'address' | 'city' | 'country' | 'date' | 'note' | 'postCode' | 'province' | 'region' | 'reason' | 'paymentPreference' | 'packageAppearance' | 'packagesNumber' | 'totalWeight' | 'shippingCharges' | 'otherGoods' | 'ddtCode'>
  & { accessories: Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>, carrier?: Maybe<(
    { __typename?: 'Carrier' }
    & CarrierDetailFragment
  )>, generators: Array<(
    { __typename?: 'Generator' }
    & GeneratorForClientDetailFragment
  )> }
);

export type FilterDetailFragment = (
  { __typename?: 'Filter' }
  & Pick<Filter, 'category' | 'brand' | 'price' | 'code' | 'createdAt' | 'id' | 'updatedAt'>
);

export type FilterGeneratorLinkDetailFragment = (
  { __typename?: 'FilterGeneratorLink' }
  & Pick<FilterGeneratorLink, 'filterId' | 'generatorId' | 'quantity' | 'createdAt' | 'updatedAt'>
  & { filter: (
    { __typename?: 'Filter' }
    & FilterDetailFragment
  ) }
);

export type GeneratorDetailFragment = (
  { __typename?: 'Generator' }
  & Pick<Generator, 'status' | 'alternatorBrand' | 'alternatorSerialNumber' | 'brand' | 'createdAt' | 'engineBrand' | 'engineSerialNumber' | 'id' | 'kVA' | 'kW' | 'productionYear' | 'purchasePrice' | 'purchaseStatus' | 'purchaseYear' | 'serialNumber' | 'generatorCode' | 'updatedAt' | 'address' | 'city' | 'country' | 'province' | 'postCode' | 'region' | 'type' | 'electricityType' | 'fuelType'>
  & { filters: Array<(
    { __typename?: 'FilterGeneratorLink' }
    & FilterGeneratorLinkDetailFragment
  )>, maintenances: Array<(
    { __typename?: 'Maintenance' }
    & MaintenanceDetailFragment
  )>, contacts?: Maybe<Array<(
    { __typename?: 'ClientContact' }
    & ClientContactDetailFragment
  )>>, client?: Maybe<(
    { __typename?: 'Client' }
    & ClientDetailFragment
  )> }
);

export type GeneratorForClientDetailFragment = (
  { __typename?: 'Generator' }
  & Pick<Generator, 'status' | 'alternatorBrand' | 'alternatorSerialNumber' | 'brand' | 'createdAt' | 'engineBrand' | 'engineSerialNumber' | 'id' | 'kVA' | 'kW' | 'productionYear' | 'purchasePrice' | 'purchaseStatus' | 'purchaseYear' | 'serialNumber' | 'generatorCode' | 'updatedAt' | 'address' | 'city' | 'country' | 'province' | 'postCode' | 'region' | 'type' | 'electricityType' | 'fuelType'>
  & { filters: Array<(
    { __typename?: 'FilterGeneratorLink' }
    & FilterGeneratorLinkDetailFragment
  )>, maintenances: Array<(
    { __typename?: 'Maintenance' }
    & MaintenanceDetailFragment
  )>, contacts?: Maybe<Array<(
    { __typename?: 'ClientContact' }
    & ClientContactDetailFragment
  )>> }
);

export type GeneratorListFragment = (
  { __typename?: 'Generator' }
  & Pick<Generator, 'status' | 'alternatorBrand' | 'alternatorSerialNumber' | 'brand' | 'createdAt' | 'engineBrand' | 'engineSerialNumber' | 'id' | 'kVA' | 'kW' | 'productionYear' | 'purchasePrice' | 'purchaseStatus' | 'purchaseYear' | 'serialNumber' | 'generatorCode' | 'updatedAt' | 'address' | 'city' | 'country' | 'province' | 'postCode' | 'region' | 'type' | 'electricityType' | 'fuelType'>
  & { client?: Maybe<(
    { __typename?: 'Client' }
    & ClientListFragment
  )> }
);

export type InvoiceDetailFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createdAt' | 'id' | 'updatedAt' | 'number' | 'amount'>
  & { rentals: Array<(
    { __typename?: 'InvoiceRentalLink' }
    & InvoiceRentalLinkDetailFragment
  )>, workReports: Array<(
    { __typename?: 'InvoiceWorkReportLink' }
    & InvoiceWorkReportLinkDetailFragment
  )> }
);

export type InvoiceNoRentalsDetailFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'createdAt' | 'id' | 'updatedAt' | 'number' | 'amount'>
  & { workReports: Array<(
    { __typename?: 'InvoiceWorkReportLink' }
    & InvoiceWorkReportLinkDetailFragment
  )> }
);

export type InvoiceRentalLinkDetailFragment = (
  { __typename?: 'InvoiceRentalLink' }
  & Pick<InvoiceRentalLink, 'invoiceId' | 'rentalId' | 'amount' | 'createdAt' | 'updatedAt'>
  & { rental: (
    { __typename?: 'Rental' }
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name' | 'id'>
    ) }
    & RentalForClientDetailFragment
  ) }
);

export type InvoiceWorkReportLinkDetailFragment = (
  { __typename?: 'InvoiceWorkReportLink' }
  & Pick<InvoiceWorkReportLink, 'invoiceId' | 'workReportId' | 'amount' | 'createdAt' | 'updatedAt'>
  & { workReport: (
    { __typename?: 'WorkReport' }
    & { maintenance?: Maybe<(
      { __typename?: 'Maintenance' }
      & { generator: (
        { __typename?: 'Generator' }
        & Pick<Generator, 'generatorCode'>
      ) }
    )>, client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name'>
    ) }
    & WorkReportNoClientDetailFragment
  ) }
);

export type MaintenanceFullDetailFragment = (
  { __typename?: 'Maintenance' }
  & Pick<Maintenance, 'note' | 'startDate' | 'endDate' | 'tasks' | 'id' | 'completed' | 'createdAt' | 'updatedAt'>
  & { technicians: Array<(
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  )>, generator: (
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  ), workReport?: Maybe<(
    { __typename?: 'WorkReport' }
    & WorkReportForMaintenanceDetailFragment
  )> }
);

export type MaintenanceListFragment = (
  { __typename?: 'Maintenance' }
  & Pick<Maintenance, 'note' | 'startDate' | 'endDate' | 'tasks' | 'id' | 'createdAt' | 'updatedAt'>
  & { technicians: Array<(
    { __typename?: 'Technician' }
    & Pick<Technician, 'id'>
  )>, generator: (
    { __typename?: 'Generator' }
    & Pick<Generator, 'id' | 'generatorCode' | 'brand' | 'kW'>
    & { contacts?: Maybe<Array<(
      { __typename?: 'ClientContact' }
      & Pick<ClientContact, 'id'>
    )>>, client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'id' | 'name'>
    )> }
  ) }
);

export type MaintenanceDetailFragment = (
  { __typename?: 'Maintenance' }
  & Pick<Maintenance, 'note' | 'startDate' | 'endDate' | 'tasks' | 'id' | 'createdAt' | 'updatedAt'>
  & { technicians: Array<(
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  )> }
);

export type RentalDetailFragment = (
  { __typename?: 'Rental' }
  & Pick<Rental, 'createdAt' | 'inDate' | 'outDate' | 'id' | 'inHours' | 'note' | 'outHours' | 'status' | 'updatedAt'>
  & { accessories: Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>, client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  ), ddt?: Maybe<(
    { __typename?: 'DDT' }
    & Pick<Ddt, 'id' | 'date'>
    & { client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name' | 'id'>
    ) }
  )>, generator: (
    { __typename?: 'Generator' }
    & Pick<Generator, 'serialNumber' | 'id' | 'generatorCode'>
  ), invoices: Array<(
    { __typename?: 'InvoiceRentalLink' }
    & InvoiceRentalLinkDetailFragment
  )> }
);

export type RentalForClientDetailFragment = (
  { __typename?: 'Rental' }
  & Pick<Rental, 'createdAt' | 'inDate' | 'outDate' | 'id' | 'inHours' | 'note' | 'outHours' | 'status' | 'updatedAt'>
  & { accessories: Array<(
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  )>, ddt?: Maybe<(
    { __typename?: 'DDT' }
    & DdtForClientDetailFragment
  )>, generator: (
    { __typename?: 'Generator' }
    & GeneratorForClientDetailFragment
  ) }
);

export type RentalForInvoiceDetailFragment = (
  { __typename?: 'Rental' }
  & Pick<Rental, 'createdAt' | 'inDate' | 'outDate' | 'id' | 'inHours' | 'note' | 'outHours' | 'status' | 'updatedAt'>
  & { generator: (
    { __typename?: 'Generator' }
    & GeneratorForClientDetailFragment
  ), client: (
    { __typename?: 'Client' }
    & Pick<Client, 'id' | 'name'>
  ) }
);

export type StatDetailFragment = (
  { __typename?: 'Stats' }
  & Pick<Stats, 'generatorsCount' | 'maintenancesCount' | 'nearMaintenanceGeneratorsCount' | 'rentalsCount' | 'rentedGeneratorsCount' | 'thisMonthMaintenancesCount' | 'tobeInvoicedRentalsCount' | 'withoutWorkReportMaintenancesCount'>
);

export type TechnicianDetailFragment = (
  { __typename?: 'Technician' }
  & Pick<Technician, 'id' | 'email' | 'phone' | 'firstName' | 'lastName' | 'createdAt' | 'updatedAt'>
);

export type UserDetailFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'email' | 'createdAt' | 'updatedAt'>
);

export type WorkReportDetailFragment = (
  { __typename?: 'WorkReport' }
  & Pick<WorkReport, 'createdAt' | 'id' | 'updatedAt' | 'operationType' | 'totalKm' | 'tankFuelLevel' | 'lunchBreak' | 'note' | 'generatorHours' | 'workHours' | 'registerCompiled' | 'registerNotCompiledReason' | 'positiveOperation' | 'cisternFuelLevel' | 'tasks' | 'workReportCode'>
  & { maintenance?: Maybe<(
    { __typename?: 'Maintenance' }
    & MaintenanceFullDetailFragment
  )>, technicians: Array<(
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  )>, client: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ) }
);

export type WorkReportListFragment = (
  { __typename?: 'WorkReport' }
  & Pick<WorkReport, 'createdAt' | 'id' | 'updatedAt' | 'operationType' | 'totalKm' | 'tankFuelLevel' | 'lunchBreak' | 'note' | 'generatorHours' | 'workHours' | 'registerCompiled' | 'registerNotCompiledReason' | 'positiveOperation' | 'cisternFuelLevel' | 'workReportCode'>
  & { maintenance?: Maybe<(
    { __typename?: 'Maintenance' }
    & MaintenanceListFragment
  )>, client: (
    { __typename?: 'Client' }
    & ClientListFragment
  ) }
);

export type WorkReportNoClientDetailFragment = (
  { __typename?: 'WorkReport' }
  & Pick<WorkReport, 'createdAt' | 'id' | 'updatedAt' | 'operationType' | 'totalKm' | 'tankFuelLevel' | 'lunchBreak' | 'note' | 'generatorHours' | 'workHours' | 'registerCompiled' | 'registerNotCompiledReason' | 'positiveOperation' | 'cisternFuelLevel' | 'workReportCode'>
  & { maintenance?: Maybe<(
    { __typename?: 'Maintenance' }
    & MaintenanceDetailFragment
  )>, technicians: Array<(
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  )> }
);

export type WorkReportForMaintenanceDetailFragment = (
  { __typename?: 'WorkReport' }
  & Pick<WorkReport, 'createdAt' | 'id' | 'updatedAt' | 'operationType' | 'totalKm' | 'tankFuelLevel' | 'lunchBreak' | 'note' | 'generatorHours' | 'workHours' | 'registerCompiled' | 'registerNotCompiledReason' | 'positiveOperation' | 'cisternFuelLevel' | 'workReportCode'>
  & { technicians: Array<(
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  )>, client: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ) }
);

export type CreateAccessoryMutationVariables = Exact<{
  data: CreateAccessoryInput;
}>;


export type CreateAccessoryMutation = (
  { __typename?: 'Mutation' }
  & { createAccessory: (
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  ) }
);

export type UpdateAccessoryMutationVariables = Exact<{
  data: UpdateAccessoryInput;
}>;


export type UpdateAccessoryMutation = (
  { __typename?: 'Mutation' }
  & { updateAccessory: (
    { __typename?: 'Accessory' }
    & AccessoryDetailFragment
  ) }
);

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'accessToken'>
  ) }
);

export type SignupMutationVariables = Exact<{
  data: SignupInput;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'Auth' }
    & Pick<Auth, 'accessToken'>
  ) }
);

export type CreateCarrierMutationVariables = Exact<{
  data: CreateCarrierInput;
}>;


export type CreateCarrierMutation = (
  { __typename?: 'Mutation' }
  & { createCarrier: (
    { __typename?: 'Carrier' }
    & CarrierDetailFragment
  ) }
);

export type UpdateCarrierMutationVariables = Exact<{
  data: UpdateCarrierInput;
}>;


export type UpdateCarrierMutation = (
  { __typename?: 'Mutation' }
  & { updateCarrier: (
    { __typename?: 'Carrier' }
    & CarrierDetailFragment
  ) }
);

export type CreateClientContactMutationVariables = Exact<{
  data: CreateClientContactInput;
}>;


export type CreateClientContactMutation = (
  { __typename?: 'Mutation' }
  & { createClientContact: (
    { __typename?: 'ClientContact' }
    & ClientContactDetailFragment
  ) }
);

export type CreateClientMutationVariables = Exact<{
  data: CreateClientInput;
}>;


export type CreateClientMutation = (
  { __typename?: 'Mutation' }
  & { createClient: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ) }
);

export type UpdateClientContactMutationVariables = Exact<{
  data: UpdateClientContactInput;
}>;


export type UpdateClientContactMutation = (
  { __typename?: 'Mutation' }
  & { updateClientContact: (
    { __typename?: 'ClientContact' }
    & ClientContactDetailFragment
  ) }
);

export type UpdateClientMutationVariables = Exact<{
  data: UpdateClientInput;
}>;


export type UpdateClientMutation = (
  { __typename?: 'Mutation' }
  & { updateClient: (
    { __typename?: 'Client' }
    & ClientDetailFragment
  ) }
);

export type CreateContractMutationVariables = Exact<{
  data: CreateContractInput;
}>;


export type CreateContractMutation = (
  { __typename?: 'Mutation' }
  & { createContract: (
    { __typename?: 'Contract' }
    & ContractDetailFragment
  ) }
);

export type UpdateContractMutationVariables = Exact<{
  data: UpdateContractInput;
}>;


export type UpdateContractMutation = (
  { __typename?: 'Mutation' }
  & { updateContract: (
    { __typename?: 'Contract' }
    & ContractDetailFragment
  ) }
);

export type CreateDdtMutationVariables = Exact<{
  data: CreateDdtInput;
}>;


export type CreateDdtMutation = (
  { __typename?: 'Mutation' }
  & { createDDT: (
    { __typename?: 'DDT' }
    & DdtDetailFragment
  ) }
);

export type UpdateDdtMutationVariables = Exact<{
  data: UpdateDdtInput;
}>;


export type UpdateDdtMutation = (
  { __typename?: 'Mutation' }
  & { updateDDT: (
    { __typename?: 'DDT' }
    & DdtDetailFragment
  ) }
);

export type CreateFilterMutationVariables = Exact<{
  data: CreateFilterInput;
}>;


export type CreateFilterMutation = (
  { __typename?: 'Mutation' }
  & { createFilter: (
    { __typename?: 'Filter' }
    & FilterDetailFragment
  ) }
);

export type UpdateFilterMutationVariables = Exact<{
  data: UpdateFilterInput;
}>;


export type UpdateFilterMutation = (
  { __typename?: 'Mutation' }
  & { updateFilter: (
    { __typename?: 'Filter' }
    & FilterDetailFragment
  ) }
);

export type CreateFilterGeneratorLinkMutationVariables = Exact<{
  data: CreateFilterGeneratorLinkInput;
}>;


export type CreateFilterGeneratorLinkMutation = (
  { __typename?: 'Mutation' }
  & { createFilterGeneratorLink: (
    { __typename?: 'FilterGeneratorLink' }
    & FilterGeneratorLinkDetailFragment
  ) }
);

export type CreateGeneratorMutationVariables = Exact<{
  data: CreateGeneratorInput;
}>;


export type CreateGeneratorMutation = (
  { __typename?: 'Mutation' }
  & { createGenerator: (
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  ) }
);

export type DeleteFilterGeneratorLinkMutationVariables = Exact<{
  data: DeleteFilterGeneratorLinkInput;
}>;


export type DeleteFilterGeneratorLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteFilterGeneratorLink: (
    { __typename?: 'DeletedFilterGeneratorLink' }
    & Pick<DeletedFilterGeneratorLink, 'deletedFilterId' | 'deletedGeneratorId'>
  ) }
);

export type UpdateFilterGeneratorLinkMutationVariables = Exact<{
  data: UpdateFilterGeneratorLinkInput;
}>;


export type UpdateFilterGeneratorLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateFilterGeneratorLink: (
    { __typename?: 'FilterGeneratorLink' }
    & FilterGeneratorLinkDetailFragment
  ) }
);

export type UpdateGeneratorMutationVariables = Exact<{
  data: UpdateGeneratorInput;
}>;


export type UpdateGeneratorMutation = (
  { __typename?: 'Mutation' }
  & { updateGenerator: (
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  ) }
);

export type CreateInvoiceRentalLinkMutationVariables = Exact<{
  data: CreateInvoiceRentalLinkInput;
}>;


export type CreateInvoiceRentalLinkMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceRentalLink: (
    { __typename?: 'InvoiceRentalLink' }
    & InvoiceRentalLinkDetailFragment
  ) }
);

export type CreateInvoiceWorkReportLinkMutationVariables = Exact<{
  data: CreateInvoiceWorkReportLinkInput;
}>;


export type CreateInvoiceWorkReportLinkMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceWorkReportLink: (
    { __typename?: 'InvoiceWorkReportLink' }
    & InvoiceWorkReportLinkDetailFragment
  ) }
);

export type CreateInvoiceMutationVariables = Exact<{
  data: CreateInvoiceInput;
}>;


export type CreateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceDetailFragment
  ) }
);

export type DeleteInvoiceRentalLinkMutationVariables = Exact<{
  data: DeleteInvoiceRentalLinkInput;
}>;


export type DeleteInvoiceRentalLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceRentalLink: (
    { __typename?: 'DeletedInvoiceRentalLink' }
    & Pick<DeletedInvoiceRentalLink, 'deletedInvoiceId' | 'deletedRentalId'>
  ) }
);

export type DeleteInvoiceWorkReportLinkMutationVariables = Exact<{
  data: DeleteInvoiceWorkReportLinkInput;
}>;


export type DeleteInvoiceWorkReportLinkMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceWorkReportLink: (
    { __typename?: 'DeletedInvoiceWorkReportLink' }
    & Pick<DeletedInvoiceWorkReportLink, 'deletedInvoiceId' | 'deletedWorkReportId'>
  ) }
);

export type UpdateInvoiceRentalLinkMutationVariables = Exact<{
  data: UpdateInvoiceRentalLinkInput;
}>;


export type UpdateInvoiceRentalLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceRentalLink: (
    { __typename?: 'InvoiceRentalLink' }
    & InvoiceRentalLinkDetailFragment
  ) }
);

export type UpdateInvoiceWorkReportLinkMutationVariables = Exact<{
  data: UpdateInvoiceWorkReportLinkInput;
}>;


export type UpdateInvoiceWorkReportLinkMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceWorkReportLink: (
    { __typename?: 'InvoiceWorkReportLink' }
    & InvoiceWorkReportLinkDetailFragment
  ) }
);

export type UpdateInvoiceMutationVariables = Exact<{
  data: UpdateInvoiceInput;
}>;


export type UpdateInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoice: (
    { __typename?: 'Invoice' }
    & InvoiceDetailFragment
  ) }
);

export type CloneMaintenancesMutationVariables = Exact<{
  data: CloneMaintenancesInput;
}>;


export type CloneMaintenancesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cloneMaintenances'>
);

export type CreateMaintenanceMutationVariables = Exact<{
  data: CreateMaintenanceInput;
}>;


export type CreateMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & { createMaintenance: (
    { __typename?: 'Maintenance' }
    & MaintenanceDetailFragment
  ) }
);

export type DeleteMaintenancesMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMaintenancesMutation = (
  { __typename?: 'Mutation' }
  & { deleteMaintenance: (
    { __typename?: 'Maintenance' }
    & Pick<Maintenance, 'id'>
  ) }
);

export type UpdateMaintenanceMutationVariables = Exact<{
  data: UpdateMaintenanceInput;
}>;


export type UpdateMaintenanceMutation = (
  { __typename?: 'Mutation' }
  & { updateMaintenance: (
    { __typename?: 'Maintenance' }
    & MaintenanceDetailFragment
  ) }
);

export type CreateRentalMutationVariables = Exact<{
  data: CreateRentalInput;
}>;


export type CreateRentalMutation = (
  { __typename?: 'Mutation' }
  & { createRental: (
    { __typename?: 'Rental' }
    & RentalDetailFragment
  ) }
);

export type UpdateRentalMutationVariables = Exact<{
  data: UpdateRentalInput;
}>;


export type UpdateRentalMutation = (
  { __typename?: 'Mutation' }
  & { updateRental: (
    { __typename?: 'Rental' }
    & RentalDetailFragment
  ) }
);

export type CreateTechnicianMutationVariables = Exact<{
  data: CreateTechnicianInput;
}>;


export type CreateTechnicianMutation = (
  { __typename?: 'Mutation' }
  & { createTechnician: (
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  ) }
);

export type UpdateTechnicianMutationVariables = Exact<{
  data: UpdateTechnicianInput;
}>;


export type UpdateTechnicianMutation = (
  { __typename?: 'Mutation' }
  & { updateTechnician: (
    { __typename?: 'Technician' }
    & TechnicianDetailFragment
  ) }
);

export type CreateWorkReportMutationVariables = Exact<{
  data: CreateWorkReportInput;
}>;


export type CreateWorkReportMutation = (
  { __typename?: 'Mutation' }
  & { createWorkReport: (
    { __typename?: 'WorkReport' }
    & WorkReportDetailFragment
  ) }
);

export type UpdateWorkReportMutationVariables = Exact<{
  data: UpdateWorkReportInput;
}>;


export type UpdateWorkReportMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkReport: (
    { __typename?: 'WorkReport' }
    & WorkReportDetailFragment
  ) }
);

export type AccessoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AccessoriesQuery = (
  { __typename?: 'Query' }
  & { accessories: (
    { __typename?: 'AccessoryConnection' }
    & Pick<AccessoryConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'AccessoryEdge' }
      & { node: (
        { __typename?: 'Accessory' }
        & AccessoryDetailFragment
      ) }
    )>> }
  ) }
);

export type CarriersQueryVariables = Exact<{ [key: string]: never; }>;


export type CarriersQuery = (
  { __typename?: 'Query' }
  & { carriers: (
    { __typename?: 'CarrierConnection' }
    & Pick<CarrierConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'CarrierEdge' }
      & { node: (
        { __typename?: 'Carrier' }
        & CarrierDetailFragment
      ) }
    )>> }
  ) }
);

export type ClientsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  clientCode?: Maybe<Scalars['Int']>;
}>;


export type ClientsQuery = (
  { __typename?: 'Query' }
  & { clients: (
    { __typename?: 'ClientConnection' }
    & Pick<ClientConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ClientEdge' }
      & { node: (
        { __typename?: 'Client' }
        & ClientDetailFragment
      ) }
    )>> }
  ) }
);

export type ContractsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  rentalAmount?: Maybe<Scalars['Float']>;
}>;


export type ContractsQuery = (
  { __typename?: 'Query' }
  & { contracts: (
    { __typename?: 'ContractConnection' }
    & Pick<ContractConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'ContractEdge' }
      & { node: (
        { __typename?: 'Contract' }
        & ContractDetailFragment
      ) }
    )>> }
  ) }
);

export type DdTsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  carrierName?: Maybe<Scalars['String']>;
}>;


export type DdTsQuery = (
  { __typename?: 'Query' }
  & { ddts: (
    { __typename?: 'DDTConnection' }
    & Pick<DdtConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'DDTEdge' }
      & { node: (
        { __typename?: 'DDT' }
        & DdtDetailFragment
      ) }
    )>> }
  ) }
);

export type FiltersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
}>;


export type FiltersQuery = (
  { __typename?: 'Query' }
  & { filters: (
    { __typename?: 'FilterConnection' }
    & Pick<FilterConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'FilterEdge' }
      & { node: (
        { __typename?: 'Filter' }
        & FilterDetailFragment
      ) }
    )>> }
  ) }
);

export type GeneratorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GeneratorQuery = (
  { __typename?: 'Query' }
  & { generator: (
    { __typename?: 'Generator' }
    & GeneratorDetailFragment
  ) }
);

export type GeneratorsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  kVA?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<GeneratorStatus>;
}>;


export type GeneratorsListQuery = (
  { __typename?: 'Query' }
  & { generators: (
    { __typename?: 'GeneratorConnection' }
    & Pick<GeneratorConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'GeneratorEdge' }
      & { node: (
        { __typename?: 'Generator' }
        & GeneratorListFragment
      ) }
    )>> }
  ) }
);

export type GeneratorsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  kVA?: Maybe<Scalars['Float']>;
  clientName?: Maybe<Scalars['String']>;
  brand?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  status?: Maybe<GeneratorStatus>;
}>;


export type GeneratorsQuery = (
  { __typename?: 'Query' }
  & { generators: (
    { __typename?: 'GeneratorConnection' }
    & Pick<GeneratorConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'GeneratorEdge' }
      & { node: (
        { __typename?: 'Generator' }
        & GeneratorDetailFragment
      ) }
    )>> }
  ) }
);

export type InvoiceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type InvoiceQuery = (
  { __typename?: 'Query' }
  & { invoice: (
    { __typename?: 'Invoice' }
    & InvoiceDetailFragment
  ) }
);

export type InvoicesQueryVariables = Exact<{
  clientId?: Maybe<Scalars['ID']>;
  rentalId?: Maybe<Scalars['ID']>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceConnection' }
    & Pick<InvoiceConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'InvoiceEdge' }
      & { node: (
        { __typename?: 'Invoice' }
        & InvoiceDetailFragment
      ) }
    )>> }
  ) }
);

export type MaintenanceQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MaintenanceQuery = (
  { __typename?: 'Query' }
  & { maintenance: (
    { __typename?: 'Maintenance' }
    & MaintenanceFullDetailFragment
  ) }
);

export type MaintenancesListQueryVariables = Exact<{
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  generatorId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
}>;


export type MaintenancesListQuery = (
  { __typename?: 'Query' }
  & { maintenances: (
    { __typename?: 'MaintenanceConnection' }
    & Pick<MaintenanceConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'MaintenanceEdge' }
      & { node: (
        { __typename?: 'Maintenance' }
        & MaintenanceListFragment
      ) }
    )>> }
  ) }
);

export type MaintenancesQueryVariables = Exact<{
  fromDate?: Maybe<Scalars['DateTime']>;
  generatorId?: Maybe<Scalars['ID']>;
  clientId?: Maybe<Scalars['ID']>;
}>;


export type MaintenancesQuery = (
  { __typename?: 'Query' }
  & { maintenances: (
    { __typename?: 'MaintenanceConnection' }
    & Pick<MaintenanceConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'MaintenanceEdge' }
      & { node: (
        { __typename?: 'Maintenance' }
        & MaintenanceFullDetailFragment
      ) }
    )>> }
  ) }
);

export type RentalQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RentalQuery = (
  { __typename?: 'Query' }
  & { rental: (
    { __typename?: 'Rental' }
    & RentalDetailFragment
  ) }
);

export type RentalsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  status?: Maybe<RentalStatus>;
}>;


export type RentalsListQuery = (
  { __typename?: 'Query' }
  & { rentals: (
    { __typename?: 'RentalConnection' }
    & Pick<RentalConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'RentalEdge' }
      & { node: (
        { __typename?: 'Rental' }
        & RentalForInvoiceDetailFragment
      ) }
    )>> }
  ) }
);

export type RentalsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  status?: Maybe<RentalStatus>;
}>;


export type RentalsQuery = (
  { __typename?: 'Query' }
  & { rentals: (
    { __typename?: 'RentalConnection' }
    & Pick<RentalConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'RentalEdge' }
      & { node: (
        { __typename?: 'Rental' }
        & RentalDetailFragment
      ) }
    )>> }
  ) }
);

export type StatsQueryVariables = Exact<{ [key: string]: never; }>;


export type StatsQuery = (
  { __typename?: 'Query' }
  & { stats: (
    { __typename?: 'Stats' }
    & StatDetailFragment
  ) }
);

export type TechniciansQueryVariables = Exact<{ [key: string]: never; }>;


export type TechniciansQuery = (
  { __typename?: 'Query' }
  & { technicians: (
    { __typename?: 'TechnicianConnection' }
    & Pick<TechnicianConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'TechnicianEdge' }
      & { node: (
        { __typename?: 'Technician' }
        & TechnicianDetailFragment
      ) }
    )>> }
  ) }
);

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = (
  { __typename?: 'Query' }
  & { viewer: (
    { __typename?: 'User' }
    & UserDetailFragment
  ) }
);

export type WorkReportQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type WorkReportQuery = (
  { __typename?: 'Query' }
  & { workReport: (
    { __typename?: 'WorkReport' }
    & WorkReportDetailFragment
  ) }
);

export type WorkReportsListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  generatorCode?: Maybe<Scalars['Int']>;
}>;


export type WorkReportsListQuery = (
  { __typename?: 'Query' }
  & { workReports: (
    { __typename?: 'WorkReportConnection' }
    & Pick<WorkReportConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'WorkReportEdge' }
      & { node: (
        { __typename?: 'WorkReport' }
        & WorkReportListFragment
      ) }
    )>> }
  ) }
);

export type WorkReportsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
}>;


export type WorkReportsQuery = (
  { __typename?: 'Query' }
  & { workReports: (
    { __typename?: 'WorkReportConnection' }
    & Pick<WorkReportConnection, 'totalCount'>
    & { edges?: Maybe<Array<(
      { __typename?: 'WorkReportEdge' }
      & { node: (
        { __typename?: 'WorkReport' }
        & WorkReportDetailFragment
      ) }
    )>> }
  ) }
);

export const AccessoryDetailFragmentDoc = gql`
    fragment AccessoryDetail on Accessory {
  note
  name
  serialNumber
  createdAt
  id
  updatedAt
}
    `;
export const ClientContactDetailFragmentDoc = gql`
    fragment ClientContactDetail on ClientContact {
  id
  firstName
  lastName
  phone
  email
}
    `;
export const CarrierDetailFragmentDoc = gql`
    fragment CarrierDetail on Carrier {
  createdAt
  id
  updatedAt
  name
  regAlboCode
  VATnumber
  fiscalCode
  sdiCode
  carrierCode
  email
  pec
  phone
}
    `;
export const FilterDetailFragmentDoc = gql`
    fragment FilterDetail on Filter {
  category
  brand
  price
  code
  createdAt
  id
  updatedAt
}
    `;
export const FilterGeneratorLinkDetailFragmentDoc = gql`
    fragment FilterGeneratorLinkDetail on FilterGeneratorLink {
  filter {
    ...FilterDetail
  }
  filterId
  generatorId
  quantity
  createdAt
  updatedAt
}
    ${FilterDetailFragmentDoc}`;
export const TechnicianDetailFragmentDoc = gql`
    fragment TechnicianDetail on Technician {
  id
  email
  phone
  firstName
  lastName
  createdAt
  updatedAt
}
    `;
export const MaintenanceDetailFragmentDoc = gql`
    fragment MaintenanceDetail on Maintenance {
  note
  startDate
  endDate
  tasks
  technicians {
    ...TechnicianDetail
  }
  id
  createdAt
  updatedAt
}
    ${TechnicianDetailFragmentDoc}`;
export const GeneratorForClientDetailFragmentDoc = gql`
    fragment GeneratorForClientDetail on Generator {
  status
  alternatorBrand
  alternatorSerialNumber
  brand
  createdAt
  engineBrand
  engineSerialNumber
  id
  kVA
  kW
  productionYear
  purchasePrice
  purchaseStatus
  purchaseYear
  serialNumber
  generatorCode
  updatedAt
  filters {
    ...FilterGeneratorLinkDetail
  }
  maintenances {
    ...MaintenanceDetail
  }
  address
  city
  country
  province
  postCode
  region
  contacts {
    ...ClientContactDetail
  }
  type
  electricityType
  fuelType
}
    ${FilterGeneratorLinkDetailFragmentDoc}
${MaintenanceDetailFragmentDoc}
${ClientContactDetailFragmentDoc}`;
export const DdtForClientDetailFragmentDoc = gql`
    fragment DDTForClientDetail on DDT {
  createdAt
  id
  updatedAt
  accessories {
    ...AccessoryDetail
  }
  address
  carrier {
    ...CarrierDetail
  }
  city
  country
  date
  generators {
    ...GeneratorForClientDetail
  }
  note
  postCode
  province
  region
  reason
  paymentPreference
  packageAppearance
  packagesNumber
  totalWeight
  shippingCharges
  otherGoods
  ddtCode
}
    ${AccessoryDetailFragmentDoc}
${CarrierDetailFragmentDoc}
${GeneratorForClientDetailFragmentDoc}`;
export const ContractForClientDetailFragmentDoc = gql`
    fragment ContractForClientDetail on Contract {
  accessories {
    ...AccessoryDetail
  }
  createdAt
  rentalDate
  extendedRental
  generators {
    ...GeneratorForClientDetail
  }
  id
  updatedAt
  monthlyPaymentsAmount
  paymentMode
  paymentTerms
  rentalAmount
  rentalDays
  securityDepositAmount
  generatorInsuranceValue
  shippingAddress
}
    ${AccessoryDetailFragmentDoc}
${GeneratorForClientDetailFragmentDoc}`;
export const RentalForClientDetailFragmentDoc = gql`
    fragment RentalForClientDetail on Rental {
  accessories {
    ...AccessoryDetail
  }
  createdAt
  inDate
  outDate
  ddt {
    ...DDTForClientDetail
  }
  generator {
    ...GeneratorForClientDetail
  }
  id
  inHours
  note
  outHours
  status
  updatedAt
}
    ${AccessoryDetailFragmentDoc}
${DdtForClientDetailFragmentDoc}
${GeneratorForClientDetailFragmentDoc}`;
export const ClientDetailFragmentDoc = gql`
    fragment ClientDetail on Client {
  address
  city
  country
  province
  sdiCode
  contacts {
    ...ClientContactDetail
  }
  ddts {
    ...DDTForClientDetail
  }
  generators {
    ...GeneratorForClientDetail
  }
  contracts {
    ...ContractForClientDetail
  }
  rentals {
    ...RentalForClientDetail
  }
  createdAt
  email
  fiscalCode
  id
  name
  pec
  phone
  postCode
  region
  clientCode
  updatedAt
  VATnumber
}
    ${ClientContactDetailFragmentDoc}
${DdtForClientDetailFragmentDoc}
${GeneratorForClientDetailFragmentDoc}
${ContractForClientDetailFragmentDoc}
${RentalForClientDetailFragmentDoc}`;
export const GeneratorDetailFragmentDoc = gql`
    fragment GeneratorDetail on Generator {
  status
  alternatorBrand
  alternatorSerialNumber
  brand
  createdAt
  engineBrand
  engineSerialNumber
  id
  kVA
  kW
  productionYear
  purchasePrice
  purchaseStatus
  purchaseYear
  serialNumber
  generatorCode
  updatedAt
  filters {
    ...FilterGeneratorLinkDetail
  }
  maintenances {
    ...MaintenanceDetail
  }
  address
  city
  country
  province
  postCode
  region
  contacts {
    ...ClientContactDetail
  }
  client {
    ...ClientDetail
  }
  type
  electricityType
  fuelType
}
    ${FilterGeneratorLinkDetailFragmentDoc}
${MaintenanceDetailFragmentDoc}
${ClientContactDetailFragmentDoc}
${ClientDetailFragmentDoc}`;
export const ContractDetailFragmentDoc = gql`
    fragment ContractDetail on Contract {
  accessories {
    ...AccessoryDetail
  }
  client {
    ...ClientDetail
  }
  createdAt
  rentalDate
  extendedRental
  generators {
    ...GeneratorDetail
  }
  id
  updatedAt
  monthlyPaymentsAmount
  paymentMode
  paymentTerms
  rentalAmount
  rentalDays
  securityDepositAmount
  shippingAddress
  generatorInsuranceValue
}
    ${AccessoryDetailFragmentDoc}
${ClientDetailFragmentDoc}
${GeneratorDetailFragmentDoc}`;
export const DdtDetailFragmentDoc = gql`
    fragment DDTDetail on DDT {
  createdAt
  id
  updatedAt
  client {
    ...ClientDetail
  }
  accessories {
    ...AccessoryDetail
  }
  address
  carrier {
    ...CarrierDetail
  }
  city
  country
  date
  generators {
    ...GeneratorDetail
  }
  note
  postCode
  province
  region
  reason
  paymentPreference
  packageAppearance
  packagesNumber
  totalWeight
  shippingCharges
  otherGoods
  ddtCode
}
    ${ClientDetailFragmentDoc}
${AccessoryDetailFragmentDoc}
${CarrierDetailFragmentDoc}
${GeneratorDetailFragmentDoc}`;
export const ClientListFragmentDoc = gql`
    fragment ClientList on Client {
  address
  city
  country
  province
  sdiCode
  createdAt
  email
  fiscalCode
  id
  name
  pec
  phone
  postCode
  region
  clientCode
  updatedAt
  VATnumber
}
    `;
export const GeneratorListFragmentDoc = gql`
    fragment GeneratorList on Generator {
  status
  alternatorBrand
  alternatorSerialNumber
  brand
  createdAt
  engineBrand
  engineSerialNumber
  id
  kVA
  kW
  productionYear
  purchasePrice
  purchaseStatus
  purchaseYear
  serialNumber
  generatorCode
  updatedAt
  address
  city
  country
  province
  postCode
  region
  client {
    ...ClientList
  }
  type
  electricityType
  fuelType
}
    ${ClientListFragmentDoc}`;
export const InvoiceRentalLinkDetailFragmentDoc = gql`
    fragment InvoiceRentalLinkDetail on InvoiceRentalLink {
  invoiceId
  rental {
    ...RentalForClientDetail
    client {
      name
      id
    }
  }
  rentalId
  amount
  createdAt
  updatedAt
}
    ${RentalForClientDetailFragmentDoc}`;
export const WorkReportNoClientDetailFragmentDoc = gql`
    fragment workReportNoClientDetail on WorkReport {
  createdAt
  id
  updatedAt
  operationType
  totalKm
  tankFuelLevel
  lunchBreak
  note
  generatorHours
  workHours
  registerCompiled
  registerNotCompiledReason
  positiveOperation
  cisternFuelLevel
  maintenance {
    ...MaintenanceDetail
  }
  technicians {
    ...TechnicianDetail
  }
  workReportCode
}
    ${MaintenanceDetailFragmentDoc}
${TechnicianDetailFragmentDoc}`;
export const InvoiceWorkReportLinkDetailFragmentDoc = gql`
    fragment InvoiceWorkReportLinkDetail on InvoiceWorkReportLink {
  invoiceId
  workReport {
    ...workReportNoClientDetail
    maintenance {
      generator {
        generatorCode
      }
    }
    client {
      name
    }
  }
  workReportId
  amount
  createdAt
  updatedAt
}
    ${WorkReportNoClientDetailFragmentDoc}`;
export const InvoiceDetailFragmentDoc = gql`
    fragment InvoiceDetail on Invoice {
  createdAt
  id
  updatedAt
  number
  rentals {
    ...InvoiceRentalLinkDetail
  }
  workReports {
    ...InvoiceWorkReportLinkDetail
  }
  amount
}
    ${InvoiceRentalLinkDetailFragmentDoc}
${InvoiceWorkReportLinkDetailFragmentDoc}`;
export const InvoiceNoRentalsDetailFragmentDoc = gql`
    fragment InvoiceNoRentalsDetail on Invoice {
  createdAt
  id
  updatedAt
  number
  workReports {
    ...InvoiceWorkReportLinkDetail
  }
  amount
}
    ${InvoiceWorkReportLinkDetailFragmentDoc}`;
export const RentalDetailFragmentDoc = gql`
    fragment RentalDetail on Rental {
  accessories {
    ...AccessoryDetail
  }
  client {
    id
    name
  }
  createdAt
  inDate
  outDate
  ddt {
    id
    date
    client {
      name
      id
    }
  }
  generator {
    serialNumber
    id
    generatorCode
  }
  id
  inHours
  invoices {
    ...InvoiceRentalLinkDetail
  }
  note
  outHours
  status
  updatedAt
}
    ${AccessoryDetailFragmentDoc}
${InvoiceRentalLinkDetailFragmentDoc}`;
export const RentalForInvoiceDetailFragmentDoc = gql`
    fragment RentalForInvoiceDetail on Rental {
  createdAt
  inDate
  outDate
  generator {
    ...GeneratorForClientDetail
  }
  client {
    id
    name
  }
  id
  inHours
  note
  outHours
  status
  updatedAt
}
    ${GeneratorForClientDetailFragmentDoc}`;
export const StatDetailFragmentDoc = gql`
    fragment StatDetail on Stats {
  generatorsCount
  maintenancesCount
  nearMaintenanceGeneratorsCount
  rentalsCount
  rentedGeneratorsCount
  thisMonthMaintenancesCount
  tobeInvoicedRentalsCount
  withoutWorkReportMaintenancesCount
}
    `;
export const UserDetailFragmentDoc = gql`
    fragment UserDetail on User {
  id
  email
  createdAt
  updatedAt
}
    `;
export const WorkReportForMaintenanceDetailFragmentDoc = gql`
    fragment WorkReportForMaintenanceDetail on WorkReport {
  createdAt
  id
  updatedAt
  operationType
  totalKm
  tankFuelLevel
  lunchBreak
  note
  generatorHours
  workHours
  registerCompiled
  registerNotCompiledReason
  positiveOperation
  cisternFuelLevel
  technicians {
    ...TechnicianDetail
  }
  client {
    ...ClientDetail
  }
  workReportCode
}
    ${TechnicianDetailFragmentDoc}
${ClientDetailFragmentDoc}`;
export const MaintenanceFullDetailFragmentDoc = gql`
    fragment MaintenanceFullDetail on Maintenance {
  note
  startDate
  endDate
  tasks
  technicians {
    ...TechnicianDetail
  }
  generator {
    ...GeneratorDetail
  }
  workReport {
    ...WorkReportForMaintenanceDetail
  }
  id
  completed
  createdAt
  updatedAt
}
    ${TechnicianDetailFragmentDoc}
${GeneratorDetailFragmentDoc}
${WorkReportForMaintenanceDetailFragmentDoc}`;
export const WorkReportDetailFragmentDoc = gql`
    fragment workReportDetail on WorkReport {
  createdAt
  id
  updatedAt
  operationType
  totalKm
  tankFuelLevel
  lunchBreak
  note
  generatorHours
  workHours
  registerCompiled
  registerNotCompiledReason
  positiveOperation
  cisternFuelLevel
  tasks
  maintenance {
    ...MaintenanceFullDetail
  }
  technicians {
    ...TechnicianDetail
  }
  client {
    ...ClientDetail
  }
  workReportCode
}
    ${MaintenanceFullDetailFragmentDoc}
${TechnicianDetailFragmentDoc}
${ClientDetailFragmentDoc}`;
export const MaintenanceListFragmentDoc = gql`
    fragment MaintenanceList on Maintenance {
  note
  startDate
  endDate
  tasks
  id
  createdAt
  updatedAt
  technicians {
    id
  }
  generator {
    id
    generatorCode
    brand
    kW
    contacts {
      id
    }
    client {
      id
      name
    }
  }
}
    `;
export const WorkReportListFragmentDoc = gql`
    fragment workReportList on WorkReport {
  createdAt
  id
  updatedAt
  operationType
  totalKm
  tankFuelLevel
  lunchBreak
  note
  generatorHours
  workHours
  registerCompiled
  registerNotCompiledReason
  positiveOperation
  cisternFuelLevel
  maintenance {
    ...MaintenanceList
  }
  client {
    ...ClientList
  }
  workReportCode
}
    ${MaintenanceListFragmentDoc}
${ClientListFragmentDoc}`;
export const CreateAccessoryDocument = gql`
    mutation CreateAccessory($data: CreateAccessoryInput!) {
  createAccessory(data: $data) {
    ...AccessoryDetail
  }
}
    ${AccessoryDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateAccessoryGQL extends Apollo.Mutation<CreateAccessoryMutation, CreateAccessoryMutationVariables> {
    document = CreateAccessoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateAccessoryDocument = gql`
    mutation UpdateAccessory($data: UpdateAccessoryInput!) {
  updateAccessory(data: $data) {
    ...AccessoryDetail
  }
}
    ${AccessoryDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateAccessoryGQL extends Apollo.Mutation<UpdateAccessoryMutation, UpdateAccessoryMutationVariables> {
    document = UpdateAccessoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    accessToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SignupDocument = gql`
    mutation Signup($data: SignupInput!) {
  signup(data: $data) {
    accessToken
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SignupGQL extends Apollo.Mutation<SignupMutation, SignupMutationVariables> {
    document = SignupDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateCarrierDocument = gql`
    mutation CreateCarrier($data: CreateCarrierInput!) {
  createCarrier(data: $data) {
    ...CarrierDetail
  }
}
    ${CarrierDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCarrierGQL extends Apollo.Mutation<CreateCarrierMutation, CreateCarrierMutationVariables> {
    document = CreateCarrierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateCarrierDocument = gql`
    mutation UpdateCarrier($data: UpdateCarrierInput!) {
  updateCarrier(data: $data) {
    ...CarrierDetail
  }
}
    ${CarrierDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateCarrierGQL extends Apollo.Mutation<UpdateCarrierMutation, UpdateCarrierMutationVariables> {
    document = UpdateCarrierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientContactDocument = gql`
    mutation CreateClientContact($data: CreateClientContactInput!) {
  createClientContact(data: $data) {
    ...ClientContactDetail
  }
}
    ${ClientContactDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientContactGQL extends Apollo.Mutation<CreateClientContactMutation, CreateClientContactMutationVariables> {
    document = CreateClientContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateClientDocument = gql`
    mutation CreateClient($data: CreateClientInput!) {
  createClient(data: $data) {
    ...ClientDetail
  }
}
    ${ClientDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateClientGQL extends Apollo.Mutation<CreateClientMutation, CreateClientMutationVariables> {
    document = CreateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClientContactDocument = gql`
    mutation UpdateClientContact($data: UpdateClientContactInput!) {
  updateClientContact(data: $data) {
    ...ClientContactDetail
  }
}
    ${ClientContactDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClientContactGQL extends Apollo.Mutation<UpdateClientContactMutation, UpdateClientContactMutationVariables> {
    document = UpdateClientContactDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateClientDocument = gql`
    mutation UpdateClient($data: UpdateClientInput!) {
  updateClient(data: $data) {
    ...ClientDetail
  }
}
    ${ClientDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateClientGQL extends Apollo.Mutation<UpdateClientMutation, UpdateClientMutationVariables> {
    document = UpdateClientDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateContractDocument = gql`
    mutation CreateContract($data: CreateContractInput!) {
  createContract(data: $data) {
    ...ContractDetail
  }
}
    ${ContractDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateContractGQL extends Apollo.Mutation<CreateContractMutation, CreateContractMutationVariables> {
    document = CreateContractDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateContractDocument = gql`
    mutation UpdateContract($data: UpdateContractInput!) {
  updateContract(data: $data) {
    ...ContractDetail
  }
}
    ${ContractDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateContractGQL extends Apollo.Mutation<UpdateContractMutation, UpdateContractMutationVariables> {
    document = UpdateContractDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateDdtDocument = gql`
    mutation CreateDDT($data: CreateDDTInput!) {
  createDDT(data: $data) {
    ...DDTDetail
  }
}
    ${DdtDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDdtGQL extends Apollo.Mutation<CreateDdtMutation, CreateDdtMutationVariables> {
    document = CreateDdtDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDdtDocument = gql`
    mutation UpdateDDT($data: UpdateDDTInput!) {
  updateDDT(data: $data) {
    ...DDTDetail
  }
}
    ${DdtDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDdtGQL extends Apollo.Mutation<UpdateDdtMutation, UpdateDdtMutationVariables> {
    document = UpdateDdtDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFilterDocument = gql`
    mutation CreateFilter($data: CreateFilterInput!) {
  createFilter(data: $data) {
    ...FilterDetail
  }
}
    ${FilterDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFilterGQL extends Apollo.Mutation<CreateFilterMutation, CreateFilterMutationVariables> {
    document = CreateFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFilterDocument = gql`
    mutation UpdateFilter($data: UpdateFilterInput!) {
  updateFilter(data: $data) {
    ...FilterDetail
  }
}
    ${FilterDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFilterGQL extends Apollo.Mutation<UpdateFilterMutation, UpdateFilterMutationVariables> {
    document = UpdateFilterDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateFilterGeneratorLinkDocument = gql`
    mutation CreateFilterGeneratorLink($data: CreateFilterGeneratorLinkInput!) {
  createFilterGeneratorLink(data: $data) {
    ...FilterGeneratorLinkDetail
  }
}
    ${FilterGeneratorLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateFilterGeneratorLinkGQL extends Apollo.Mutation<CreateFilterGeneratorLinkMutation, CreateFilterGeneratorLinkMutationVariables> {
    document = CreateFilterGeneratorLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateGeneratorDocument = gql`
    mutation CreateGenerator($data: CreateGeneratorInput!) {
  createGenerator(data: $data) {
    ...GeneratorDetail
  }
}
    ${GeneratorDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateGeneratorGQL extends Apollo.Mutation<CreateGeneratorMutation, CreateGeneratorMutationVariables> {
    document = CreateGeneratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFilterGeneratorLinkDocument = gql`
    mutation DeleteFilterGeneratorLink($data: DeleteFilterGeneratorLinkInput!) {
  deleteFilterGeneratorLink(data: $data) {
    deletedFilterId
    deletedGeneratorId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFilterGeneratorLinkGQL extends Apollo.Mutation<DeleteFilterGeneratorLinkMutation, DeleteFilterGeneratorLinkMutationVariables> {
    document = DeleteFilterGeneratorLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFilterGeneratorLinkDocument = gql`
    mutation UpdateFilterGeneratorLink($data: UpdateFilterGeneratorLinkInput!) {
  updateFilterGeneratorLink(data: $data) {
    ...FilterGeneratorLinkDetail
  }
}
    ${FilterGeneratorLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFilterGeneratorLinkGQL extends Apollo.Mutation<UpdateFilterGeneratorLinkMutation, UpdateFilterGeneratorLinkMutationVariables> {
    document = UpdateFilterGeneratorLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateGeneratorDocument = gql`
    mutation updateGenerator($data: UpdateGeneratorInput!) {
  updateGenerator(data: $data) {
    ...GeneratorDetail
  }
}
    ${GeneratorDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateGeneratorGQL extends Apollo.Mutation<UpdateGeneratorMutation, UpdateGeneratorMutationVariables> {
    document = UpdateGeneratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInvoiceRentalLinkDocument = gql`
    mutation CreateInvoiceRentalLink($data: CreateInvoiceRentalLinkInput!) {
  createInvoiceRentalLink(data: $data) {
    ...InvoiceRentalLinkDetail
  }
}
    ${InvoiceRentalLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInvoiceRentalLinkGQL extends Apollo.Mutation<CreateInvoiceRentalLinkMutation, CreateInvoiceRentalLinkMutationVariables> {
    document = CreateInvoiceRentalLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInvoiceWorkReportLinkDocument = gql`
    mutation CreateInvoiceWorkReportLink($data: CreateInvoiceWorkReportLinkInput!) {
  createInvoiceWorkReportLink(data: $data) {
    ...InvoiceWorkReportLinkDetail
  }
}
    ${InvoiceWorkReportLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInvoiceWorkReportLinkGQL extends Apollo.Mutation<CreateInvoiceWorkReportLinkMutation, CreateInvoiceWorkReportLinkMutationVariables> {
    document = CreateInvoiceWorkReportLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($data: CreateInvoiceInput!) {
  createInvoice(data: $data) {
    ...InvoiceDetail
  }
}
    ${InvoiceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInvoiceGQL extends Apollo.Mutation<CreateInvoiceMutation, CreateInvoiceMutationVariables> {
    document = CreateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteInvoiceRentalLinkDocument = gql`
    mutation DeleteInvoiceRentalLink($data: DeleteInvoiceRentalLinkInput!) {
  deleteInvoiceRentalLink(data: $data) {
    deletedInvoiceId
    deletedRentalId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteInvoiceRentalLinkGQL extends Apollo.Mutation<DeleteInvoiceRentalLinkMutation, DeleteInvoiceRentalLinkMutationVariables> {
    document = DeleteInvoiceRentalLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteInvoiceWorkReportLinkDocument = gql`
    mutation DeleteInvoiceWorkReportLink($data: DeleteInvoiceWorkReportLinkInput!) {
  deleteInvoiceWorkReportLink(data: $data) {
    deletedInvoiceId
    deletedWorkReportId
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteInvoiceWorkReportLinkGQL extends Apollo.Mutation<DeleteInvoiceWorkReportLinkMutation, DeleteInvoiceWorkReportLinkMutationVariables> {
    document = DeleteInvoiceWorkReportLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInvoiceRentalLinkDocument = gql`
    mutation UpdateInvoiceRentalLink($data: UpdateInvoiceRentalLinkInput!) {
  updateInvoiceRentalLink(data: $data) {
    ...InvoiceRentalLinkDetail
  }
}
    ${InvoiceRentalLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInvoiceRentalLinkGQL extends Apollo.Mutation<UpdateInvoiceRentalLinkMutation, UpdateInvoiceRentalLinkMutationVariables> {
    document = UpdateInvoiceRentalLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInvoiceWorkReportLinkDocument = gql`
    mutation UpdateInvoiceWorkReportLink($data: UpdateInvoiceWorkReportLinkInput!) {
  updateInvoiceWorkReportLink(data: $data) {
    ...InvoiceWorkReportLinkDetail
  }
}
    ${InvoiceWorkReportLinkDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInvoiceWorkReportLinkGQL extends Apollo.Mutation<UpdateInvoiceWorkReportLinkMutation, UpdateInvoiceWorkReportLinkMutationVariables> {
    document = UpdateInvoiceWorkReportLinkDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($data: UpdateInvoiceInput!) {
  updateInvoice(data: $data) {
    ...InvoiceDetail
  }
}
    ${InvoiceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInvoiceGQL extends Apollo.Mutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables> {
    document = UpdateInvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloneMaintenancesDocument = gql`
    mutation cloneMaintenances($data: CloneMaintenancesInput!) {
  cloneMaintenances(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloneMaintenancesGQL extends Apollo.Mutation<CloneMaintenancesMutation, CloneMaintenancesMutationVariables> {
    document = CloneMaintenancesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateMaintenanceDocument = gql`
    mutation CreateMaintenance($data: CreateMaintenanceInput!) {
  createMaintenance(data: $data) {
    ...MaintenanceDetail
  }
}
    ${MaintenanceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateMaintenanceGQL extends Apollo.Mutation<CreateMaintenanceMutation, CreateMaintenanceMutationVariables> {
    document = CreateMaintenanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteMaintenancesDocument = gql`
    mutation deleteMaintenances($id: String!) {
  deleteMaintenance(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteMaintenancesGQL extends Apollo.Mutation<DeleteMaintenancesMutation, DeleteMaintenancesMutationVariables> {
    document = DeleteMaintenancesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateMaintenanceDocument = gql`
    mutation UpdateMaintenance($data: UpdateMaintenanceInput!) {
  updateMaintenance(data: $data) {
    ...MaintenanceDetail
  }
}
    ${MaintenanceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateMaintenanceGQL extends Apollo.Mutation<UpdateMaintenanceMutation, UpdateMaintenanceMutationVariables> {
    document = UpdateMaintenanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateRentalDocument = gql`
    mutation CreateRental($data: CreateRentalInput!) {
  createRental(data: $data) {
    ...RentalDetail
  }
}
    ${RentalDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateRentalGQL extends Apollo.Mutation<CreateRentalMutation, CreateRentalMutationVariables> {
    document = CreateRentalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateRentalDocument = gql`
    mutation UpdateRental($data: UpdateRentalInput!) {
  updateRental(data: $data) {
    ...RentalDetail
  }
}
    ${RentalDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateRentalGQL extends Apollo.Mutation<UpdateRentalMutation, UpdateRentalMutationVariables> {
    document = UpdateRentalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateTechnicianDocument = gql`
    mutation CreateTechnician($data: CreateTechnicianInput!) {
  createTechnician(data: $data) {
    ...TechnicianDetail
  }
}
    ${TechnicianDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateTechnicianGQL extends Apollo.Mutation<CreateTechnicianMutation, CreateTechnicianMutationVariables> {
    document = CreateTechnicianDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTechnicianDocument = gql`
    mutation UpdateTechnician($data: UpdateTechnicianInput!) {
  updateTechnician(data: $data) {
    ...TechnicianDetail
  }
}
    ${TechnicianDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTechnicianGQL extends Apollo.Mutation<UpdateTechnicianMutation, UpdateTechnicianMutationVariables> {
    document = UpdateTechnicianDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateWorkReportDocument = gql`
    mutation CreateWorkReport($data: CreateWorkReportInput!) {
  createWorkReport(data: $data) {
    ...workReportDetail
  }
}
    ${WorkReportDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateWorkReportGQL extends Apollo.Mutation<CreateWorkReportMutation, CreateWorkReportMutationVariables> {
    document = CreateWorkReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateWorkReportDocument = gql`
    mutation UpdateWorkReport($data: UpdateWorkReportInput!) {
  updateWorkReport(data: $data) {
    ...workReportDetail
  }
}
    ${WorkReportDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateWorkReportGQL extends Apollo.Mutation<UpdateWorkReportMutation, UpdateWorkReportMutationVariables> {
    document = UpdateWorkReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AccessoriesDocument = gql`
    query Accessories {
  accessories {
    totalCount
    edges {
      node {
        ...AccessoryDetail
      }
    }
  }
}
    ${AccessoryDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AccessoriesGQL extends Apollo.Query<AccessoriesQuery, AccessoriesQueryVariables> {
    document = AccessoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CarriersDocument = gql`
    query Carriers {
  carriers {
    totalCount
    edges {
      node {
        ...CarrierDetail
      }
    }
  }
}
    ${CarrierDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CarriersGQL extends Apollo.Query<CarriersQuery, CarriersQueryVariables> {
    document = CarriersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClientsDocument = gql`
    query Clients($first: Int, $after: String, $name: String, $clientCode: Int) {
  clients(first: $first, after: $after, name: $name, clientCode: $clientCode) {
    totalCount
    edges {
      node {
        ...ClientDetail
      }
    }
  }
}
    ${ClientDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientsGQL extends Apollo.Query<ClientsQuery, ClientsQueryVariables> {
    document = ClientsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ContractsDocument = gql`
    query Contracts($first: Int, $after: String, $rentalAmount: Float) {
  contracts(first: $first, after: $after, rentalAmount: $rentalAmount) {
    totalCount
    edges {
      node {
        ...ContractDetail
      }
    }
  }
}
    ${ContractDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ContractsGQL extends Apollo.Query<ContractsQuery, ContractsQueryVariables> {
    document = ContractsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DdTsDocument = gql`
    query DDTs($first: Int, $after: String, $carrierName: String) {
  ddts(first: $first, after: $after, carrierName: $carrierName) {
    totalCount
    edges {
      node {
        ...DDTDetail
      }
    }
  }
}
    ${DdtDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DdTsGQL extends Apollo.Query<DdTsQuery, DdTsQueryVariables> {
    document = DdTsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FiltersDocument = gql`
    query Filters($first: Int, $after: String, $code: String, $category: String, $brand: String) {
  filters(first: $first, after: $after, code: $code, category: $category, brand: $brand) {
    totalCount
    edges {
      node {
        ...FilterDetail
      }
    }
  }
}
    ${FilterDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class FiltersGQL extends Apollo.Query<FiltersQuery, FiltersQueryVariables> {
    document = FiltersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratorDocument = gql`
    query Generator($id: String!) {
  generator(id: $id) {
    ...GeneratorDetail
  }
}
    ${GeneratorDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratorGQL extends Apollo.Query<GeneratorQuery, GeneratorQueryVariables> {
    document = GeneratorDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratorsListDocument = gql`
    query GeneratorsList($first: Int, $after: String, $kVA: Float, $clientName: String, $brand: String, $serialNumber: String, $status: GeneratorStatus) {
  generators(first: $first, after: $after, kVA: $kVA, clientName: $clientName, brand: $brand, serialNumber: $serialNumber, status: $status) {
    totalCount
    edges {
      node {
        ...GeneratorList
      }
    }
  }
}
    ${GeneratorListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratorsListGQL extends Apollo.Query<GeneratorsListQuery, GeneratorsListQueryVariables> {
    document = GeneratorsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GeneratorsDocument = gql`
    query Generators($first: Int, $after: String, $kVA: Float, $clientName: String, $brand: String, $serialNumber: String, $status: GeneratorStatus) {
  generators(first: $first, after: $after, kVA: $kVA, clientName: $clientName, brand: $brand, serialNumber: $serialNumber, status: $status) {
    totalCount
    edges {
      node {
        ...GeneratorDetail
      }
    }
  }
}
    ${GeneratorDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GeneratorsGQL extends Apollo.Query<GeneratorsQuery, GeneratorsQueryVariables> {
    document = GeneratorsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoiceDocument = gql`
    query Invoice($id: String!) {
  invoice(id: $id) {
    ...InvoiceDetail
  }
}
    ${InvoiceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoiceGQL extends Apollo.Query<InvoiceQuery, InvoiceQueryVariables> {
    document = InvoiceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const InvoicesDocument = gql`
    query Invoices($clientId: ID, $rentalId: ID) {
  invoices(clientId: $clientId, rentalId: $rentalId) {
    totalCount
    edges {
      node {
        ...InvoiceDetail
      }
    }
  }
}
    ${InvoiceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class InvoicesGQL extends Apollo.Query<InvoicesQuery, InvoicesQueryVariables> {
    document = InvoicesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaintenanceDocument = gql`
    query Maintenance($id: String!) {
  maintenance(id: $id) {
    ...MaintenanceFullDetail
  }
}
    ${MaintenanceFullDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaintenanceGQL extends Apollo.Query<MaintenanceQuery, MaintenanceQueryVariables> {
    document = MaintenanceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaintenancesListDocument = gql`
    query MaintenancesList($fromDate: DateTime, $toDate: DateTime, $generatorId: ID, $clientId: ID) {
  maintenances(fromDate: $fromDate, toDate: $toDate, generatorId: $generatorId, clientId: $clientId) {
    totalCount
    edges {
      node {
        ...MaintenanceList
      }
    }
  }
}
    ${MaintenanceListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaintenancesListGQL extends Apollo.Query<MaintenancesListQuery, MaintenancesListQueryVariables> {
    document = MaintenancesListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MaintenancesDocument = gql`
    query Maintenances($fromDate: DateTime, $generatorId: ID, $clientId: ID) {
  maintenances(fromDate: $fromDate, generatorId: $generatorId, clientId: $clientId) {
    totalCount
    edges {
      node {
        ...MaintenanceFullDetail
      }
    }
  }
}
    ${MaintenanceFullDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class MaintenancesGQL extends Apollo.Query<MaintenancesQuery, MaintenancesQueryVariables> {
    document = MaintenancesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RentalDocument = gql`
    query Rental($id: String!) {
  rental(id: $id) {
    ...RentalDetail
  }
}
    ${RentalDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RentalGQL extends Apollo.Query<RentalQuery, RentalQueryVariables> {
    document = RentalDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RentalsListDocument = gql`
    query RentalsList($first: Int, $after: String, $status: RentalStatus) {
  rentals(first: $first, after: $after, status: $status) {
    totalCount
    edges {
      node {
        ...RentalForInvoiceDetail
      }
    }
  }
}
    ${RentalForInvoiceDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RentalsListGQL extends Apollo.Query<RentalsListQuery, RentalsListQueryVariables> {
    document = RentalsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RentalsDocument = gql`
    query Rentals($first: Int, $after: String, $status: RentalStatus) {
  rentals(first: $first, after: $after, status: $status) {
    totalCount
    edges {
      node {
        ...RentalDetail
      }
    }
  }
}
    ${RentalDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class RentalsGQL extends Apollo.Query<RentalsQuery, RentalsQueryVariables> {
    document = RentalsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StatsDocument = gql`
    query Stats {
  stats {
    ...StatDetail
  }
}
    ${StatDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class StatsGQL extends Apollo.Query<StatsQuery, StatsQueryVariables> {
    document = StatsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TechniciansDocument = gql`
    query Technicians {
  technicians {
    totalCount
    edges {
      node {
        ...TechnicianDetail
      }
    }
  }
}
    ${TechnicianDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class TechniciansGQL extends Apollo.Query<TechniciansQuery, TechniciansQueryVariables> {
    document = TechniciansDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ViewerDocument = gql`
    query Viewer {
  viewer {
    ...UserDetail
  }
}
    ${UserDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ViewerGQL extends Apollo.Query<ViewerQuery, ViewerQueryVariables> {
    document = ViewerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkReportDocument = gql`
    query WorkReport($id: String!) {
  workReport(id: $id) {
    ...workReportDetail
  }
}
    ${WorkReportDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkReportGQL extends Apollo.Query<WorkReportQuery, WorkReportQueryVariables> {
    document = WorkReportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkReportsListDocument = gql`
    query WorkReportsList($first: Int, $after: String, $clientName: String, $generatorCode: Int) {
  workReports(first: $first, after: $after, clientName: $clientName, generatorCode: $generatorCode) {
    totalCount
    edges {
      node {
        ...workReportList
      }
    }
  }
}
    ${WorkReportListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkReportsListGQL extends Apollo.Query<WorkReportsListQuery, WorkReportsListQueryVariables> {
    document = WorkReportsListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const WorkReportsDocument = gql`
    query WorkReports($first: Int, $after: String, $clientName: String) {
  workReports(first: $first, after: $after, clientName: $clientName) {
    totalCount
    edges {
      node {
        ...workReportDetail
      }
    }
  }
}
    ${WorkReportDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class WorkReportsGQL extends Apollo.Query<WorkReportsQuery, WorkReportsQueryVariables> {
    document = WorkReportsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }