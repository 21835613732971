import { Injectable, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { Client, ClientContact } from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  constructor(
    private http: HttpClient
  ) { }

  snackBarRequestEvent: Subject<any> = new Subject();
  filterRentalsEvent: Subject<any> = new Subject();
  filterClientsEvent: Subject<any> = new Subject();
  filterDDTsEvent: Subject<any> = new Subject();
  filterGeneratorsEvent: Subject<any> = new Subject();
  filterAccessoriesEvent: Subject<any> = new Subject();
  filterFiltersEvent: Subject<any> = new Subject();
  filterTechniciansEvent: Subject<any> = new Subject();
  filterCarriersEvent: Subject<any> = new Subject();
  filterInvoicesEvent: Subject<any> = new Subject();
  filterContractsEvent: Subject<any> = new Subject();
  filterWorkReportsEvent: Subject<any> = new Subject();
  filterMaintenancesEvent: Subject<any> = new Subject();

  rightSidebarClick: Subject<any> = new Subject();
  reloadInvoicesEvent: Subject<any> = new Subject();

  showSpinnerEvent: Subject<any> = new Subject();

}
