<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="title">
            <mat-icon svgIcon="flash-circle" class="float-left"></mat-icon>
            <div class="item-div">ACCESSORI</div>
        </div>
        <div class="form-div">
            <form name="selectAccessoryForm" #f="ngForm" novalidate>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Nome</mat-label>
                    <input matInput (keyup)="filter($event, 'name')" placeholder="" [formGroup]="selectAccessoryForm"
                        name="name" [formControl]="settingsControl.name">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Codice seriale</mat-label>
                    <input matInput (keyup)="filter($event, 'serialNumber')" placeholder="" [formGroup]="selectAccessoryForm"
                        name="serialNumber" [formControl]="settingsControl.serialNumber">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </mat-card>
</div>