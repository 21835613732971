import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateInvoiceWorkReportLinkGQL,
  CreateInvoiceWorkReportLinkInput,
  DeleteInvoiceWorkReportLinkGQL,
  DeleteInvoiceWorkReportLinkInput,
  InvoicesDocument,
  UpdateInvoiceWorkReportLinkGQL,
  UpdateInvoiceWorkReportLinkInput
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class InvoiceWorkReportLinksService {

  constructor(
    private readonly createInvoiceWorkReportLinkService: CreateInvoiceWorkReportLinkGQL,
    private readonly updateInvoiceWorkReportLinkService: UpdateInvoiceWorkReportLinkGQL,
    private readonly deleteInvoiceWorkReportLinkService: DeleteInvoiceWorkReportLinkGQL,
  ) { }

  /*mutations*/
  addNewInvoiceWorkReportLink(payload: CreateInvoiceWorkReportLinkInput) {
    return this.createInvoiceWorkReportLinkService.mutate({ data: payload });
  }

  updateInvoiceWorkReportLink(payload: UpdateInvoiceWorkReportLinkInput) {
    return this.updateInvoiceWorkReportLinkService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateInvoiceWorkReportLink;
    }));
  }

  deleteInvoiceWorkReportLink(payload: DeleteInvoiceWorkReportLinkInput) {
    // return this.deleteInvoiceWorkReportLinkService.mutate({ data: payload }, {refetchQueries: [{query: InvoicesDocument}]}).pipe(map(val => {
    return this.deleteInvoiceWorkReportLinkService.mutate({ data: payload }).pipe(map(val => {
      return val.data.deleteInvoiceWorkReportLink;
    }));
  }

}
