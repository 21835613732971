<div class="main-container w-100">
    <div class="no-filter" *ngIf="getWorkReportsArray().controls.length === 0">Nessun buono di lavoro</div>
    <form [formGroup]="workReportsForms" *ngIf="getWorkReportsArray().controls.length > 0" class="w-100">
        <div formArrayName="workReportsArray">
            <div *ngFor="let item of getWorkReportsArray().controls; let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline" class="form-input filter-brand">
                    <mat-label>Buono di lavoro*</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            name="workReportId" formControlName="workReportId"
                            [matAutocomplete]="auto" (keyup)="autocompleteWorkReports($event)">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnWorkReports" (optionsScroll)="moreWorkReports()">
                            <mat-option *ngFor="let workReport  of filteredWorkReports" [value]="workReport">
  {{ workReport.maintenance?.generator.generatorCode }} - {{ workReport.client.name }} -
                            {{ workReport.maintenance?.startDate | date: 'dd/MM/yyyy' }}
                            </mat-option>
                        </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Totale</mat-label>
                    <input matInput placeholder="" name="amount" formControlName="amount">
                </mat-form-field>
                <button class="remove-btn" (click)="removeItem(i)" mat-button color="warn">
                    <mat-icon class="icons" color="warn" svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>
    </form>
    <div class="form-buttons-div">
        <button mat-flat-button color="primary" class="add-btn" (click)="addItem()">
            <mat-icon class="icons" svgIcon="plus"></mat-icon>
            AGGIUNGI BUONO DI LAVORO
        </button>
    </div>
</div>