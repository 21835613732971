<div class="maintenances-div" *ngIf="maintenances.length > 0">
    <!--<mat-accordion class="w-100" *ngFor="let man of maintenances; let ind = index" class="accordion">
        <mat-expansion-panel class="bg-grey" [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title class="accordion-title">
                    MANUTENZIONE del {{ man.startDate | date: 'dd/MM/yyyy'}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <app-new-maintenance [selectedGenerator]="selectedGenerator" [selectedMaintenance]="man"></app-new-maintenance>
        </mat-expansion-panel>
    </mat-accordion>-->
   <app-custom-table class="mt-3" [columns]="{displayedColumns: displayedColumns, columnLabels: columnLabels}" [title]="'MANUTENZIONI'" [data]="maintenances" [modalConfig]='modalConfig'></app-custom-table>
</div>
<div class="no-maintenance-div" *ngIf="maintenances.length === 0">
    Non hai ancora aggiunto nessuna manutenzione per questo gruppo.
</div>