<div class="main-container h-100 w-100">
    <div class="select-filter-div">
        <app-select-filter></app-select-filter>
    </div>

    <div class="row no-margin filters-container">
        <div class="col-3">
            <button class="w-100 add-filter-button" mat-button (click)="createNewFilter = true; selectedFilter = null">
                <mat-icon class="new-filter-icon" svgIcon="plus"></mat-icon>
                INSERISCI FILTRO
            </button>
        </div>
        <div class="col-9">
        </div>
        <div class="col-3 h-100 filter-card-div" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="loadMore()">
            <mat-card class="filter-card pointer" *ngFor="let filter of filteredFilters"
                [ngClass]="{selected: selectedFilter && selectedFilter.id === filter.id}"
                (click)="selectFilter(filter)">
                {{ filter.code }}
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="createNewFilter">
            <mat-card class="card">
                <app-new-filter></app-new-filter>
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="!createNewFilter">
            <app-filter-page [(selectedFilter)]="selectedFilter"></app-filter-page>
        </div>
    </div>
</div>