import { Component, OnInit, SimpleChanges } from '@angular/core';

import { ContractsService } from '@services/contracts/contracts.service';
import { Contract } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { environment } from '@env/environment';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent extends BaseComponent implements OnInit {
  // contracts: Contract[] = [];
  filteredContracts: Contract[] = [];
  selectedContract: Contract = null;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly contractsService: ContractsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.contractsService.getContracts({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((contracts: Contract[]) => {
        // this.contracts = contracts;
        this.filteredContracts = contracts;
        // this.filterContracts(this.currentFilter);
        if (this.selectedContract) {
          const id = this.selectedContract.id;
          this.selectedContract = this.filteredContracts.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterContractsEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterContracts()
      })
    );

  }

  ngOnInit(): void {
  }

  selectContract(el) {
    this.selectedContract = el;
  }

  filterContracts() {
   this.eventBusService.showSpinnerEvent.next(true);
    this.selectedContract = null;
    this.contractsService.getContracts(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((contracts: Contract[]) => {
      this.filteredContracts = contracts;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredContracts.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredContracts[this.filteredContracts.length - 1].id;
      this.contractsService.moreContracts(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // TODO: remove
  // filterContracts(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredContracts = this.contracts;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredContracts = this.contracts.filter((el) => {
  //     if (filter.from === 'rentalAmount') {
  //       return el.rentalAmount === filter.value;
  //     }
  //     return true;
  //   });
  // }

  download(id) {
    window.open(environment.apiUrlPdf + '/pdfs/contracts/' + id, '_blank');
    return true;
  }



}
