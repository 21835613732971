import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-carrier-page',
  templateUrl: './carrier-page.component.html',
  styleUrls: ['./carrier-page.component.scss']
})
export class CarrierPageComponent implements OnInit {
  @Input() selectedCarrier;
  constructor() {

  }

  ngOnInit(): void {
  }

}
