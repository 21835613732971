import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.scss']
})
export class SelectClientComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectClientForm: FormGroup;
  settingsControl = {
    name: new FormControl(''),
    clientCode: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectClientForm = formBuilder.group({
      name: this.settingsControl.name,
      clientCode: this.settingsControl.clientCode,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterClientsEvent.next(content);
    });
  }

  filter(ev, from) {
    let value = ev.target ? ev.target.value : ev.value;
    if (from === 'clientCode') {
      value = +value;
    }
    this.filterSubject.next({value, from});
  }
}
