import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateContractGQL,
  UpdateContractGQL,
  CreateContractInput,
  UpdateContractInput,
  ContractsGQL,
  ContractsDocument
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  constructor(
    private readonly contractsService: ContractsGQL,
    private readonly createContractService: CreateContractGQL,
    private readonly updateContractService: UpdateContractGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  getContracts(filter?: any, first?: number, after?: string) {
    return this.contractsService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.contracts.edges.map(el => el.node);
    }));
  }

  moreContracts(filter?: any, first?: number, after?: string) {
    return this.contractsService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  /*mutations*/
  addNewContract(payload: CreateContractInput) {
    // return this.createContractService.mutate({ data: payload }, {refetchQueries: [{query: ContractsDocument}]});
    return this.createContractService.mutate({ data: payload });
  }

  updateContract(payload: UpdateContractInput) {
    return this.updateContractService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateContract;
    }));
  }

}
