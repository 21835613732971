import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Observable, of, from, Subject } from 'rxjs';

import { Client, ClientContact, CreateMaintenanceInput, Generator, GeneratorStatus } from '@app/graphql/generated/graphql';
import { GeneratorsService } from '@services/generators/generators.service';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';
import { CountriesService } from '@services/rest-countries/countries.service';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { ClientsService } from '@services/clients/clients.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-generator',
  templateUrl: './new-generator.component.html',
  styleUrls: ['./new-generator.component.scss']
})
export class NewGeneratorComponent extends SubmitWithConfirmationComponent implements OnInit, OnChanges {
  @Input() selectedGenerator: Generator = null;
  addGeneratorForm: FormGroup;
  maintenanceMonthsForm: FormGroup;
  generatorStatuses = Constants.GENERATOR_STATES;
  purchaseStatuses = Constants.GENERATOR_PURCHASE_STATES;
  electricityTypes = Constants.GENERATOR_ELECTRICITY_TYPES;
  fuelTypes = Constants.GENERATOR_FUEL_TYPES;
  months = Constants.MONTHS;
  showTasks = false;
  showMaintenances = true;
  showContacts = false;
  showClients = false;
  MAINTENANCE_STATUS = GeneratorStatus.Managed;
  settingsControl = {
    generatorCode: new FormControl({ value: '', disabled: true }, [Validators.required]),
    status: new FormControl('', [Validators.required]),
    brand: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    kVA: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    kW: new FormControl('', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    engineBrand: new FormControl('', []),
    engineSerialNumber: new FormControl('', []),
    alternatorBrand: new FormControl('', []),
    alternatorSerialNumber: new FormControl('', []),
    productionYear: new FormControl(null, [Validators.pattern(/^[0-9]\d*$/)]),
    purchasePrice: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    purchaseStatus: new FormControl(null, []),
    purchaseYear: new FormControl(null, [Validators.pattern(/^[0-9]\d*$/)]),
    address: new FormControl('', []),
    postCode: new FormControl('', []),
    city: new FormControl('', []),
    province: new FormControl('', []),
    // region: new FormControl('', []),
    country: new FormControl('Italy', []),
    clientId: new FormControl(null, []),
    contactIds: new FormControl([], []),
    electricityType: new FormControl(null, []),
    fuelType: new FormControl(null, []),
    type: new FormControl('', []),
  };
  countries: string[] = [];
  filteredCountries: Observable<string[]>;
  tasksForms: FormGroup;
  // clients: Client[] = [];
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[];
  filterClients = {};
  contacts: ClientContact[] = [];
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly eventBusService: EventBusService,
    private readonly countriesService: CountriesService,
    private readonly clientsService: ClientsService,
    private readonly maintenancesService: MaintenancesService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewGeneratorComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addGeneratorForm = formBuilder.group({
      generatorCode: this.settingsControl.generatorCode,
      status: this.settingsControl.status,
      brand: this.settingsControl.brand,
      serialNumber: this.settingsControl.serialNumber,
      kVA: this.settingsControl.kVA,
      kW: this.settingsControl.kW,
      engineBrand: this.settingsControl.engineBrand,
      engineSerialNumber: this.settingsControl.engineSerialNumber,
      alternatorBrand: this.settingsControl.alternatorBrand,
      alternatorSerialNumber: this.settingsControl.alternatorSerialNumber,
      productionYear: this.settingsControl.productionYear,
      purchasePrice: this.settingsControl.purchasePrice,
      purchaseStatus: this.settingsControl.purchaseStatus,
      purchaseYear: this.settingsControl.purchaseYear,
      address: this.settingsControl.address,
      postCode: this.settingsControl.postCode,
      city: this.settingsControl.city,
      province: this.settingsControl.province,
      // region: this.settingsControl.region,
      country: this.settingsControl.country,
      clientId: this.settingsControl.clientId,
      contactIds: this.settingsControl.contactIds,
      electricityType: this.settingsControl.electricityType,
      fuelType: this.settingsControl.fuelType,
      type: this.settingsControl.type,
    });
    
    this.maintenanceMonthsForm = this.formBuilder.group({
      maintenanceMonths: this.formBuilder.array([])
    });
    for (const month of this.months) {
      this.getMaintenanceArray().push(
        new FormControl(false, []),
      );
    }
    /*this.subscriptions.push(
      this.eventBusService.isTaskFormsValidEvent.subscribe(val => {
        this.isTasksFormValid = val;
      })
    );*/
    this.tasksForms = this.formBuilder.group({
      tasksArray: this.formBuilder.array([])
    });
    // this.clientsService.getClients().subscribe((clients: Client[]) => {
    //   this.clients = clients;
    //   this.filteredClients = of(this.clients);
    // });
    // this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredClients = of(this.clients);
    //     return;
    //   }
    //   this.filteredClients = of(this._filterClients(value));
    // });

    if (dialogData && dialogData.selectedGenerator) {
      this.selectedGenerator = dialogData.selectedGenerator;
    }

  }

  ngOnInit(): void {
    this.eventBusService.showSpinnerEvent.next(true);
    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.eventBusService.showSpinnerEvent.next(false);
      this.filteredClients = clients;
    });

    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    if (this.selectedGenerator) {
      this.fillGenerator();
    }/* else {
      // FIXME: remove
      this.addGeneratorForm.patchValue({
        brand: 'Siemens',
        serialNumber: 'FIEL89383932',
        kVA: 93,
        kW: 2,
        engineBrand: 'Siemens',
        engineSerialNumber: 'UUU9X',
        alternatorBrand: 'Siemens',
        alternatorSerialNumber: '4AR',
        productionYear: 1994,
        purchasePrice: 22.45,
        purchaseStatus: 'NEW',
        purchaseYear: 1992
      });
    }*/
    this.countriesService.getCountries().subscribe((countries: any[]) => {
      this.countries = countries.map((el) => el.name);
    });
    this.filteredCountries = this.settingsControl.country.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.getMaintenanceArray().valueChanges.subscribe(val => {
      this.showTasks = val.some(el => el === true);
    });

    this.addGeneratorForm.get('status').valueChanges.subscribe(val => {
      if (val !== GeneratorStatus.Managed) {
        this.maintenanceMonthsForm.reset();
        this.tasksForms.reset();
        this.showMaintenances = false;
        this.addGeneratorForm.patchValue(Constants.DEFAULT_COMPANY_ADDRESS);
      } else {
        this.showMaintenances = true;
      }

      if (val === GeneratorStatus.Managed) {
        this.showClients = true;
        this.showClients = true;
      } else {
        this.addGeneratorForm.get('clientId').reset();
        this.addGeneratorForm.get('contactIds').reset();
        this.showClients = false;
        this.showContacts = false;
      }
    });

    this.addGeneratorForm.get('clientId').valueChanges.subscribe((val: Client) => {
      if (!val) {
        return;
      }
      this.showContacts = true;
      this.addGeneratorForm.get('contactIds').reset();
      this.contacts = val.contacts;
    });
  }

  private _filter(value: string): string[] {
    if (!value) {
      return this.countries;
    }
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  // private _filterClients(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }


  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }



  getMaintenanceArray() {
    return this.maintenanceMonthsForm.get('maintenanceMonths') as FormArray;
  }

  getTasksArray() {
    return this.tasksForms.get('tasksArray') as FormArray;
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedGenerator' in changes) {
      this.selectedGenerator = changes.selectedGenerator.currentValue;
      this.fillGenerator();
    }
  }

  submit() {
    const payload = this.addGeneratorForm.getRawValue();
    if ('generatorCode' in payload) {
      delete payload.generatorCode;
    }
    if (payload.purchaseYear) {
      payload.purchaseYear = parseFloat(payload.purchaseYear);
    }
    if (payload.purchasePrice) {
      payload.purchasePrice = parseFloat(payload.purchasePrice);
    }
    if (payload.kW) {
      payload.kW = parseFloat(payload.kW);
    }
    if (payload.kVA) {
      payload.kVA = parseFloat(payload.kVA);
    }
    if (payload.productionYear) {
      payload.productionYear = parseFloat(payload.productionYear);
    }
    if (payload.clientId) {
      payload.clientId = payload.clientId.id;
    }
    if (payload.contacts) {
      payload.contacts = payload.contacts.map(el => el.id);
    }
    let mutation: Observable<any>;
    let message = 'Gruppo elettrogeno inserito con successo';
    if (this.selectedGenerator) { // update
      payload.generatorId = this.selectedGenerator.id; // manually add the clientCode
      mutation = this.generatorsService.updateGenerator(payload);
      message = 'Gruppo elettrogeno aggiornato con successo';
    } else {
      mutation = this.generatorsService.addNewGenerator(payload);
      message = 'Gruppo elettrogeno inserito con successo';
    }
    mutation.subscribe(
      async (generator: Generator) => {
        if (this.showMaintenances && this.getMaintenanceArray().value && this.getMaintenanceArray().value.length > 0) {
          await this.createMaintenances(generator);
          await this.maintenancesService.getMaintenancesList();
        }
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedGenerator) {
          this.addGeneratorForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedGenerator) { // form for adding a client
      return true;
    } else {
      const formValue = this.addGeneratorForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedGenerator[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    if (this.dialogRef) {
      this.dialogRef.close();
      return;
    }
    this.addGeneratorForm.reset();
    this.addGeneratorForm.patchValue(this.selectedGenerator);
  }

  async createMaintenances(generator: Generator) {
    try {
      const months = this.getMaintenanceArray().value;
      for (let i = 0; i < months.length; i++) {
        const month = months[i];
        if (!month) {
          continue;
        }
        const year = new Date().getFullYear();
        const startDate = new Date(year, i, 1);
        const payload: CreateMaintenanceInput = {
          generatorId: generator.id,
          startDate,
          tasks: this.getTasksArray().value,
        };
        await this.maintenancesService.addNewMaintenance(payload).toPromise();
      }
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  fillGenerator() {
    this.addGeneratorForm.patchValue(this.selectedGenerator);
    console.log(this.selectedGenerator)
    if (this.selectedGenerator.client) {
      this.addGeneratorForm.get('clientId').setValue(this.selectedGenerator.client);
      this.showClients = true;
    }

    if (this.selectedGenerator.contacts && this.selectedGenerator.client) {
      this.showContacts = true;
      this.contacts = this.selectedGenerator.client.contacts;
      this.addGeneratorForm.get('contactIds').setValue(this.selectedGenerator.contacts.map(el => el.id));
    }
  }

moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

}
