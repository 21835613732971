import { Component, Inject, Input, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { Ddt, Client, Generator, Accessory, CreateDdtInput, UpdateDdtInput, Carrier, GeneratorStatus } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { EventBusService } from '@services/event-bus.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { AccessoriesService } from '@services/accessories/accessories.service';
import { Constants } from '@shared/constants';
import { DDTsService } from '@services/ddts/ddts.service';
import { CountriesService } from '@services/rest-countries/countries.service';
import { CarriersService } from '@services/carriers/carriers.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-ddt',
  templateUrl: './new-ddt.component.html',
  styleUrls: ['./new-ddt.component.scss']
})
export class NewDDTComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedDDT: Ddt = null;
  addDDTForm: FormGroup;
  generators: Generator[] = [];
  // clients: Client[] = [];
  carriers: Carrier[] = [];
  accessories: Accessory[] = [];
  filteredGenerators: Observable<Generator[]>;
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[];
  filterClients = {};
  paymentPreferences = Constants.PAYMENT_PREFERENCES;
  shippingCharges = Constants.SHIPPING_CHARGES;
  packageAppearance = Constants.PACKAGE_APPEARANCES;
  goodsForms: FormGroup;
  settingsControl = {
    clientId: new FormControl('', [Validators.required]),
    carrierId: new FormControl('', []),
    generatorIds: new FormControl([], []),
    date: new FormControl('', [Validators.required]),
    accessoryIds: new FormControl([], []),
    note: new FormControl('', []),
    address: new FormControl('', [Validators.required]),
    postCode: new FormControl('', [Validators.required]),
    city: new FormControl('', [Validators.required]),
    province: new FormControl('', [Validators.required]),
    // region: new FormControl('', [Validators.required]),
    country: new FormControl('Italy', [Validators.required]),
    reason: new FormControl('', []),
    paymentPreference: new FormControl('', [Validators.required]),
    packageAppearance: new FormControl('', [Validators.required]),
    packagesNumber: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    totalWeight: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    shippingCharges: new FormControl(null, []),
    otherGoods: new FormControl('', []),
  };
  countries: string[] = [];
  filteredCountries: Observable<string[]>;
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly accessoriesService: AccessoriesService,
    private readonly carrierService: CarriersService,
    private readonly ddtsService: DDTsService,
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private readonly countriesService: CountriesService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewDDTComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addDDTForm = formBuilder.group({
      clientId: this.settingsControl.clientId,
      generatorIds: this.settingsControl.generatorIds,
      carrierId: this.settingsControl.carrierId,
      date: this.settingsControl.date,
      address: this.settingsControl.address,
      postCode: this.settingsControl.postCode,
      city: this.settingsControl.city,
      province: this.settingsControl.province,
      // region: this.settingsControl.region,
      country: this.settingsControl.country,
      note: this.settingsControl.note,
      accessoryIds: this.settingsControl.accessoryIds,
      reason: this.settingsControl.reason,
      paymentPreference: this.settingsControl.paymentPreference,
      packageAppearance: this.settingsControl.packageAppearance,
      packagesNumber: this.settingsControl.packagesNumber,
      totalWeight: this.settingsControl.totalWeight,
      shippingCharges: this.settingsControl.shippingCharges,
      otherGoods: this.settingsControl.otherGoods,
    });
    this.countriesService.getCountries().subscribe((countries: any[]) => {
      this.countries = countries.map((el) => el.name);
    });

    this.goodsForms = this.formBuilder.group({
      goodsArray: this.formBuilder.array([])
    });
    if (dialogData && dialogData.selectedDDT) {
      this.selectedDDT = dialogData.selectedDDT;
    }
  }

  ngOnInit(): void {

    this.eventBusService.showSpinnerEvent.next(true);
    this.filteredCountries = this.settingsControl.country.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    if (!this.selectedDDT) { // otherwise the listener is set after fillDDT completed
      this.settingsControl.clientId.valueChanges.subscribe(val => {
        if (val) {
          this.addDDTForm.patchValue({
            address: val.address ? val.address : '',
            city: val.city ? val.city : '',
            province: val.province ? val.province : '',
            postCode: val.postCode ? val.postCode : '',
            // region: val.region ? val.region : '',
            country: val.country ? val.country : '',
          });
        }
      });
    }
    this.generatorsService.getGeneratorsList().subscribe((generators: Generator[]) => {
      this.generators = generators.filter(el => el.status === GeneratorStatus.Available || el.status === GeneratorStatus.Goods);
      this.filteredGenerators = of(this.generators);
    });
    // this.clientsService.getClients().subscribe((clients: Client[]) => {
    //   this.clients = clients;
    //   this.filteredClients = of(this.clients);
    // });
    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.eventBusService.showSpinnerEvent.next(false);
      this.filteredClients = clients;
    });
    this.carrierService.getCarriers().subscribe((carriers: Carrier[]) => {
      this.carriers = carriers;
    });
    this.accessoriesService.getAccessories().subscribe((accessories: Accessory[]) => {
      this.accessories = accessories;
    });

    // this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredGenerators = of(this.generators);
    //     return;
    //   }
    //   this.generatorsService.getGeneratorsList().subscribe((generators: Generator[]) => {
    //     this.filteredGenerators = of(this._filterGenerators(value));
    //   });
    // });
    // this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredClients = of(this.clients);
    //     return;
    //   }
    //   this.clientsService.getClients().subscribe((clients: Client[]) => {
    //     this.filteredClients = of(this._filterClients(value));
    //   });
    // });
    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    if (this.selectedDDT) {
      this.fillDDT();
    }
    this.addDDTForm.get('clientId').valueChanges.subscribe((val: Client) => {
      /*this.addDDTForm.patchValue({
        address: val.address,
        city: val.city,
        postCode: val.postCode,
        province: val.province,
        region: val.region,
        country: val.country,
      });*/
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedDDT' in changes && changes.selectedDDT.previousValue) {
      this.selectedDDT = changes.selectedDDT.currentValue;
      this.goodsForms = this.formBuilder.group({
        goodsArray: this.formBuilder.array([])
      });
      this.fillDDT();
    }
  }

  private _filter(value: string): string[] {
    if (value === '' || value === undefined) {
      return this.countries;
    }
    const filterValue = value.toLowerCase();
    return this.countries.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterGenerators(value: string): Generator[] {
    if (value === '') {
      this.filteredGenerators = of(this.generators);
      return;
    }
    const filterValue = value.toLowerCase();
    return this.generators.filter(el => el.serialNumber.toLowerCase().includes(filterValue));
  }

  // private _filterClients(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  // autocompleteGenerators(ev) {
  //   this.autocompleteGeneratorsSubject.next(ev.target.value);
  // }

  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  displayFnGenerators(generator: Generator): string {
    return generator && generator.serialNumber ? generator.serialNumber : '';
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }


  submit() {
    const payload = this.addDDTForm.value;
    payload.clientId = payload.clientId.id;
    let mutation: Observable<any> = this.ddtsService.addNewDDT(payload as CreateDdtInput);
    let message = 'DDT inserito con successo';
    payload.otherGoods = this.goodsForms.value.goodsArray;
    if ('packagesNumber' in payload) {
      payload.packagesNumber = parseFloat(payload.packagesNumber);
    }
    if ('totalWeight' in payload) {
      payload.totalWeight = parseFloat(payload.totalWeight);
    }
    if (this.selectedDDT) {
      payload.ddtId = this.selectedDDT.id;
      mutation = this.ddtsService.updateDDT(payload as UpdateDdtInput);
      message = 'DDT aggiornato con successo';
    }


    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedDDT) {
          this.addDDTForm.reset();
          this.goodsForms.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  fillDDT() {
    this.addDDTForm.patchValue({
      ...this.selectedDDT,
      clientId: this.selectedDDT.client,
      carrierId: this.selectedDDT.carrier ? this.selectedDDT.carrier.id : '',
      accessoryIds: this.selectedDDT.accessories.map(el => el.id),
      generatorIds: this.selectedDDT.generators.map(el => el.id),
    });
    if (this.selectedDDT.otherGoods) {
      for (const good of this.selectedDDT.otherGoods) {
        (this.goodsForms.get('goodsArray') as FormArray).push(
          this.formBuilder.group({
            description: [good.description, [Validators.required]],
            quantity: [good.quantity, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
          })
        );
      }
    }
    this.settingsControl.clientId.valueChanges.subscribe(val => {
      this.addDDTForm.patchValue({
        address: val.address ? val.address : '',
        city: val.city ? val.city : '',
        province: val.province ? val.province : '',
        postCode: val.postCode ? val.postCode : '',
        // region: val.region ? val.region : '',
        country: val.country ? val.country : '',
      });
    });
  }

  moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

}
