import { Component, OnInit, Output } from '@angular/core';

import { AuthService } from '@services/auth/auth.service';
import { BaseComponent } from '@shared/base/base.component';
import { EventEmitter } from '@angular/core';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent extends BaseComponent implements OnInit {
  @Output() leftClickEvent: EventEmitter<boolean> = new EventEmitter();
  isAuthenticated: boolean;
  constructor(
    private readonly authService: AuthService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.authService.isAuthenticatedSubject.subscribe((isAuth) => {
        this.isAuthenticated = isAuth;
      })
    );
  }

  ngOnInit(): void {
  }

  logout() {
    this.authService.logout();
    return;
  }


  leftClick() {
    this.leftClickEvent.emit(true);
  }

  rightClick() {
    this.eventBusService.rightSidebarClick.next(true);
  }

}
