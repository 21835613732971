import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateFilterGQL,
  CreateFilterInput,
  FiltersGQL,
  UpdateFilterInput,
  UpdateFilterGQL,
  FiltersQuery,
  FiltersDocument
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  constructor(
    private readonly filtersService: FiltersGQL,
    private readonly createFilterService: CreateFilterGQL,
    private readonly updateFilterService: UpdateFilterGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  getFilters(filter?: any, first?: number, after?: string) {
    return this.filtersService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.filters.edges.map(el => el.node);
    }));
  }

  moreFilters(filter?: any, first?: number, after?: string) {
    return this.filtersService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  /*mutations*/
  addNewFilter(payload: CreateFilterInput) {
    return this.createFilterService.mutate({ data: payload }, {refetchQueries: [{query: FiltersDocument}]});
    // return this.createFilterService.mutate({ data: payload });
  }

  updateFilter(payload: UpdateFilterInput) {
    return this.updateFilterService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateFilter;
    }));
  }

}
