import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { Contract, Client, Generator, Accessory, CreateContractInput, UpdateContractInput, Ddt, GeneratorStatus } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { EventBusService } from '@services/event-bus.service';
import { ContractsService } from '@services/contracts/contracts.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { AccessoriesService } from '@services/accessories/accessories.service';
import { Constants } from '@shared/constants';
import { SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-contract',
  templateUrl: './new-contract.component.html',
  styleUrls: ['./new-contract.component.scss']
})
export class NewContractComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedContract: Contract = null;
  addContractForm: FormGroup;
  generators: Generator[] = [];
  // clients: Client[] = [];
  accessories: Accessory[] = [];
  filteredGenerators: Observable<Generator[]>;
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[];
  filterClients = {};
  paymentTerms = Constants.PAYMENT_TERM;
  paymentModes = Constants.PAYMENT_MODE;
  settingsControl = {
    generatorIds: new FormControl([], []),
    clientId: new FormControl('', [Validators.required]),
    accessoryIds: new FormControl([], []),
    rentalDate: new FormControl('', [Validators.required]),
    extendedRental: new FormControl(false, [Validators.required]),
    rentalDays: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    rentalAmount: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    securityDepositAmount: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    shippingAddress: new FormControl('', [Validators.required]),
    paymentMode: new FormControl('', [Validators.required]),
    paymentTerms: new FormControl(null, []),
    monthlyPaymentsAmount: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    generatorInsuranceValue: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
  };
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly accessoriesService: AccessoriesService,
    private readonly contractsService: ContractsService,
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewContractComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addContractForm = formBuilder.group({
      generatorIds: this.settingsControl.generatorIds,
      clientId: this.settingsControl.clientId,
      rentalDate: this.settingsControl.rentalDate,
      extendedRental: this.settingsControl.extendedRental,
      rentalDays: this.settingsControl.rentalDays,
      rentalAmount: this.settingsControl.rentalAmount,
      securityDepositAmount: this.settingsControl.securityDepositAmount,
      shippingAddress: this.settingsControl.shippingAddress,
      paymentMode: this.settingsControl.paymentMode,
      paymentTerms: this.settingsControl.paymentTerms,
      monthlyPaymentsAmount: this.settingsControl.monthlyPaymentsAmount,
      accessoryIds: this.settingsControl.accessoryIds,
      generatorInsuranceValue: this.settingsControl.generatorInsuranceValue,
    });
    if (dialogData && dialogData.selectedContract) {
      this.selectedContract = dialogData.selectedContract;
    }
    /*if (dialogData && dialogData.selectedClientId) {
      this.addContractForm.get('clientId').setValue(dialogData.selectedClientId);
    }*/
  }

  ngOnInit(): void {

    this.eventBusService.showSpinnerEvent.next(true);
    this.generatorsService.getGeneratorsList().subscribe((generators: Generator[]) => {
      this.generators = generators.filter(el => {
        return el.status !== GeneratorStatus.Managed && el.status !== GeneratorStatus.Goods;
      });
      this.filteredGenerators = of(this.generators);
    });
    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.eventBusService.showSpinnerEvent.next(false);
      this.filteredClients = clients;
    });
    this.accessoriesService.getAccessories().subscribe((accessories: Accessory[]) => {
      this.accessories = accessories;
    });

    // this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredGenerators = of(this.generators);
    //     return;
    //   }
    //   this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //     this.filteredGenerators = of(this._filterGenerators(value));
    //   });
    // });
    // this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredClients = of(this.clients);
    //     return;
    //   }
    //   this.clientsService.getClients({ name: value }).subscribe((clients: Client[]) => {
    //     // this.filteredClients = of(this._filterClients(value));
    //     this.filteredClients = of(clients);

    //   });
    // });
    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    if (this.selectedContract) {
      this.fillContract();
    }/* else { // FIXME: remove
      this.addContractForm.patchValue({
        rentalDate: new Date(),
        extendedRental: true,
        rentalDays: 10,
        rentalAmount: 130.34,
        securityDepositAmount: 80,
        shippingAddress: 'Via Aldo Moro 87/A, 40134 Modena',
        paymentMode: 'BANK_TRANSFER',
        paymentTerms: 'PREPAYMENT',
        monthlyPaymentsMode: 'BANK_TRANSFER',
        monthlyPaymentsAmount: 45,
      })
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedContract' in changes) {
      this.selectedContract = changes.selectedContract.currentValue;
      this.fillContract();
    }
  }

  private _filterGenerators(value: string): Generator[] {
    if (value === '') {
      this.filteredGenerators = of(this.generators);
      return;
    }
    const filterValue = value.toLowerCase();
    return this.generators.filter(el => el.serialNumber.toLowerCase().includes(filterValue));
  }

  // private _filterClients(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  // autocompleteGenerators(ev) {
  //   this.autocompleteGeneratorsSubject.next(ev.target.value);
  // }

  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  displayFnGenerators(generator: Generator): string {
    return generator && generator.serialNumber ? `${generator.generatorCode} - ${generator.serialNumber}` : '';
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }


  submit() {
    const payload = this.addContractForm.value;
    payload.clientId = payload.clientId.id;
    console.log(payload)
    if (payload.rentalDays) {
      payload.rentalDays = parseFloat(payload.rentalDays);
    }
    if (payload.rentalAmount) {
      payload.rentalAmount = parseFloat(payload.rentalAmount);
    }
    if (payload.monthlyPaymentsAmount) {
      payload.monthlyPaymentsAmount = parseFloat(payload.monthlyPaymentsAmount);
    }
    if (payload.generatorInsuranceValue) {
      payload.generatorInsuranceValue = parseFloat(payload.generatorInsuranceValue);
    }
    if (payload.securityDepositAmount) {
      payload.securityDepositAmount = parseFloat(payload.securityDepositAmount);
    } else {
      payload.securityDepositAmount = null;
    }
    console.log(payload)
    let mutation: Observable<any> = this.contractsService.addNewContract(payload as CreateContractInput);
    let message = 'Contratto inserito con successo';
    if (this.selectedContract) {
      payload.contractId = this.selectedContract.id;
      mutation = this.contractsService.updateContract(payload as UpdateContractInput);
      message = 'Contratto aggiornato con successo';
    }

    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (this.selectedContract) {
          this.addContractForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  fillContract() {
    console.log(this.selectedContract)
    this.addContractForm.patchValue({
      ...this.selectedContract,
      clientId: this.selectedContract.client,
      generatorIds: this.selectedContract.generators.map(el => el.id),
      accessoryIds: this.selectedContract.accessories.map(el => el.id),
    });
  }

  moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

}
