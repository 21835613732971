import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-select-carrier',
  templateUrl: './select-carrier.component.html',
  styleUrls: ['./select-carrier.component.scss']
})
export class SelectCarrierComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectCarrierForm: FormGroup;
  settingsControl = {
    name: new FormControl(''),
    carrierCode: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectCarrierForm = formBuilder.group({
      name: this.settingsControl.name,
      carrierCode: this.settingsControl.carrierCode,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterCarriersEvent.next(content);
    });
  }

  filter(ev, from) {
    this.filterSubject.next({value: ev.target.value, from});
  }
}
