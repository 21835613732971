<div class="h-100 w-100 signup-container">
  <div class="row signup">
    <div class="col-12 form-container internal-shadow">
      <div class="row h-100">
        <div class="col-12 text-center title mb-5 mt-3">
          {{ 'common.SIGNUP'  | translate }}
        </div>
        <div class="col-10 offset-1 text-center">
          <form name="signupForm" (ngSubmit)=signup() #f="ngForm" novalidate>
            <div class="form-group row">
              <label for="exampleInputEmail1"
                class="col-sm-5 col-form-label">{{ 'signup.FIRST_NAME' | translate }}</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="firstName" aria-describedby="firstName"
                  [formGroup]="signupForm" name="firstName" [formControl]="settingsControl.firstName">

              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputEmail1"
                class="col-sm-5 col-form-label">{{ 'signup.LAST_NAME' | translate }}</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="firstName" aria-describedby="firstName" name="lastName"
                  [formGroup]="signupForm" [formControl]="settingsControl.lastName">

              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputEmail1" class="col-sm-5 col-form-label">E-mail</label>
              <div class="col-sm-7">
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                  name="email" [formGroup]="signupForm" [formControl]="settingsControl.email">
              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputPassword1" class="col-sm-5 col-form-label">Password</label>
              <div class="input-group col-sm-7">
                <input id="password" showHideInput type="password" class="form-control" name="password" #password
                  [formGroup]="signupForm" [formControl]="settingsControl.password" />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="material-icons eye" showHideTrigger="password" [showHideStatus]="{
                      materialIcon: true,
                      id: 'password'
                      }">
                    </i>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputPassword1"
                class="col-sm-5 col-form-label">{{ 'signup.CONFIRM_PASSWORD' | translate }}</label>
              <div class="input-group col-sm-7">
                <input type="password" showHideInput class="form-control" id="confirmPassword" name="confirmPassword"
                  #confirmPassword [formGroup]="signupForm" [formControl]="settingsControl.confirmPassword" />
                <div class="input-group-append">
                  <div class="input-group-text">
                    <i class="material-icons eye" showHideTrigger="confirmPassword" [showHideStatus]="{
                      materialIcon: true,
                      id: 'confirmPassword'
                      }">
                    </i>
                  </div>
                </div>

              </div>

            </div>
            <div class="form-group row">
              <label for="exampleInputEmail1" class="col-sm-5 col-form-label">{{ 'signup.COUNTRY' | translate }}</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="country" aria-describedby="country" [formGroup]="signupForm"
                  name="country" [formControl]="settingsControl.country">
              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputEmail1" class="col-sm-5 col-form-label">{{ 'signup.COMPANY' | translate }}</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="company" aria-describedby="company" [formGroup]="signupForm"
                  name="company" [formControl]="settingsControl.company">
              </div>
            </div>
            <div class="form-group row">
              <label for="exampleInputEmail1" class="col-sm-5 col-form-label">{{ 'signup.TITLE' | translate }}</label>
              <div class="col-sm-7">
                <input type="text" class="form-control" id="title" aria-describedby="title" [formGroup]="signupForm"
                  name="title" [formControl]="settingsControl.title">
              </div>
            </div>
            <div class="col-12">
                <small class="form-text text-muted" *ngIf="signupForm.controls['firstName'].errors?.minlength &&
          signupForm.controls['firstName'].touched">{{ 'signup.HINT_FIST_NAME' | translate }}</small>
                <small  class="form-text text-muted" *ngIf="signupForm.controls['lastName'].errors?.minlength &&
          signupForm.controls['lastName'].touched">{{ 'signup.HINT_LAST_NAME' | translate }}</small>
              <small class="form-text text-muted" *ngIf="signupForm.controls['email'].errors &&
          signupForm.controls['email'].touched">{{ 'signup.HINT_EMAIL' | translate }}</small>
              <small class="form-text text-muted" *ngIf="signupForm.controls['confirmPassword'].errors?.mismatch &&
          signupForm.controls['confirmPassword'].touched">{{ 'signup.HINT_PASSWORD' | translate }}</small>
            </div>
            <div class="row mt-4">
              <div class="offset-1 col-6 terms-conditions">By clicking Sign Up, you are indicating that you have read,
                understood, and agree to the <a href="#"> Privacy Policy.</a></div>
              <div class="col-5">
                <button class="buttons" type="submit"
                  [disabled]="!signupForm.valid">{{ 'common.SIGN_UP' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
