<div class="main-container h-100 w-100">
    <mat-tab-group class="w-100 h-100">
        <mat-tab label="FILTRI E BATTERIE">
            <mat-card class="card h-100">
                <app-filters></app-filters>
            </mat-card>
        </mat-tab>
        <mat-tab label="ACCESSORI">
            <mat-card class="card h-100">
                <app-accessories></app-accessories>
            </mat-card>
        </mat-tab>
        <mat-tab label="TECNICI">
            <app-technicians></app-technicians>
        </mat-tab>
        <mat-tab label="VETTORI">
            <app-carriers></app-carriers>
        </mat-tab>
        <mat-tab label="FATTURE">
            <app-invoices></app-invoices>
        </mat-tab>
    </mat-tab-group>
</div>