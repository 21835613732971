import { Injectable, Output } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from '@env/environment';
import { LoginGQL, LoginInput, SignupGQL } from '@app/graphql/generated/graphql';
import { StorageService } from '@services/storage/storage.service';
import { StorageTypeEnum } from '@services/storage/storage-type.enum';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  @Output() isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authenticationToken: string;

  constructor(
    private readonly http: HttpClient,
    private readonly loginService: LoginGQL,
    private readonly signupService: SignupGQL,
    private readonly storageService: StorageService,
    private router: Router,
    private apollo: Apollo,
  ) { }

  getAuthHeaders() {
    let auth = new HttpHeaders();
    auth = auth.set('Authorization', 'JWT ' + window.localStorage.getItem('token'));
    return auth;
  }

  login(email: string, password: string): Observable<any> {
    const payload: LoginInput = {
      email,
      password
    };
    return this.loginService.mutate({ data: payload }).pipe(
      map((response: any) => {
        this.isAuthenticatedSubject.next(true);
        this.apollo.client.resetStore();
        return response.data.login;
      })
    );
  }

  logout() {
    this.storageService.remove('token', StorageTypeEnum.SESSION_STORAGE);
    this.storageService.remove('user_data', StorageTypeEnum.SESSION_STORAGE);
    this.isAuthenticatedSubject.next(false);
    this.apollo.client.resetStore();
    this.router.navigate(['/login']);
    return;
  }

  public isAuthenticated(): Observable<boolean> {
    this.authenticationToken = this.storageService.retrieve('token', StorageTypeEnum.SESSION_STORAGE);
    if (this.authenticationToken !== null
      /*&& window.localStorage.getItem('user_data') !== null*/) {
      this.isAuthenticatedSubject.next(true);
      return of(true);
    } else {
      this.isAuthenticatedSubject.next(false);
      return of(false);
    }
  }

  public getAuthenticationToken(): Observable<string> {
    this.authenticationToken = this.storageService.retrieve('token', StorageTypeEnum.SESSION_STORAGE);
    if (this.authenticationToken !== null) {
      return of(this.authenticationToken);
    } else {
      return of(null);
    }
  }
}
