<div class="main-container w-100 h-100">
    <div class="select-generator-div">
        <app-select-generator></app-select-generator>
    </div>
    <div class="row no-margin generators-container">
        <div class="col-3 h-100 generator-card-div"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
        >
            <mat-card class="generator-card pointer" *ngFor="let generator of filteredGenerators"
                [ngClass]="{selected: selectedGenerator&& selectedGenerator.id === generator.id}"
                (click)="selectGenerator(generator)">
                <!--<span *ngIf="generator.status === maintenanceStatus">{{ generator.serialNumber}}</span>
                <span *ngIf="generator.status !== maintenanceStatus">{{ generator.generatorCode}} - {{ generator.serialNumber}}</span>-->
                <span>{{ generator.generatorCode}} - {{ generator.brand }} - {{ generator.kVA }}kVA</span>
            </mat-card>
        </div>
        <div class="col-9 h-100">
            <app-generator-page [(selectedGenerator)]="selectedGenerator"></app-generator-page>
        </div>
    </div>
</div>