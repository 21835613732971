<div class="main-container h-100 w-100">
    <div class="no-workReport" *ngIf="!selectedWorkReport">SELEZIONA UN BUONO DI LAVORO DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-workReport w-100 mt-3" *ngIf="selectedWorkReport">
        <mat-tab-group  (selectedTabChange)="activateTab($event.index)">
            <mat-tab label="DETTAGLI">
                <mat-card class="card">
                    <app-new-work-report [(selectedWorkReport)]="selectedWorkReport"></app-new-work-report>
                </mat-card>
            </mat-tab>
            <mat-tab label="PDF" class="h-100">
                <ng-template matTabContent>
                    <app-pdf-viewer *ngIf="toShow" [pdfUrl]="getPDFUrl()"></app-pdf-viewer>
            </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>