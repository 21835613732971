import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  ClientsGQL,
  CreateClientGQL,
  CreateClientInput,
  CreateClientContactInput,
  CreateClientContactGQL,
  UpdateClientContactGQL,
  UpdateClientContactInput,
  UpdateClientInput,
  UpdateClientGQL,
  ClientsDocument
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private readonly clientsService: ClientsGQL,
    private readonly createClientService: CreateClientGQL,
    private readonly createClientContactService: CreateClientContactGQL,
    private readonly updateClientService: UpdateClientGQL,
    private readonly updateClientContactService: UpdateClientContactGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  getClients(filter?: any, first?: number, after?: string) {
    return this.clientsService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.clients.edges.map(el => el.node);
    }));
  }

  moreClients(filter?: any, first?: number, after?: string) {
    return this.clientsService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  // getClientsByFilter(filter) {
  //   return this.clientsService.watch(filter).valueChanges.pipe(map(val => {
  //     return val.data.clients.edges.map(el => el.node);
  //   }));
  // }

  /*mutations*/
  addNewClient(payload: CreateClientInput) {
    // return this.createClientService.mutate({ data: payload}, {refetchQueries: [{query: ClientsDocument}]});
    return this.createClientService.mutate({ data: payload});
  }

  updateClient(payload: UpdateClientInput) {
    // return this.updateClientService.mutate({ data: payload }, {refetchQueries: [{query: ClientsDocument}]}).pipe(map(val => {
    return this.updateClientService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateClient;
    }));
  }

  addNewClientContact(payload: CreateClientContactInput) {
    return this.createClientContactService.mutate({ data: payload });
  }

  updateClientContact(payload: UpdateClientContactInput) {
    return this.updateClientContactService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateClientContact;
    }));
  }
}
