import { Component, Inject, Input, OnInit, Optional, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CreateInvoiceRentalLinkInput, CreateInvoiceWorkReportLinkInput, Invoice, InvoiceRentalLink, InvoiceWorkReportLink } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { Observable } from 'rxjs';
import { Constants } from '@shared/constants';
import { InvoicesService } from '@services/invoices/invoices.service';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvoiceRentalLinksService } from '@services/invoices/invoice-rental-links.service';
import { InvoiceWorkReportLinksService } from '@services/invoices/invoice-work-report-links.service';

@Component({
  selector: 'app-new-invoice',
  templateUrl: './new-invoice.component.html',
  styleUrls: ['./new-invoice.component.scss']
})
export class NewInvoiceComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedInvoice: Invoice = null;
  rentalsForms: FormGroup;
  addInvoiceForm: FormGroup;
  workReportsForms: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  selectedRentals: InvoiceRentalLink[];
  selectedWorkReports: InvoiceWorkReportLink[];
  settingsControl = {
    number: new FormControl('', [Validators.required, Validators.pattern(/^[0-9]\d*$/)]),
    amount: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly invoicesService: InvoicesService,
    private readonly eventBusService: EventBusService,
    private readonly invoiceRentalLinksService: InvoiceRentalLinksService,
    private readonly invoiceWorkReportLinksService: InvoiceWorkReportLinksService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewInvoiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addInvoiceForm = formBuilder.group({
      number: this.settingsControl.number,
      amount: this.settingsControl.amount,
    });
    this.rentalsForms = this.formBuilder.group({
      rentalsArray: this.formBuilder.array([])
    });

    this.workReportsForms = this.formBuilder.group({
      workReportsArray: this.formBuilder.array([])
    });
    if (dialogData && dialogData.selectedInvoice) {
      this.selectedInvoice = dialogData.selectedInvoice;
    }
  }

  ngOnInit(): void {
    if (this.selectedInvoice) {
      this.addInvoiceForm.patchValue(this.selectedInvoice);
      this.selectedRentals = this.selectedInvoice.rentals;
      this.selectedWorkReports = this.selectedInvoice.workReports;
    }
    this.rentalsForms.valueChanges.subscribe((val) => {
      if (val.rentalsArray && val.rentalsArray.length > 0) {
        const tot = val.rentalsArray.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        const tot1 = this.workReportsForms.get('workReportsArray').value.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        this.addInvoiceForm.get('amount').setValue(tot + tot1);
        this.addInvoiceForm.get('amount').disable();
      } else if (this.rentalsForms.get('rentalsArray').value.length === 0 && this.workReportsForms.get('workReportsArray').value.length > 0) {
        const tot = this.workReportsForms.get('workReportsArray').value.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        this.addInvoiceForm.get('amount').setValue(tot);
        this.addInvoiceForm.get('amount').disable();
      } else if (this.rentalsForms.get('rentalsArray').value.length === 0 && this.workReportsForms.get('workReportsArray').value.length === 0) {
        this.addInvoiceForm.get('amount').enable();
      }
    });
    this.workReportsForms.valueChanges.subscribe((val) => {
      if (val.workReportsArray && val.workReportsArray.length > 0) {
        const tot = val.workReportsArray.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        const tot1 = this.rentalsForms.get('rentalsArray').value.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        this.addInvoiceForm.get('amount').setValue(tot + tot1);
        this.addInvoiceForm.get('amount').disable();
      } else if (this.rentalsForms.get('rentalsArray').value.length > 0 && this.workReportsForms.get('workReportsArray').value.length === 0) {
        const tot = this.rentalsForms.get('rentalsArray').value.reduce((sum, el) => sum + (parseFloat(el.amount) || 0), 0);
        this.addInvoiceForm.get('amount').setValue(tot);
        this.addInvoiceForm.get('amount').disable();
      } else if (this.rentalsForms.get('rentalsArray').value.length === 0 && this.workReportsForms.get('workReportsArray').value.length === 0) {
        this.addInvoiceForm.get('amount').enable();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedInvoice' in changes && changes.selectedInvoice) {
      this.selectedInvoice = changes.selectedInvoice.currentValue;
      this.addInvoiceForm.patchValue(this.selectedInvoice);
      this.selectedRentals = this.selectedInvoice.rentals;
      this.selectedWorkReports = this.selectedInvoice.workReports;
    }
  }

  async submit() {
    const payload = this.addInvoiceForm.getRawValue();
    console.log(payload)
    payload.amount = parseFloat(payload.amount);
    let mutation: Observable<any>;
    let message = 'Fattura inserita con successo';
    if (this.selectedInvoice) { // update
      payload.invoiceId = this.selectedInvoice.id; // manually add the invoiceCode
      mutation = this.invoicesService.updateInvoice(payload);
      message = 'Fattura aggiornata con successo';
    } else {
      mutation = this.invoicesService.addNewInvoice(payload);
      message = 'Fattura inserita con successo';
    }
    let invoice: Invoice = null;
    try {
      const res = await mutation.toPromise();
      if (!this.selectedInvoice) {
        invoice = res.data.createInvoice;
      } else {
        invoice = this.selectedInvoice;
      }
    } catch (err) {
      console.log(err);
      this.eventBusService.snackBarRequestEvent.next({
        errorCode: 'Si è verificato un problema',
        style: Constants.SNACKBAR_ERROR
      });

    }
    try {
      // delete the previous reports
      if (this.selectedInvoice) {
        for (const rentalLink of this.selectedInvoice.rentals) {
          await this.invoiceRentalLinksService.deleteInvoiceRentalLink({invoiceId: this.selectedInvoice.id, rentalId: rentalLink.rentalId}).toPromise();
        }
       
      }
      for (const rental of this.rentalsForms.get('rentalsArray').value) {
        const body: CreateInvoiceRentalLinkInput = {
          invoiceId: invoice.id,
          amount: parseFloat(rental.amount),
          rentalId: rental.rentalId.id
        };
        await this.invoiceRentalLinksService.addNewInvoiceRentalLink(body).toPromise();
      }
      // delete the previous workReports
      if (this.selectedInvoice) {
        for (const workReportLink of this.selectedInvoice.workReports) {
          await this.invoiceWorkReportLinksService.deleteInvoiceWorkReportLink({invoiceId: this.selectedInvoice.id, workReportId: workReportLink.workReportId}).toPromise();
        }
      }
      for (const workReport of this.workReportsForms.get('workReportsArray').value) {
        const body: CreateInvoiceWorkReportLinkInput = {
          invoiceId: invoice.id,
          amount: parseFloat(workReport.amount),
          workReportId: workReport.workReportId.id
        };
        await this.invoiceWorkReportLinksService.addNewInvoiceWorkReportLink(body).toPromise();
      }
      this.eventBusService.snackBarRequestEvent.next({
        message,
        style: Constants.SNACKBAR_SUCCESS
      });
      await this.updateInvoices(); // manually update the invoices
      if (this.dialogRef) {
       this.dialogRef.close();
      }
      if (!this.selectedInvoice) {
        this.addInvoiceForm.reset();
        this.rentalsForms.reset();
        this.workReportsForms.reset();
      }
    } catch (err) {
      // TODO: delete the previous invoice created
      console.log(err);
      this.eventBusService.snackBarRequestEvent.next({
        errorCode: 'Si è verificato un problema',
        style: Constants.SNACKBAR_ERROR
      });
    }
  }

  showSubmitButton() {
    if (!this.selectedInvoice) { // form for adding a client
      return true;
    } else {
      const formValue = this.addInvoiceForm.getRawValue();
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedInvoice[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    this.addInvoiceForm.patchValue(this.selectedInvoice);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async updateInvoices() {
    this.eventBusService.reloadInvoicesEvent.next(true);
  }

}
