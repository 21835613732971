import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { GraphQLModule } from '@app/graphql.module';
import { AppMaterialModule } from '@app/app-material.module';
import { MatIconRegistry } from '@angular/material/icon';

import { AddModule } from '@pages/add/add.module';
import { MaintenancesComponent } from './maintenances.component';
import { MaintenancesCalendarComponent } from './maintenances-calendar/maintenances-calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
// import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { MaintenanceEventDetailComponent } from './maintenances-calendar/maintenance-event-detail/maintenance-event-detail.component';

/*export function momentAdapterFactory() {
  return adapterFactory(moment);
};*/


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    AppMaterialModule,
    NgbModalModule,
    AddModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [
    MaintenancesComponent,
    MaintenancesCalendarComponent,
    MaintenanceEventDetailComponent,
  ],
  providers: [],
  exports: [
  ],
})
export class MaintenancesModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
