import { Component, OnInit } from '@angular/core';
import { Technician } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { TechniciansService } from '@services/technicians/technicians.service';
import { BaseComponent } from '@shared/base/base.component';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss']
})
export class TechniciansComponent extends BaseComponent implements OnInit {

  selectedTechnician: Technician = null;
  technicians: Technician[] = [];
  filteredAccessories: Technician[] = [];
  createNewTechnician = false;
  currentTechnician: { value, from } = { value: '', from: '' };
  constructor(
    private readonly eventBusService: EventBusService,
    private readonly techniciansService: TechniciansService,
  ) {
    super();
    this.subscriptions.push(
      this.techniciansService.getTechnicians().subscribe((technicians: Technician[]) => {
        this.technicians = technicians;
        this.filteredAccessories = this.technicians;
        this.filterAccessories(this.currentTechnician);
        if (this.selectedTechnician) {
          const id = this.selectedTechnician.id;
          this.selectedTechnician = this.technicians.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterTechniciansEvent.subscribe((technician) => this.filterAccessories(technician))
    );

  }

  ngOnInit(): void {

  }

  selectTechnician(technician) {
    this.createNewTechnician = false;
    this.selectedTechnician = technician;
  }

  filterAccessories(technician: { value, from }) {
    if (!technician.from || !technician.value) {
      this.filteredAccessories = this.technicians;
      return;
    }
    this.currentTechnician = technician;
    this.filteredAccessories = this.technicians.filter((el) => {
      if (technician.from === 'email') {
        return el.email.toLowerCase().includes(technician.value.toLowerCase());
      }
      if (technician.from === 'lastName') {
        return el.lastName.toLowerCase().includes(technician.value.toLowerCase());
      }
      return true;
    });
  }


}
