<div class="form-container" [ngClass]="{'with-dialog': selectedContact, 'no-dialog': !selectedContact}">
    <div class="title text-center">INSERISCI RESPONSABILE CLIENTE</div>
    <form name="addContactForm" (ngSubmit)="selectedContact? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field *ngIf="!selectedContact" appearance="outline" class="form-input">
            <mat-label>Nome Cliente di riferimento</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addContactForm"
                name="clientId" [formControl]="settingsControl.clientId" [matAutocomplete]="auto"
                (keyup)="autocomplete($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionsScroll)="moreClients()">
                <mat-option *ngFor="let client of filteredClients" [value]="client">
                    {{ client.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <br *ngIf="!selectedContact">
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Nome</mat-label>
            <input matInput placeholder="" [formGroup]="addContactForm" name="firstName"
                [formControl]="settingsControl.firstName">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Cognome</mat-label>
            <input matInput placeholder="" [formGroup]="addContactForm" name="lastName"
                [formControl]="settingsControl.lastName">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="" [formGroup]="addContactForm" name="phone"
                [formControl]="settingsControl.phone">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Email</mat-label>
            <input matInput placeholder="" [formGroup]="addContactForm" name="phone"
                [formControl]="settingsControl.email">
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right"
            [disabled]="!addContactForm.valid">Salva</button>
    </form>
</div>