<div class="form-container">
    <div class="title text-center" *ngIf="!selectedDDT">INSERISCI DDT</div>
    <form name="addDDTForm" #f="ngForm" novalidate>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input" *ngIf="selectedDDT">
                <mat-label>Codice DDT*</mat-label>
                <input matInput placeholder="" disabled="true" [value]="selectedDDT.ddtCode">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Cliente*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addDDTForm" name="clientId"
                    [formControl]="settingsControl.clientId" [matAutocomplete]="auto" (keyup)="autocompleteClients($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionsScroll)="moreClients()">
                    <mat-option *ngFor="let client of filteredClients" [value]="client">
                        {{ client.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Generatori</mat-label>
                <mat-select [formControl]="settingsControl.generatorIds" multiple>
                    <mat-option *ngFor="let generator of filteredGenerators | async" [value]="generator.id">
                        {{ generator.generatorCode }} - {{ generator.serialNumber }}
                    </mat-option>
                </mat-select>
                <!--   <mat-label>Generatori</mat-label>
                            <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addDDTForm"
                                name="generatorIds" [formControl]="settingsControl.generatorIds" [matAutocomplete]="auto"
                                (keyup)="autocompleteGenerators($event)">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnGenerators">
                                <mat-option *ngFor="let generator of filteredGenerators | async" [value]="generator">
                                    {{ generator.serialNumber }}
                                </mat-option>
                            </mat-autocomplete>
                        -->
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Vettore</mat-label>
                <mat-select [formControl]="settingsControl.carrierId">
                    <mat-option *ngFor="let carrier of carriers" [value]="carrier.id">
                        {{ carrier.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Data*</mat-label>
                <!-- <input matInput [matDatepicker]="picker" [formGroup]="addDDTForm" name="date"
                                [formControl]="settingsControl.date"> -->
                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formGroup]="addDDTForm" name="date"
                    [formControl]="settingsControl.date">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker #picker [stepHour]="1" [stepMinute]="1">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <br>
            <br>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Causale</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="reason" [formControl]="settingsControl.reason">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Modalità di pagamento*</mat-label>
                <mat-select [formControl]="settingsControl.paymentPreference">
                    <mat-option *ngFor="let mode of paymentPreferences" [value]="mode.value">
                        {{ mode.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Aspetto esteriore*</mat-label>
                <mat-select [formControl]="settingsControl.packageAppearance">
                    <mat-option *ngFor="let pa of packageAppearance" [value]="pa.value">
                        {{ pa.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Numero colli</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="packagesNumber"
                    [formControl]="settingsControl.packagesNumber">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Peso totale</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="totalWeight"
                    [formControl]="settingsControl.totalWeight">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Tipo di porto</mat-label>
                <mat-select [formControl]="settingsControl.shippingCharges">
                    <mat-option *ngFor="let sc of shippingCharges" [value]="sc.value">
                        {{ sc.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Accessori</mat-label>
                <mat-select [formControl]="settingsControl.accessoryIds" multiple>
                    <mat-option *ngFor="let accessory of accessories" [value]="accessory.id">
                        {{ accessory.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Indirizzo*</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="address" [formControl]="settingsControl.address">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>CAP*</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="postCode" [formControl]="settingsControl.postCode">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Città*</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="city" [formControl]="settingsControl.city">
            </mat-form-field>
            
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Provincia*</mat-label>
                <input matInput placeholder="" [formGroup]="addDDTForm" name="province" [formControl]="settingsControl.province">
            </mat-form-field>
            
            
            <!--<mat-form-field appearance="outline" class="form-input">
                            <mat-label>Regione/Stato*</mat-label>
                            <input matInput placeholder="" [formGroup]="addDDTForm" name="region"
                                [formControl]="settingsControl.region">
                        </mat-form-field>-->
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Paese*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addDDTForm" name="country"
                    [formControl]="settingsControl.country" [matAutocomplete]="autoCountry">
                <mat-autocomplete #autoCountry="matAutocomplete">
                    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                        {{ country }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            </div>
            
            <mat-form-field appearance="outline" class="form-input textarea">
                <mat-label>Note</mat-label>
                <textarea matInput placeholder="" [formGroup]="addDDTForm" name="note" [formControl]="settingsControl.note" rows="5"
                    cols="40"></textarea>
            </mat-form-field>
            <br>
            </form>
            <app-add-goods [goodsForms]="goodsForms"></app-add-goods>
            <button mat-raised-button color="primary" type="submit" class="float-right"
                (click)="selectedDDT ? askConfirmation() : submit()"
                [disabled]="!addDDTForm.valid || !goodsForms.valid">Salva</button>
            </div>