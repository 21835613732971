import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';

import { GeneratorsService } from '@services/generators/generators.service';
import { CreateMaintenanceInput, Generator, GeneratorStatus, Maintenance, Technician, UpdateMaintenanceInput } from '@app/graphql/generated/graphql';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { TechniciansService } from '@services/technicians/technicians.service';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { Constants } from '@shared/constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-maintenance',
  templateUrl: './new-maintenance.component.html',
  styleUrls: ['./new-maintenance.component.scss']
})
export class NewMaintenanceComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedMaintenance: Maintenance = null;
  @Input() selectedGenerator: Generator = null;
  addMaintenanceForm: FormGroup;
  // generators: Generator[] = [];
  technicians: Technician[] = [];
  // filteredGenerators: Observable<Generator[]>;
  filteredGenerators: Generator[] = [];
  filterGenerators = {};
  filteredTechnicians: Observable<Technician[]>;
  settingsControl = {
    generatorId: new FormControl('', [Validators.required]),
    technicianIds: new FormControl([], []),
    note: new FormControl(null, []),
    startDate: new FormControl('', [Validators.required]),
  };
  tasksForms: FormGroup;
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteTechniciansSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly maintenancesService: MaintenancesService,
    private readonly techniciansService: TechniciansService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewMaintenanceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addMaintenanceForm = formBuilder.group({
      generatorId: this.settingsControl.generatorId,
      technicianIds: this.settingsControl.technicianIds,
      note: this.settingsControl.note,
      startDate: this.settingsControl.startDate,
    });
    if (dialogData && dialogData.selectedMaintenance) {
      this.selectedMaintenance = dialogData.selectedMaintenance;
    }

    if (dialogData && dialogData.selectedGenerator) {
      this.selectedGenerator = dialogData.selectedGenerator;
    }
  }

  ngOnInit(): void {
    this.eventBusService.showSpinnerEvent.next(true);
    this.generatorsService.getGeneratorsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
      this.eventBusService.showSpinnerEvent.next(false);
      // this.filteredGenerators.next(generators);
      this.filteredGenerators = generators;
    });
    // this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //   // this.generators = generators.filter(el => el.status !== GeneratorStatus.Managed);
    //   this.generators = generators;
    //   this.filteredGenerators = of(this.generators);
    // });
    this.techniciansService.getTechnicians().subscribe((technicians: Technician[]) => {
      this.technicians = technicians;
      this.filteredTechnicians = of(this.technicians);
    });

    // this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredGenerators = of(this.generators);
    //     return;
    //   }
    //   this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //     this.filteredGenerators = of(this._filterGenerators(value));
    //   });
    // });
    this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filterGenerators = {};
      } else {
        this.filterGenerators = { serialNumber: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.generatorsService.getGeneratorsList({ serialNumber: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredGenerators = generators;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    this.autocompleteTechniciansSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filteredTechnicians = of(this.technicians);
        return;
      }
      this.techniciansService.getTechnicians().subscribe((technicians: Technician[]) => {
        this.filteredTechnicians = of(this._filterTechnicians(value));
      });
    });
    this.tasksForms = this.formBuilder.group({
      tasksArray: this.formBuilder.array([])
    });
    if (this.selectedMaintenance) {
      this.fillMaintenance();
    }
  }

  // private _filterGenerators(value: string): Generator[] {
  //   if (value === '') {
  //     this.filteredGenerators = of (this.generators);
  //     return;
  //   }
  //   const filterValue = value.toLowerCase();
  //   return this.generators.filter(el => el.serialNumber.toLowerCase().includes(filterValue));
  // }

  private _filterTechnicians(value: string): Technician[] {
    const filterValue = value.toLowerCase();
    return this.technicians.filter(el => el.lastName.toLowerCase().includes(filterValue));
  }

  autocompleteGenerators(ev) {
    this.autocompleteGeneratorsSubject.next(ev.target.value);
  }

  autocompleteTechnicians(ev) {
    this.autocompleteTechniciansSubject.next(ev.target.value);
  }

  displayFn(generator: Generator): string {
    return generator && generator.serialNumber ? `${generator.generatorCode} - ${generator.serialNumber}` : '';
  }

  getTasksArray() {
    return this.tasksForms.get('tasksArray') as FormArray;
  }

  submit() {
    const payload = this.addMaintenanceForm.value;
    payload.generatorId = payload.generatorId.id;

    payload.tasks = this.getTasksArray().value;
    let mutation: Observable<any> = this.maintenancesService.addNewMaintenance(payload as CreateMaintenanceInput);
    let message = 'Manutenzione inserita con successo';
    if (this.selectedMaintenance) {
      payload.maintenanceId = this.selectedMaintenance.id;
      delete payload.generatorId;
      mutation = this.maintenancesService.updateMaintenance(payload as UpdateMaintenanceInput);
      message = 'Manutenzione aggiornata con successo';
    }

    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedMaintenance) {
          this.addMaintenanceForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  fillMaintenance() {
    this.addMaintenanceForm.patchValue({
      ...this.selectedMaintenance,
      technicianIds: this.selectedMaintenance.technicians.map(el => el.id),
      generatorId: this.selectedGenerator,
    });
    for (const task of this.selectedMaintenance.tasks) {
      this.getTasksArray().push(
        this.formBuilder.group({
          operation: [task.operation, [Validators.required]],
          description: [task.description, []],
          completed: [task.completed, []],
        })
      );
    }
  }

  moreGenerators() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredGenerators.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredGenerators[this.filteredGenerators.length - 1].id;
      this.generatorsService.moreGenerators(this.filterGenerators, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }
}
