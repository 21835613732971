import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Filter, Generator } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { FiltersService } from '@services/filters/filters.service';
import { FilterGeneratorLinksService } from '@services/generators/filter-generator-links.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-generator-filter-links',
  templateUrl: './generator-filter-links.component.html',
  styleUrls: ['./generator-filter-links.component.scss']
})
export class GeneratorFilterLinksComponent extends BaseComponent implements OnInit {
  @Input() selectedGenerator: Generator = null;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  filterLinksForms: FormGroup[] = [];
  filters: Filter[] = [];
  filtersPerCategory: any[] = [];
  currentFiltersPerCategory: any[] = [];
  categoriesArray: string[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
    private readonly filtersService: FiltersService,
    private readonly filterGeneratorLinksService: FilterGeneratorLinksService,
  ) {
    super();
    for (const cat of this.filterCategories) {
      this.categoriesArray.push(cat.value);
      this.filterLinksForms.push(
        this.formBuilder.group({
          filterLinksArray: this.formBuilder.array([])
        })
      );
      this.filtersPerCategory.push([]);
      this.currentFiltersPerCategory.push([]);
    }
    this.subscriptions.push(
      this.filtersService.getFilters().subscribe((filters: Filter[]) => {
        this.filters = filters;
        for (const filter of this.filters) {
          const cat = filter.category;
          const index = this.categoriesArray.indexOf(cat);
          this.filtersPerCategory[index].push(filter);
        }
        // this.currentFiltersPerCategory = this.filtersPerCategory.slice();
      }),
    );
  }

  ngOnInit(): void {
    this.fillFilters();
  }

  ngOnChanges(changes: SimpleChanges) {

    if ('selectedGenerator' in changes && changes.selectedGenerator.previousValue) {
      this.filterLinksForms = [];
      this.currentFiltersPerCategory = [];
      this.selectedGenerator = changes.selectedGenerator.currentValue;
      for (const cat of this.filterCategories) {
        this.filterLinksForms.push(
          this.formBuilder.group({
            filterLinksArray: this.formBuilder.array([])
          })
        );
        this.currentFiltersPerCategory.push([]);
      }
      this.fillFilters();
    }
  }

  getFilterLinksArray(index: number) {
    return this.filterLinksForms[index].get('filterLinksArray') as FormArray;
  }

  addItem(index: number) {
    this.getFilterLinksArray(index).push(
      this.formBuilder.group({
        filterId: ['', [Validators.required]],
        quantity: ['', [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
      })
    );
  }

  async removeItem(arrayIndex: number, index: number) {
    const val = this.getFilterLinksArray(arrayIndex).getRawValue()[index];
    const filterId = val.filterId;
    const quantity = val.quantity;
    if (this.alreadySaved(filterId)) {
      try {
        await this.filterGeneratorLinksService.deleteFilterGeneratorLink(
          {
            generatorId: this.selectedGenerator.id,
            filterId,
          }
        ).toPromise();
        this.eventBusService.snackBarRequestEvent.next({
          message: 'Filtro elminato con successo',
          style: Constants.SNACKBAR_SUCCESS
        });
      } catch (err) {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    }
    this.getFilterLinksArray(arrayIndex).removeAt(index);
    this.makeFiltersList(index);
  }

  async submit(index: number) {
    console.log(this.getFilterLinksArray(index));
    const filters = this.filters;
    const formValue = this.getFilterLinksArray(index).getRawValue();
    for (const el of formValue) {
      console.log(el)
      el.generatorId = this.selectedGenerator.id;
      el.quantity = parseFloat(el.quantity);
      let message = '';
      try {
        if (this.alreadySaved(el.filterId)) {
          el.filterId = el.filterId;
          await this.filterGeneratorLinksService.updateFilterGeneratorLink(el).toPromise();
          message = 'Filtro aggiornato con successo';
          this.makeFiltersList(index);
        } else {
          await this.filterGeneratorLinksService.addNewFilterGeneratorLink(el).toPromise();
          message = 'Filtro aggiunto con successo';
        }
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
      } catch (err) {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    }
  }

  checkDifference(index) {
    // TODO:
    return true;
  }

  fillFilters() {
    if (this.selectedGenerator.filters) {
      for (const filter of this.selectedGenerator.filters) {
        const cat = filter.filter.category;
        const index = this.categoriesArray.indexOf(cat);
        this.getFilterLinksArray(index).push(
          this.formBuilder.group({
            filterId: [{ value: filter.filterId, disabled: true }, [Validators.required]],
            quantity: [filter.quantity, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]],
          })
        );
        this.makeFiltersList(index);
      }
    }
  }

  makeFiltersList(index: number) {
    this.currentFiltersPerCategory[index] = [];
    this.getFilterLinksArray(index).getRawValue().forEach(element => {
      this.currentFiltersPerCategory[index].push(element);
    });
  }

  alreadyTaken(index: number, filterId: string) {
    return this.currentFiltersPerCategory[index].filter(f => f.filterId === filterId).length > 0;
  }

  alreadySaved(filterId: string) {
    return this.selectedGenerator.filters.filter(f => f.filterId === filterId).length > 0;
  }


}
