<div class="main-container h-100 w-100">
    <div class="no-client" *ngIf="!selectedClient">SELEZIONA UN CLIENTE DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-client w-100 mt-3" *ngIf="selectedClient">
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
            <mat-tab label="ANAGRAFICA">
                <mat-card class="card">
                    <app-new-client [(selectedClient)]="selectedClient"></app-new-client>
                </mat-card>
                <mat-card class="card">
                    <!--<app-client-contacts-list class="mt-3" [(selectedClient)]="selectedClient"></app-client-contacts-list>-->
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: contactsDisplayedColumns, columnLabels: contactsColumnLabels}" [title]="'RESPONSABILI CLIENTE'" [data]="selectedClient.contacts" [modalConfig]='contactsModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
            <mat-tab label="GRUPPI ELETTROGENI">
                <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: generatorsDisplayedColumns, columnLabels: generatorsColumnLabels}" [title]="'GRUPPI ELETTROGENI CLIENTE'" [data]="generators" [modalConfig]='generatorsModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
            <mat-tab label="CONTRATTI">
                <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: contractsDisplayedColumns, columnLabels: contractsColumnLabels}" [title]="'CONTRATTI CLIENTE'" [data]="contracts" [modalConfig]='contractsModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
            <mat-tab label="NOLEGGI">
                <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: rentalsDisplayedColumns, columnLabels: rentalsColumnLabels}" [title]="'NOLEGGI CLIENTE'" [data]="rentals" [modalConfig]='rentalsModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>

            <mat-tab label="DDT">
                <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: ddtsDisplayedColumns, columnLabels: ddtsColumnLabels}" [title]="'DDT CLIENTE'" [data]="ddts" [modalConfig]='ddtsModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
            <mat-tab label="FATTURE">
                <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: invoicesDisplayedColumns, columnLabels: invoicesColumnLabels}" [title]="'FATTURE CLIENTE'" [data]="invoices" [modalConfig]='invoicesModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>