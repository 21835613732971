import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';

import { BaseComponent } from '@shared/base/base.component';

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    // return if another validator has already found an error on the matchingControl
    /*if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
    }*/

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mismatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends BaseComponent implements OnInit, OnDestroy {
  signupForm: FormGroup;
  settingsControl = {
    firstName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    lastName: new FormControl('', [Validators.required, Validators.minLength(2)]),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    country: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    title: new FormControl('', [Validators.required]),
  };


  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();
    // Initializes the login fields
    this.signupForm = formBuilder.group({
      firstName: this.settingsControl.firstName,
      lastName: this.settingsControl.lastName,
      email: this.settingsControl.email,
      password: this.settingsControl.password,
      confirmPassword: this.settingsControl.confirmPassword,
      country: this.settingsControl.country,
      company: this.settingsControl.company,
      title: this.settingsControl.title,
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      }
    );
  }

  ngOnInit() {
  }

  ngOnDestroy() { }

  signup() {
  }



  /*signup() {
    const user = new User();
    user.firstName = this.signupForm.get('firstName').value;
    user.lastName = this.signupForm.get('lastName').value;
    user.email = this.signupForm.get('email').value;
    user.gender = 'male';
    user.password = this.signupForm.get('password').value;
    user.birthdate = new Date(this.signupForm.get('birthdate').value);
    user.invitedByEmail = this.signupForm.get('invitedByEmail').value;
    if (user.invitedByEmail === '') {
      user.invitedByEmail = undefined;
    }
    this.authService.signUp(user).subscribe(
      data => {
        this.eventBusService.sendSnackBarRequest({
          errorCode: 'info.signup_done',
          message: 'Benvenuto in FreeCopia! Ora puoi accedere e usufruire del servizio',
          action: '',
          style: Constants.SNACKBAR_SUCCESS
        });
        const userCred = {
          email: user.email,
          password: user.password
        };
        this.authService.login(userCred).subscribe(() => {
        });
      },
      err => {
        this.eventBusService.sendSnackBarRequest({
          errorCode: 'error.' + err.error.errorCode,
          message: null,
          action: '',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }*/

}
