<h1 mat-dialog-title class="text-center dialogTitle">Copia manutenzioni</h1>
<div mat-dialog-content>
  <form name="addDDTForm" #f="ngForm" novalidate>
    <div class="row">
    <div class="col-8 offset-2">
    <mat-form-field appearance="outline" class="form-input">
      <mat-label>Copia da*</mat-label>
      <input matInput [matDatepicker]="picker" placeholder="Scegli data" [formGroup]="cloneMaintenancesForm"
        name="fromDate" [formControl]="settingsControl.fromDate" (dateChange)="changeDate()">
      <mat-datepicker-toggle matsuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-input">
      <mat-label>Copia fino a*</mat-label>
      <input matInput [matDatepicker]="picker1" placeholder="Scegli data" [formGroup]="cloneMaintenancesForm"
        name="toDate" [formControl]="settingsControl.toDate" (dateChange)="changeDate()">
      <mat-datepicker-toggle matsuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
</div>
    <div class="col-8 offset-2">
    <mat-form-field appearance="outline" class="form-input">
      <mat-label>Nuova data da*</mat-label>
      <input matInput [matDatepicker]="picker2" placeholder="Scegli data" [formGroup]="cloneMaintenancesForm"
        name="newFromDate" [formControl]="settingsControl.newFromDate" (dateChange)="changeDate()">
      <mat-datepicker-toggle matsuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field appearance="outline" class="form-input">
      <mat-label>Nuova data fino a*</mat-label>
      <input matInput [matDatepicker]="picker3" placeholder="Scegli data" [formGroup]="cloneMaintenancesForm"
        name="newToDate" [formControl]="settingsControl.newToDate">
      <mat-datepicker-toggle matsuffix [for]="picker3"></mat-datepicker-toggle>
      <mat-datepicker #picker3></mat-datepicker>
    </mat-form-field>
</div>
</div>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button mat-flat-button color="warn" (click)="onNoClick()" class="buttons">Annulla</button>
  <button [disabled]="!cloneMaintenancesForm.valid" class="confirmButton buttons" color="primary" mat-stroked-button (click)="closeDialog()">Conferma</button>
</div>