<div class="row no-margin h-100 main-container">
    <div class="col-12 rentals">
        <mat-card class="w-100 containers">
            <div class="title-container">
                <div class="title">
                    <div class="icon-div">
                        <mat-icon svgIcon="clipboard-flow"></mat-icon>
                    </div>
                    <div class="item-div">NOLEGGI</div>
                </div>
            </div>
            <div class="contents">
                <mat-card class="element-container" *ngFor="let el of rentalsRecap">
                    <div class="counter">
                        {{ el.value }}
                    </div>
                    <div class="description">
                        {{ el.description }}
                    </div>
                </mat-card>
            </div>
        </mat-card>
    </div>

    <div class="col-12 enerators">
        <mat-card class="w-100 containers">
            <div class="title-container">
                <div class="title">
                    <div class="icon-div">
                        <mat-icon svgIcon="flash-circle"></mat-icon>
                    </div>
                    <div class="item-div">GRUPPI <br>ELETTROGENI</div>
                </div>
            </div>
            <div class="contents">
                <mat-card class="element-container" *ngFor="let el of generatorsRecap">
                    <div class="counter">
                        {{ el.value }}
                    </div>
                    <div class="description">
                        {{ el.description }}
                    </div>
                </mat-card>
            </div>
        </mat-card>
    </div>

    <div class="col-12 clients">
        <mat-card class="w-100 containers">
            <div class="title-container">
                <div class="title">
                    <div class="icon-div">
                        <mat-icon svgIcon="hammer-wrench"></mat-icon>
                    </div>
                    <div class="item-div">MANUTENZIONI</div>
                </div>
            </div>
            <div class="contents">
                <mat-card class="element-container" *ngFor="let el of maintenancesRecap">
                    <div class="counter">
                        {{ el.value }}
                    </div>
                    <div class="description">
                        {{ el.description }}
                    </div>
                </mat-card>
            </div>
        </mat-card>
    </div>
</div>