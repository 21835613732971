import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Maintenance, UpdateMaintenanceInput } from '@app/graphql/generated/graphql';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
registerLocaleData(localeIt);
import {
  startOfDay,
  isSameDay,
  isSameMonth,
  isAfter,
  isToday,
} from 'date-fns';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NewMaintenanceComponent } from '@pages/add/new-maintenance/new-maintenance.component';
import { MaintenanceEventDetailComponent } from './maintenance-event-detail/maintenance-event-detail.component';
import { AskConfirmComponent } from '@shared/ask-confirm-modal/ask-confirm-modal.component';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';
import { BaseComponent } from '@shared/base/base.component';
import moment from 'moment';
import { first } from 'rxjs/operators';

const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#009933',
    secondary: '#b3ffb3',
  },
};


@Component({
  selector: 'app-maintenances-calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './maintenances-calendar.component.html',
  styleUrls: ['./maintenances-calendar.component.scss']
})
export class MaintenancesCalendarComponent extends BaseComponent implements OnInit {
  @Input() maintenances: Maintenance[] = [];
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @Output() changeIntervalDate: EventEmitter<any> = new EventEmitter();
  filteredMaintenances: Maintenance[] = null;
  actions: CalendarEventAction[] = [
    {
      label: '<i class="material-icons action-icons mat-icon">edit</i>',
      a11yLabel: 'Modifica',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('edit', event);
      },
    },
    {
      label: '<i class="material-icons action-icons mat-icon">delete</i>',
      a11yLabel: 'Elimina',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('delete', event);
      },
    },
  ];
  events: CalendarEvent[] = [];
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  modalData: {
    action: string;
    event: CalendarEvent;
  };
  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;
  currentFilter: any = null;

  constructor(
    private dialogService: MatDialog,
    private readonly maintenancesService: MaintenancesService,
    private readonly eventBusService: EventBusService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
    this.filteredMaintenances = this.maintenances;
    // this.subscriptions.push(
    //   this.eventBusService.filterMaintenancesEvent.subscribe((val) => {
    //     // FIXME: check
    //     this.filterMaintenances(val);
    //     this.filterMaintenances(this.currentFilter);
    //     this.fillCalendar();
    //   })
    // );
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('maintenances' in changes) {
      this.filteredMaintenances = this.maintenances;
      this.fillCalendar();
    }
  }


  fillCalendar() {
    this.events = [];
    if (this.filteredMaintenances.length > 0) {
      this.filteredMaintenances.forEach(main => {
        this.addEvent(main);
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  addEvent(maintenance: Maintenance): void {
    let title = '';
    if (maintenance.generator && maintenance.generator.client) {
      title = maintenance.generator.client.name + ' - ' + maintenance.generator.brand + ' - ' + maintenance.generator.kW + 'kW';
      let tasks = '';
      maintenance.tasks.forEach(task => {
        tasks += this.getOperationName(task.operation) + ', ';
      });
      if (tasks !== '') {
        title += ', OPERAZIONI: ' + tasks.replace(/,([^,]*)$/, '$1');
      }
    } else {
      title = 'ERRORE, generatore non associato a cliente/generatore';
    }
    this.events = [
      ...this.events,
      {
        start: startOfDay(new Date(maintenance.startDate)),
        title,
        color: colors.yellow,
        allDay: true,
        actions: this.actions,
        draggable: true,
        meta: maintenance,
      },
    ];
    this.checkMaintenanceColor(this.events[this.events.length - 1]);
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    event.start = newStart;
    event.end = newEnd;
    this.handleEvent('move', event);
  }

  async handleEvent(action: string, event: CalendarEvent) {
    this.modalData = { event, action };
    if (action === 'edit') {
      this.openMaintenance(event.meta);
      this.checkMaintenanceColor(event);
    } else if (action === 'delete') {
      this.askConfirmation(event);
    } else if (action === 'move') {
      const payload = {
        startDate: event.start,
      };
      this.updateMaintenance(event.meta, payload);
      this.checkMaintenanceColor(event);
    } else if (action === 'Clicked') {
      this.openEventDetail(event);
    }
  }
  
  async deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
    await this.deleteMaintenance(eventToDelete.meta)
    this.changeDetectorRef.detectChanges();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  changeDate(date) {
    const month = moment(date).month();
    const year = moment(date).year();
    // const fromDate = moment([year, month, 1]).add(-7, 'days').toDate();
    // const toDate = moment([year, month+1, 1]).add(7, 'days').toDate();
    console.log(date)
    const fromDate = moment(date).startOf('month').add(-7, 'days').set({hour:0,minute:0,second:0,millisecond:0}).toDate();
    const toDate = moment(date).endOf('month').add(7, 'days').set({hour:0,minute:0,second:0,millisecond:0}).toDate();
    // console.log(fromDate)
    this.activeDayIsOpen = false; // this was here...
    this.changeIntervalDate.next({fromDate, toDate});
  }

  openMaintenance(m: Maintenance) {
    this.maintenancesService.getMaintenanceById(m.id).pipe(first()).subscribe(maintenance => {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = false;
      dialogConfig.autoFocus = true;
      dialogConfig.panelClass = 'new-maintenance-modal';
      dialogConfig.height = 'auto';
      dialogConfig.width = '70%';
      dialogConfig.data = {
        selectedMaintenance: maintenance,
        selectedGenerator: maintenance.generator,
      };
      const dialogRef = this.dialogService.open(NewMaintenanceComponent, dialogConfig);
      dialogRef
      .afterClosed()
      .subscribe(async (res) => {
        if (res) {
        }
      });
    });
  }

  openEventDetail(event: CalendarEvent) {
    this.maintenancesService.getMaintenanceById(event.meta.id).pipe(first()).subscribe(maintenance => {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'new-event-detail-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';
    dialogConfig.data = {
      selectedMaintenance: maintenance,
    };
    const dialogRef = this.dialogService.open(MaintenanceEventDetailComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .subscribe(async (res) => {
        this.checkMaintenanceColor(event);
      });
    });
  }

  askConfirmation(event: CalendarEvent) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = ['save'];
    dialogConfig.width = '50%';
    dialogConfig.data = {
      delete: true,
    };

    const dialogRef = this.dialogService.open(
      AskConfirmComponent,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.deleteEvent(event);
      }
    });
  }

  async updateMaintenance(maintenance: Maintenance, payload: any) {
    payload.maintenanceId = maintenance.id;
    const mutation = this.maintenancesService.updateMaintenance(payload as UpdateMaintenanceInput);
    const message = 'Manutenzione aggiornata con successo';
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  async deleteMaintenance(maintenance: Maintenance) {
    const mutation = this.maintenancesService.deleteMaintenance({id: maintenance.id});
    const message = 'Manutenzione eliminata con successo';
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
      },
      (error) => {
        console.log(error)
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  checkMaintenanceColor(event: CalendarEvent) {
    const today = new Date();
    if (event.meta.completed && event.meta.workReport) {
      event.color = colors.green;
      return;
    }
    if (isToday(event.start) && !event.meta.completed) {
      event.color = colors.yellow;
      return;
    }
    if (isAfter(today, event.start) && !event.meta.completed) {
      event.color = colors.red;
      return;
    }
    event.color = colors.blue;
  }

  filterMaintenances(val: any) {
    this.filteredMaintenances = this.maintenances;
    if (val) {
      this.currentFilter = val;
    } else {
      this.fillCalendar();
      return;
    }
    if (val.clientId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => {
       return man.generator.client && man.generator.client.id === val.clientId.id;
      });
    }
    if (val.generatorId) {
      console.log(val.generatorId.id, this.filteredMaintenances)
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => man.generator.id === val.generatorId.id);
    }
    if (val.technicianId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => {
        console.log(man.technicians)
        return man.technicians && man.technicians.some(tec => tec.id === val.technicianId.id)
      });
    }
    if (val.contactId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => man.generator.contacts.some(con => con.id === val.contactId.id));
    }
    console.log(this.filteredMaintenances)
    this.fillCalendar();
  }

  getOperationName(operation) {
    const operations = Constants.TASK_OPERATIONS;
    return operations.filter((op) => op.value === operation)[0].name;
  }

}
