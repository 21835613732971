<div class="row">
    <div class="contact col-4">
        <div class="title">
            Generatore
        </div>
        <div class="content" *ngIf="generator">
            <div class="field"><span class="key">Marca:</span> {{ generator.brand }} </div>
            <div class="field"><span class="key">Numero seriale:</span> {{ generator.serialNumber }} </div>
            <div class="field"><span class="key">Potenza:</span> {{ generator.kW }}kW - {{ generator.kVA }}kVA </div>
            <div class="field"><span class="key">Motore:</span> {{ generator.engineBrand }} -
                {{ generator.engineSerialNumber }} </div>
            <div class="field"><span class="key">Alternatore:</span> {{ generator.alternatorBrand }} -
                {{ generator.alternatorSerialNumber }} </div>
            <div class="field" *ngIf="generator.address"><span class="key">Indirizzo:</span>
                {{ formatAddress(generator) }} </div>
        </div>
        <div class="title mt-2">
            Filtri
        </div>
        <div class="content" *ngIf="generator && generator.filters.length === 0">
            Nessun filtro associato a questo generatore.
        </div>
        <div class="content" *ngFor="let filter of generator.filters">
            <div class="field"> {{ getFilterCategory(filter.filter.category) }} -
                {{ filter.filter.brand }} - {{ filter.filter.code }} x{{ filter.quantity }}</div>
        </div>
    </div>
    <div class="client col-4" *ngIf="client">
        <div class="title">
            Cliente
        </div>
        <div class="content">
            <div class="field"><span class="key">Nome:</span> {{ client.name }}</div>
            <div class="field"><span class="key">Email:</span> {{ client.email }}</div>
            <div class="field"><span class="key">Pec:</span> {{ client.pec }}</div>
            <div class="field"><span class="key">sdiCode:</span> {{ client.sdiCode }}</div>
            <div class="field"><span class="key">P.IVA:</span> {{ client.VATnumber }}</div>
            <div class="field"><span class="key">Telefono:</span> {{ client.phone }}</div>
        </div>
        <div class="title mt-2">
            Responsabili cliente
        </div>
        <div class="content" *ngIf="generator && generator.contacts.length === 0">
            Nessun responsabile associato a questo generatore.
        </div>
        <div class="content" *ngFor="let contact of contacts">
            <div class="field"><span class="key">Nome:</span> {{ contact.firstName }} {{ contact.lastName }}</div>
            <div class="field"><span class="key">Email:</span> {{ contact.email }}</div>
            <div class="field"><span class="key">Telefono:</span> {{ contact.phone }}</div>
        </div>
    </div>
    <div class="maintenance col-4">
        <div class="title">
            Tecnici
        </div>
        <div class="content" *ngIf="maintenance && maintenance.technicians.length === 0">
            Nessun tecnico associato a questa manutenzione.
        </div>
        <div class="content" *ngFor="let technician of maintenance.technicians">
            <div class="field"><span class="key">Nome:</span> {{ technician.firstName }} {{ technician.lastName }}
            </div>
        </div>
        <div class="title mt-2">
            Note Manutenzione
        </div>
        <div class="content" *ngIf="!maintenance.note || maintenance.note === ''">
            Nessuna nota presente.
        </div>
        <div class="content" *ngIf="maintenance.note">
            <div class="field">{{ maintenance.note }}</div>
        </div>
        <div class="title mt-2">
            Task
        </div>
        <div class="content" *ngIf="maintenance.tasks.length === 0">
            Nessun task associato a questo generatore.
        </div>
        <div class="content" *ngFor="let task of maintenance.tasks">
            <div class="field"><span class="key">Operazione:</span> {{ getOperationName(task.operation) }}
                <mat-icon svgIcon="information-outline" [matTooltip]="task.description" *ngIf="task.description"
                    color="primary"></mat-icon>
            </div>
        </div>
    </div>
</div>
<div class="w-100 row footer">
    <div class="maintenance-date col-4">
        <span class="key">Data Manutenzione: </span>{{ maintenance.startDate | date: 'dd/MM/yyyy'}}
    </div>
    <div class="col-6 offset-2 buttons-div ">
        <button class="buttons" color="primary" mat-stroked-button (click)="createWorkReport()">
        {{ maintenance.workReport?  "MODIFICA BUONO DI LAVORO" : "CREA BUONO DI LAVORO"}}
        </button>
        <button class="buttons" color="primary" mat-raised-button
            (click)="changeMaintenanceStatus()">{{ isMaintenanceCompleted ?  "RIAPRI MANUTENZIONE" : "COMPLETA MANUTENZIONE"}}</button>
    </div>
</div>