import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';

const ENTER_KEYCODE = 13;
const ESCAPE_KEYCODE = 27;

@Component({
  selector: 'app-clone-maintenances-modal',
  templateUrl: './clone-maintenances-modal.component.html',
  styleUrls: ['./clone-maintenances-modal.component.scss']
})
export class CloneMaintenancesModalComponent implements OnInit {

  cloneMaintenancesForm: FormGroup;
  feedback;
  translateBy: number;

settingsControl = {
    fromDate: new FormControl('', [Validators.required]),
    toDate: new FormControl('', [Validators.required]),
    newFromDate: new FormControl('', [Validators.required]),
    newToDate: new FormControl({disabled: true, value: ''}, [Validators.required]),
  };


  constructor(
    public dialogRef: MatDialogRef<CloneMaintenancesModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data
  ) { 
    this.cloneMaintenancesForm = formBuilder.group({
      fromDate: this.settingsControl.fromDate,
      toDate: this.settingsControl.toDate,
      newFromDate: this.settingsControl.newFromDate,
      newToDate: this.settingsControl.newToDate,
    });
  }

  
  ngOnInit() {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  
  closeDialog() {
    this.dialogRef.close({
      fromDate: moment(this.cloneMaintenancesForm.get('fromDate').value).startOf('day').toDate(),
      toDate: moment(this.cloneMaintenancesForm.get('toDate').value).endOf('day').toDate(),
      newFromDate: this.cloneMaintenancesForm.get('newFromDate').value,
      translateBy: this.translateBy
    });
  }
  
  changeDate() {
    const formValue = this.cloneMaintenancesForm.value;
    if (formValue.fromDate && formValue.toDate && formValue.newFromDate) {
      const fromDate = moment(formValue.fromDate).startOf('day');
      const toDate = moment(formValue.toDate).endOf('day');
      const newFromDate = moment(formValue.newFromDate);
      const diff = toDate.diff(fromDate, 'days');
      this.translateBy = newFromDate.diff(fromDate, 'days');
      if (diff < 0) {
        this.cloneMaintenancesForm.get('toDate').setValue('')
        return;
      } 
      const newToDate = newFromDate.add(diff, 'days').toDate();
      this.cloneMaintenancesForm.get('newToDate').setValue(newToDate)
    }
  }


  @HostListener('document:keydown', ['$event']) onKeydownHandler(
    event: KeyboardEvent
  ) {
    if (event.keyCode === ESCAPE_KEYCODE) {
      this.onNoClick();
    }
    if (event.keyCode === ENTER_KEYCODE) {
      this.closeDialog();
    }
  }
}
