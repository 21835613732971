import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { WorkReport } from '@app/graphql/generated/graphql';
import { environment } from '@env/environment';

@Component({
  selector: 'app-workReport-page',
  templateUrl: './workReport-page.component.html',
  styleUrls: ['./workReport-page.component.scss']
})
export class WorkReportPageComponent implements OnInit {
  @Input() selectedWorkReport: WorkReport = null;
  pdfUrl: string = null;
  public toShow = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedWorkReport' in changes && changes.selectedWorkReport.currentValue) {
      this.selectedWorkReport = changes.selectedWorkReport.currentValue;
      this.pdfUrl = environment.apiUrlPdf + '/pdfs/workReports/' + this.selectedWorkReport.id;
    }
  }

  getPDFUrl() {
    return this.pdfUrl;
  }


  activateTab(tab: number): void {
    this.hideOtherPDFs();
    setTimeout(() => {
      this.toShow = true;
    }, 100);
  }

  hideOtherPDFs(): void {
    this.toShow = false;
  }

}
