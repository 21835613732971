import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-select-rental',
  templateUrl: './select-rental.component.html',
  styleUrls: ['./select-rental.component.scss']
})
export class SelectRentalComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectRentalForm: FormGroup;
  rentalStatuses = Constants.RENTAL_STATUS;
  settingsControl = {
    status: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectRentalForm = formBuilder.group({
      status: this.settingsControl.status,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterRentalsEvent.next(content);
    });
  }

  filter(ev, from) {
    const value = ev.target ? ev.target.value : ev.value;
    this.filterSubject.next({value, from});
  }
}
