import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  StatsGQL,
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class StatsService {

  constructor(
    private readonly statsService: StatsGQL,
  ) { }

  /*queries*/
  getStats() {
    return this.statsService.watch().valueChanges.pipe(map(val => {
      return val.data.stats;
    }));
  }

}
