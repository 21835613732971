<div class="contacts-list-div mt-3">
  <div class="title text-center">RESPONSABILI CLIENTE</div>

  <mat-form-field *ngIf="dataSource.data.length > 0" appearance="outline" class="form-input w-100">
    <mat-label>Cerca</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Cerca" name="filter">
    <mat-icon matSuffix>search</mat-icon>
  </mat-form-field>

  <table *ngIf="dataSource.data.length > 0" mat-table [dataSource]="dataSource" matSort multiTemplateDataRows class="mat-elevation-z7 w-100">
    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nome</th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cognome</th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Telefono</th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
          <div class="buttons-container">
            <button mat-button class="buttons" (click)="openContactDetail(element)">
              <mat-icon svgIcon="pencil"></mat-icon>
              MODIFICA
            </button>
            <button mat-button class="buttons delete-button">
              <mat-icon svgIcon="trash-can"></mat-icon>
              ELIMINA
            </button>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="pointer"
      [class.example-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
    </tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
  </table>


  <div class="no-contacts w-100" *ngIf="dataSource.data.length === 0">NON CI SONO ANCORA RESPONSABILI PER QUESTO CLIENTE</div>


</div>