<div class="main-container">
    <div class="section-title">
        <mat-icon svgIcon="clipboard-flow"></mat-icon>
        NOLEGGI
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'rental']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            CREA NOLEGGIO
        </button>
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'ddt']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            CREA DDT
        </button>
    </div>
    <div class="section-title">
        <mat-icon svgIcon="account"></mat-icon>
        CLIENTI
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'client']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            CREA CLIENTE
        </button>
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'contract']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            CREA CONTRATTO
        </button>
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'client-contact']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            INSERISCI RESPONSABILE
        </button>
    </div>
    <div class="section-title">
        <mat-icon svgIcon="account"></mat-icon>
        GRUPPI ELETTROGENI
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'generator']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            INSERISCI GRUPPO
            ELETTROGENO
        </button>
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'workReport']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            CREA BUONO DI LAVORO
        </button>
    </div>
    <div class="w-100">
        <button mat-button [routerLink]="['add', 'maintenance']" (click)="rightSidebarClick()">
            <mat-icon svgIcon="plus"></mat-icon>
            INSERISCI MANUTENZIONE
        </button>
    </div>
</div>