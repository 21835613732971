import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-select-invoice',
  templateUrl: './select-invoice.component.html',
  styleUrls: ['./select-invoice.component.scss']
})
export class SelectInvoiceComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectInvoiceForm: FormGroup;
  settingsControl = {
    number: new FormControl('', [Validators.pattern(/^[0-9]\\d*$/)]),
    amount: new FormControl('', [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectInvoiceForm = formBuilder.group({
      number: this.settingsControl.number,
      amount: this.settingsControl.amount,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterInvoicesEvent.next(content);
    });
  }

  filter(ev, from) {
    this.filterSubject.next({value: ev.target.value, from});
  }
}
