import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Technician } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { TechniciansService } from '@services/technicians/technicians.service';
import { Observable } from 'rxjs';

import { Constants } from '@shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-technician',
  templateUrl: './new-technician.component.html',
  styleUrls: ['./new-technician.component.scss']
})
export class NewTechnicianComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedTechnician: Technician = null;
  addTechnicianForm: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  settingsControl = {
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', []),
    email: new FormControl('', [Validators.required]),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly filtersService: TechniciansService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
  ) {
    super(dialog);
    this.addTechnicianForm = formBuilder.group({
      firstName: this.settingsControl.firstName,
      lastName: this.settingsControl.lastName,
      phone: this.settingsControl.phone,
      email: this.settingsControl.email,
    });
  }

  ngOnInit(): void {
    if (this.selectedTechnician) {
      this.addTechnicianForm.patchValue(this.selectedTechnician);
    } /* else {
      // FIXME: remove
      this.addTechnicianForm.patchValue({
        firstName: 'Nome',
        lastName: 'Di Prova',
        phone: '',
        email: 'prova@gmail.com',
      });
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedTechnician' in changes) {
      this.selectedTechnician = changes.selectedTechnician.currentValue;
      this.addTechnicianForm.patchValue(this.selectedTechnician);
    }
  }

  submit() {
    const payload = this.addTechnicianForm.value;
    let mutation: Observable<any>;
    let message = 'Tecnico inserito con successo';
    if (this.selectedTechnician) { // update
      payload.technicianId = this.selectedTechnician.id; // manually add the clientCode
      mutation = this.filtersService.updateTechnician(payload);
      message = 'Tecnico aggiornato con successo';
    } else {
      mutation = this.filtersService.addNewTechnician(payload);
      message = 'Tecnico inserito con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedTechnician) {
          this.addTechnicianForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedTechnician) { // form for adding a client
      return true;
    } else {
      const formValue = this.addTechnicianForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedTechnician[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    this.addTechnicianForm.patchValue(this.selectedTechnician);
  }

}
