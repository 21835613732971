import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewClientComponent } from './new-client/new-client.component';
import { AddComponent } from './add.component';
import { NewClientContactComponent } from './new-client-contact/new-client-contact.component';
import { NewGeneratorComponent } from './new-generator/new-generator.component';
import { NewMaintenanceComponent } from './new-maintenance/new-maintenance.component';
import { NewRentalComponent } from './new-rental/new-rental.component';
import { NewDDTComponent } from './new-ddt/new-ddt.component';
import { AuthGuard } from '@services/auth/auth.guard';
import { NewContractComponent } from './new-contract/new-contract.component';
import { NewWorkReportComponent } from './new-workReport/new-workReport.component';


const routes: Routes = [
  {
    path: 'add', canActivate: [AuthGuard], component: AddComponent, children: [
      { path: 'generator', component: NewGeneratorComponent},
      { path: 'client', component: NewClientComponent},
      { path: 'client-contact', component: NewClientContactComponent},
      { path: 'maintenance', component: NewMaintenanceComponent},
      { path: 'rental', component: NewRentalComponent},
      { path: 'ddt', component: NewDDTComponent},
      { path: 'workReport', component: NewWorkReportComponent},
      { path: 'contract', component: NewContractComponent},
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddRoutingModule { }
