import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-add-goods',
  templateUrl: './add-goods.component.html',
  styleUrls: ['./add-goods.component.scss']
})
export class AddGoodsComponent extends BaseComponent implements OnInit {
  @Input() goodsForms: FormGroup = null;

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.goodsForms) {
      this.goodsForms = this.formBuilder.group({
        goodsArray: this.formBuilder.array([])
      });
    }



  }

  ngOnChanges(changes: SimpleChanges) {
  }

  getGoodsArray() {
    return this.goodsForms.get('goodsArray') as FormArray;
  }

  addItem() {
    this.getGoodsArray().push(
      this.formBuilder.group({
        description: new FormControl('', [Validators.required]),
        quantity: new FormControl(1, [Validators.required, Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
      })
    );
  }

  async removeItem(index: number) {
    this.getGoodsArray().removeAt(index);
  }

}
