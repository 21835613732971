import { Component, OnInit, SimpleChanges } from '@angular/core';

import { WorkReportsService } from '@services/workReports/workReports.service';
import { WorkReport } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { environment } from '@env/environment';
import { Constants } from '@shared/constants';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-work-reports',
  templateUrl: './workReports.component.html',
  styleUrls: ['./workReports.component.scss']
})
export class WorkReportsComponent extends BaseComponent implements OnInit {
  // workReports: WorkReport[] = [];
  filteredWorkReports: WorkReport[] = [];
  selectedWorkReport: WorkReport = null;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly workReportsService: WorkReportsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.workReportsService.getWorkReportsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((workReports: WorkReport[]) => {
        // this.workReports = workReports;
        this.filteredWorkReports = workReports;
        // this.filterWorkReports(this.currentFilter);
        // if (this.selectedWorkReport) {
        //   const id = this.selectedWorkReport.id;
        //   this.selectedWorkReport = this.filteredWorkReports.filter((el) => el.id === id)[0];
        // }
        this.eventBusService.showSpinnerEvent.next(false);
      }),
      this.eventBusService.filterWorkReportsEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterWorkReports()
      })
    );

  }

  ngOnInit(): void {
  }

  selectWorkReport(el) {
    this.workReportsService.getWorkReportById(el.id).pipe(first()).subscribe(val => {
      this.selectedWorkReport = val as WorkReport;
    });
  }

  filterWorkReports() {
    this.eventBusService.showSpinnerEvent.next(true);
    this.selectedWorkReport = null;
    this.workReportsService.getWorkReportsList(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((workReports: WorkReport[]) => {
      this.filteredWorkReports = workReports;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredWorkReports.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredWorkReports[this.filteredWorkReports.length - 1].id;
      this.workReportsService.moreWorkReports(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }
  
  // TODO: remove
  // filterWorkReports(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredWorkReports = this.workReports;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredWorkReports = this.workReports.filter((el) => {
  //     if (filter.from === 'clientName') {
  //       return el.client &&
  //       el.client.name.toLowerCase().includes(filter.value.toLowerCase());
  //     }
  //     if (filter.from === 'generatorCode') {
  //       return el.maintenance && el.maintenance.generator && el.maintenance.generator.generatorCode === parseFloat(filter.value);
  //     }
  //     return true;
  //   });
  // }

  download(id) {
    window.open(environment.apiUrlPdf + '/pdfs/workReports/' + id, '_blank');
    return true;
  }



}
