import { Component, OnInit } from '@angular/core';

import { BaseComponent } from '@shared/base/base.component';
import { Router } from '@angular/router';
import { StatsService } from '@services/stats/stats.service';
import { Stats } from '@app/graphql/generated/graphql';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  stats: Stats;
  rentalsRecap = [
    /*{ value: 23, description: 'Gruppi elettrogeni da consegnare' },
    { value: 3, description: 'Gruppi elettrogeni da ritirare' },
    { value: 10, description: 'Noleggi scaduti' },*/
  ];

  maintenancesRecap = [
    /*{ value: 23, description: 'Contratti da finalizzare' },
    { value: 3, description: 'Contratti in scadenza' },
    { value: 10, description: 'Noleggi da fatturare' },
    { value: 10, description: 'Noleggi da fatturare' },
    { value: 10, description: 'Noleggi da fatturare' },*/
  ];

  generatorsRecap = [
    /*{ value: 23, description: 'Buoni lavoro nei prossimi 30 giorni' },
    { value: 3, description: 'Buoni di lavori scaduti' },*/
  ];

  constructor(
    private router: Router,
    private readonly statsService: StatsService,
  ) {
    super();
    this.subscriptions.push(
      this.statsService.getStats().subscribe(stats => {
        console.log(stats)
        this.stats = stats;
        this.makeStats();
      })
    );
  }

  ngOnInit(): void {

  }

  makeStats() {
    this.rentalsRecap.push({value: this.stats.rentalsCount, description: 'Numero noleggi'});
    this.rentalsRecap.push({value: this.stats.tobeInvoicedRentalsCount, description: 'Noleggi da fatturare'});
    this.rentalsRecap.push({value: this.stats.rentedGeneratorsCount, description: 'Generatori in noleggio'});

    this.generatorsRecap.push({value: this.stats.generatorsCount, description: 'Numero gruppi elettrogeni'});
    this.generatorsRecap.push({value: this.stats.nearMaintenanceGeneratorsCount, description: 'Generatori vicini alla manutenzione'});

    this.maintenancesRecap.push({value: this.stats.maintenancesCount, description: 'Numero manutenzioni'});
    this.maintenancesRecap.push({value: this.stats.thisMonthMaintenancesCount, description: 'Numero manutenzioni in questo mese'});
    this.maintenancesRecap.push({value: this.stats.withoutWorkReportMaintenancesCount, description: 'Manutenzioni senza buono di lavoro'});


  }
}
