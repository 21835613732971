import { ElectricityType, FuelType, GeneratorStatus, OperationType, PaymentMode, PaymentPreference, PaymentTerm, PurchaseStatus, ShippingChargesType } from '@app/graphql/generated/graphql';
export class Constants {
  public static readonly SNACKBAR_INFO: string = 'snackbar-info';
  public static readonly SNACKBAR_SUCCESS: string = 'snackbar-success';
  public static readonly SNACKBAR_ERROR: string = 'snackbar-error';

  public static readonly GENERATOR_STATES: { name, value }[] = new Array(
    { name: 'Disponibile per noleggio', value: GeneratorStatus.Available },
    { name: 'In manutenzione presso cliente', value: GeneratorStatus.Managed },
    { name: 'A noleggio', value: GeneratorStatus.Rented },
    { name: 'Merce', value: GeneratorStatus.Goods }
  );
  public static readonly GENERATOR_PURCHASE_STATES: { name, value }[] = new Array(
    { name: 'Nuovo', value: PurchaseStatus.New },
    { name: 'Usato', value: PurchaseStatus.Used }
  );

  public static readonly FILTER_CATEGORIES: { name, value }[] = new Array(
    { name: 'Olio', value: 'OIL' },
    { name: 'Acqua', value: 'WATER' },
    { name: 'Batteria', value: 'BATTERY' },
    { name: 'Aria', value: 'AIR' },
    { name: 'Gasolio', value: 'FUEL' }
  );

  // public static readonly TASK_OPERATIONS: { name, value }[] = new Array(
  //   { name: 'Cambio olio', value: 'OIL_CHANGE' },
  //   { name: 'Cambio filtri', value: 'FILTERS_CHANGE' },
  //   { name: 'Cambio antigelo', value: 'ANTIFREEZE_CHANGE' },
  //   { name: 'Cambio batterie', value: 'BATTERIES_CHANGE' },
  //   { name: 'Altro', value: 'OTHERS' },
  // );

  public static readonly TASK_OPERATIONS: { name, value }[] = new Array(
    { name: 'Cambio olio', value: 'OIL_CHANGE' },
    { name: 'Controllo livello olio', value: 'OIL_CHECK' },
    { name: 'Controllo livello acqua motore', value: 'WATER_ENGINE_CHECK' },
    { name: 'Cambio filtri gasolio', value: 'FUEL_FILTERS_CHANGE' },
    { name: 'Controllo livello elettrolita', value: 'ELECTROLYTE_CHECK' },
    { name: 'Cambio filtri acqua', value: 'WATER_FILTERS_CHANGE' },
    { name: 'Controllo densità elettrolita', value: 'ELECTROLYTE_DENSITY_CHECK' },
    { name: 'Cambio filtri aria', value: 'AIR_FILTERS_CHANGE' },
    { name: 'Controllo taratura preriscaldamento mot.', value: 'ENGINE_CALIBRATION_CHECK' },
    { name: 'Controllo visivo perdite', value: 'LEAKS_CHECK' },
    { name: 'Pulizia prefiltri aria', value: 'AIR_PREFILTERS_CLEANING' },
    { name: 'Pulizia filtri aria', value: 'AIR_FILTERS_CLEANING' },
    { name: 'Controllo tiraggio cinghie trapezoidali', value: 'FORCED_DRAUGHT_CHECK' },
    { name: 'Controllo serraggio cavi', value: 'WIRES_CHECK' },
    { name: 'Pulizia generale', value: 'CLEANING' },
    { name: 'Ingrassaggio', value: 'LUBRICATION' },
    { name: 'Prova generale macchina', value: 'GENERATOR_TEST' },
    { name: 'Controllo serraggio bulloni', value: 'BOLTS_CHECK' },
    { name: 'Controllo parti mecchaniche', value: 'MECHANISMS_CHECK' },
    { name: 'Verifica automatismi', value: 'AUTOMATISMS_CHECK' },
    { name: 'Controllo parametri elettrici', value: 'ELECTRIC_PARAMETERS_CHECK' },
    { name: 'Prova dei trasmettitori allarme', value: 'ALARMS_CHECK' },
    { name: 'Controllo quadro elettrico di comando', value: 'CONTROL_PANEL_CHECK' },
    { name: 'Cambio filtri', value: 'FILTERS_CHANGE' },
    { name: 'Cambio antigelo', value: 'ANTIFREEZE_CHANGE' },
    { name: 'Cambio batterie', value: 'BATTERIES_CHANGE' },
  );

  public static readonly FILTER_BRANDS: { name, value }[] = new Array(
    { name: 'Tecnocar', value: 'TECNOCAR' },
    { name: 'Sofima', value: 'SOFIMA' },
    { name: 'Ufi', value: 'UFI' },
    { name: 'MannFilter', value: 'MANNFILTER' },
    { name: 'Bosch', value: 'BOSCH' },
    { name: 'Donaldson', value: 'DONALDSON' },
    { name: 'Fiaam Filtres', value: 'FIAAM_FILTRES' },
    { name: 'Virgis Filters', value: 'VIRGIS_FILTERS' },
    { name: 'FleetGuard', value: 'FLEETGUARD' },
    { name: 'CAV', value: 'CAV' },
    { name: 'Delphi', value: 'DELPHI' },
    { name: 'FIAMM', value: 'FIAMM' },
    { name: 'Varta', value: 'VARTA' },
    { name: 'Iveco', value: 'IVECO' },
    { name: 'Volvo', value: 'VOLVO' },
    { name: 'Bowden', value: 'BOWDEN' },
  );

  public static readonly MONTHS: string[] = [
    'Gennaio',
    'Febbraio',
    'Marzo',
    'Aprile',
    'Maggio',
    'Giugno',
    'Luglio',
    'Agosto',
    'Settembre',
    'Ottobre',
    'Novembre',
    'Dicembre'
  ];

  public static readonly RENTAL_STATUS: { name, value }[] = new Array(
    { name: 'In entrata', value: 'IN' },
    { name: 'In uscita', value: 'OUT' },
    { name: 'Fatturato', value: 'INVOICE' },
  );

  public static readonly PAYMENT_MODE: { name, value }[] = new Array(
    { name: 'Bonifico bancario', value:  PaymentMode.BankTransfer },
    { name: 'Assegno bancario', value: PaymentMode.BankCheque },
    { name: 'Assegno circolare', value:  PaymentMode.BankDraft},
    { name: 'Contanti', value: PaymentMode.Cash },
  );

  public static readonly PAYMENT_TERM: { name, value }[] = new Array(
    { name: '50%', value: PaymentTerm.Half },
    { name: 'Pagamento alla consegna', value: PaymentTerm.Prepayment },
    { name: 'Pagamento alla restituzione', value: PaymentTerm.Postpayment },
  );

  public static DEFAULT_COMPANY_ADDRESS = new Object({
    address: 'Loc. S. Zeno 60/B',
    postCode: '52040',
    city: 'Arezzo',
    province: 'AR',
    region: 'Emilia-Romagna',
    country: 'Italy'
  });

  public static readonly PAYMENT_PREFERENCES: { name, value }[] = new Array(
    { name: 'In conto', value: PaymentPreference.Account },
    { name: 'In saldo', value: PaymentPreference.Balance },
  );

  public static readonly SHIPPING_CHARGES: { name, value }[] = new Array(
    { name: '', value: null },
    { name: 'Porto franco', value: ShippingChargesType.FreePort },
    { name: 'Porto assegnato', value: ShippingChargesType.AssignedPort },
  );

  public static readonly GENERATOR_ELECTRICITY_TYPES: { name, value }[] = new Array(
    { name: '', value: null },
    { name: 'Monofase', value: ElectricityType.MonoPhase },
    { name: 'Trifase', value: ElectricityType.ThreePhase },
  );

  public static readonly GENERATOR_FUEL_TYPES: { name, value }[] = new Array(
    { name: '', value: null },
    { name: 'Benzina', value: FuelType.Gasoline },
    { name: 'Diesel', value: FuelType.Diesel },
    { name: 'Gpl', value: FuelType.Gpl },
    { name: 'Metano', value: FuelType.Methane },
    { name: 'Ibrido', value: FuelType.Hybrid },
  );



  public static readonly PACKAGE_APPEARANCES: { name, value }[] = new Array(
    { name: 'A vista', value: 'VISIBLE'},
    { name: 'Su pallet', value: 'ON_PALLET'},
    { name: 'Imballo', value: 'PACKAGING'},
    { name: 'Altro', value: 'OTHER'},
  );

  public static readonly OPERATION_TYPES: { name, value }[] = new Array(
    { name: 'Straordinaria', value: OperationType.Extraordinary },
    { name: 'Ordinaria', value: OperationType.Ordinary },
  );

  public static readonly MAINTENANCES_TASKS: { name, value }[] = new Array(
    { name: 'Cambio olio', value: 'OIL_CHANGE' },
    { name: 'Controllo livello olio', value: 'OIL_CHECK' },
    { name: 'Controllo livello acqua motore', value: 'WATER_ENGINE_CHECK' },
    { name: 'Cambio filtri gasolio', value: 'FUEL_FILTERS_CHANGE' },
    { name: 'Controllo livello elettrolita', value: 'ELECTROLYTE_CHECK' },
    { name: 'Cambio filtri acqua', value: 'WATER_FILTERS_CHANGE' },
    { name: 'Controllo densità elettrolita', value: 'ELECTROLYTE_DENSITY_CHECK' },
    { name: 'Cambio filtri aria', value: 'AIR_FILTERS_CHANGE' },
    { name: 'Controllo taratura preriscaldamento mot.', value: 'ENGINE_CALIBRATION_CHECK' },
    { name: 'Controllo visivo perdite', value: 'LEAKS_CHECK' },
    { name: 'Pulizia prefiltri aria', value: 'AIR_PREFILTERS_CLEANING' },
    { name: 'Pulizia filtri aria', value: 'AIR_FILTERS_CLEANING' },
    { name: 'Controllo tiraggio cinghie trapezoidali', value: 'FORCED_DRAUGHT_CHECK' },
    { name: 'Controllo serraggio cavi', value: 'WIRES_CHECK' },
    { name: 'Pulizia generale', value: 'CLEANING' },
    { name: 'Ingrassaggio', value: 'LUBRICATION' },
    { name: 'Prova generale macchina', value: 'GENERATOR_TEST' },
    { name: 'Controllo serraggio bulloni', value: 'BOLTS_CHECK' },
    { name: 'Controllo parti mecchaniche', value: 'MECHANISMS_CHECK' },
    { name: 'Verifica automatismi', value: 'AUTOMATISMS_CHECK' },
    { name: 'Controllo parametri elettrici', value: 'ELECTRIC_PARAMETERS_CHECK' },
    { name: 'Prova dei trasmettitori allarme', value: 'ALARMS_CHECK' },
    { name: 'Controllo quadro elettrico di comando', value: 'CONTROL_PANEL_CHECK' },
    { name: 'Cambio filtri', value: 'FILTERS_CHANGE' },
    { name: 'Cambio antigelo', value: 'ANTIFREEZE_CHANGE' },
    { name: 'Cambio batterie', value: 'BATTERIES_CHANGE' },
  );
  public static readonly PAGINATION_ELEMENTS_NUMBER: number = 20;
}
