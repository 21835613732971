import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Carrier } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { Observable } from 'rxjs';
import { Constants } from '@shared/constants';
import { CarriersService } from '@services/carriers/carriers.service';
import { MatDialog } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-carrier',
  templateUrl: './new-carrier.component.html',
  styleUrls: ['./new-carrier.component.scss']
})
export class NewCarrierComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedCarrier: Carrier = null;
  addCarrierForm: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  settingsControl = {
    name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    sdiCode: new FormControl('', []),
    carrierCode: new FormControl({value: '', disabled: true}, [Validators.required]),
    VATnumber: new FormControl('', [Validators.required]),
    fiscalCode: new FormControl('', []),
    phone: new FormControl('', []),
    email: new FormControl('', [Validators.required, Validators.email]),
    pec: new FormControl('', [Validators.email]),
    regAlboCode: new FormControl('', []),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly techniciansService: CarriersService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
  ) {
    super(dialog);
    this.addCarrierForm = formBuilder.group({
      name: this.settingsControl.name,
      sdiCode: this.settingsControl.sdiCode,
      carrierCode: this.settingsControl.carrierCode,
      VATnumber: this.settingsControl.VATnumber,
      phone: this.settingsControl.fiscalCode,
      fiscalCode: this.settingsControl.phone,
      email : this.settingsControl.email,
      pec: this.settingsControl.pec,
      regAlboCode: this.settingsControl.regAlboCode,
    });
  }

  ngOnInit(): void {
    if (this.selectedCarrier) {
      this.addCarrierForm.patchValue(this.selectedCarrier);
    } /* else {
      // FIXME: remove
      this.addCarrierForm.patchValue({
        name: 'test',
      });
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedCarrier' in changes) {
      this.selectedCarrier = changes.selectedCarrier.currentValue;
      this.addCarrierForm.patchValue(this.selectedCarrier);
    }
  }

  submit() {
    const payload = this.addCarrierForm.value;
    let mutation: Observable<any>;
    let message = 'Vettore inserito con successo';
    if (this.selectedCarrier) { // update
      payload.carrierId = this.selectedCarrier.id; // manually add the clientCode
      mutation = this.techniciansService.updateCarrier(payload);
      message = 'Vettore aggiornato con successo';
    } else {
      mutation = this.techniciansService.addNewCarrier(payload);
      message = 'Vettore inserito con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedCarrier) {
          this.addCarrierForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedCarrier) { // form for adding a client
      return true;
    } else {
      const formValue = this.addCarrierForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedCarrier[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    this.addCarrierForm.patchValue(this.selectedCarrier);
  }

}
