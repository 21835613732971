import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  AccessoriesGQL,
  CreateAccessoryGQL,
  UpdateAccessoryGQL,
  CreateAccessoryInput,
  UpdateAccessoryInput,
  AccessoriesDocument
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class AccessoriesService {

  constructor(
    private readonly accessoriesService: AccessoriesGQL,
    private readonly createAccessoryService: CreateAccessoryGQL,
    private readonly updateAccessoryService: UpdateAccessoryGQL,
  ) { }

  /*queries*/
  getAccessories() {
    return this.accessoriesService.watch().valueChanges.pipe(map(val => {
      return val.data.accessories.edges.map(el => el.node);
    }));
  }

  /*mutations*/
  addNewAccessory(payload: CreateAccessoryInput) {
    return this.createAccessoryService.mutate({ data: payload }, {refetchQueries: [{query: AccessoriesDocument}]});
    // return this.createFilterService.mutate({ data: payload });
  }

  updateAccessory(payload: UpdateAccessoryInput) {
    return this.updateAccessoryService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateAccessory;
    }));
  }

}
