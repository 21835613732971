<div class="main-container h-100 w-100">
    <div class="select-invoice-div">
        <app-select-invoice></app-select-invoice>
    </div>

    <div class="row no-margin invoices-container">
        <div class="col-3">
            <button class="w-100 add-invoice-button" mat-button (click)="createNewInvoice = true; selectedInvoice = null">
                <mat-icon class="new-invoice-icon" svgIcon="plus"></mat-icon>
                INSERISCI FATTURA
            </button>
        </div>
        <div class="col-9">
        </div>
        <div class="col-3 h-100 invoice-card-div">
            <mat-card class="invoice-card pointer" *ngFor="let invoice of filteredInvoices" [ngClass]="{selected: selectedInvoice && selectedInvoice.id === invoice.id}" (click)="selectInvoice(invoice)">
                Fattura {{ invoice.number }}
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="createNewInvoice">
            <mat-card class="card">
                <app-new-invoice></app-new-invoice>
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="!createNewInvoice">
            <app-invoice-page [(selectedInvoice)]="selectedInvoice"></app-invoice-page>
        </div>
    </div>
</div>