<div class="form-container">
    <div class="title text-center" *ngIf="!selectedGenerator">INSERISCI GRUPPO ELETTROGENO</div>
    <div class="title text-center" *ngIf="selectedGenerator">MODIFICA GRUPPO ELETTROGENO</div>
    <form name="addGeneratorForm" (ngSubmit)="selectedGenerator ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input" *ngIf="selectedGenerator">
            <mat-label>Codice generatore*</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="generatorCode"
                [formControl]="settingsControl.generatorCode">
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input generator-status">
            <mat-label>Stato*</mat-label>
            <mat-select [formGroup]="addGeneratorForm" name="status" [formControl]="settingsControl.status">
                <mat-option *ngFor="let status of generatorStatuses" [value]="status.value">
                    {{ status.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input" *ngIf="showClients">
            <mat-label>Cliente</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addGeneratorForm"
                name="clientId" [formControl]="settingsControl.clientId" [matAutocomplete]="auto"
                (keyup)="autocompleteClients($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionsScroll)="moreClients()">
                <mat-option *ngFor="let client of filteredClients" [value]="client">
                    {{ client.name }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input generator-status" *ngIf="showClients && showContacts">
            <mat-label>Responsabili</mat-label>
            <mat-select [formGroup]="addGeneratorForm" name="status"
                [formControl]="settingsControl.contactIds" multiple>
                <mat-option *ngFor="let contact of contacts" [value]="contact.id">
                    {{ contact.firstName }} {{ contact.lastName }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <br>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Marca*</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="brand"
                [formControl]="settingsControl.brand">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Matricola*</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="serialNumber"
                [formControl]="settingsControl.serialNumber">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Potenza kVA*</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="kVA"
                [formControl]="settingsControl.kVA">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Potenza kW*</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="kW" [formControl]="settingsControl.kW">
        </mat-form-field>
        <br>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Marca motore</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="engineBrand"
                [formControl]="settingsControl.engineBrand">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Matricola motore</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="engineSerialNumber"
                [formControl]="settingsControl.engineSerialNumber">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Marca alternatore</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="alternatorBrand"
                [formControl]="settingsControl.alternatorBrand">
        </mat-form-field>


        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Matricola alternatore</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="alternatorSerialNumber"
                [formControl]="settingsControl.alternatorSerialNumber">
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Anno di produzione</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="productionYear"
                [formControl]="settingsControl.productionYear">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Numero fasi</mat-label>
            <mat-select [formGroup]="addGeneratorForm" name="status" [formControl]="settingsControl.electricityType">
                <mat-option *ngFor="let type of electricityTypes" [value]="type.value">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Carburante</mat-label>
            <mat-select [formGroup]="addGeneratorForm" name="status" [formControl]="settingsControl.fuelType">
                <mat-option *ngFor="let type of fuelTypes" [value]="type.value">
                    {{ type.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Tipo</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="type"
                [formControl]="settingsControl.type">
        </mat-form-field>

        <div *ngIf="settingsControl.status.value !== MAINTENANCE_STATUS">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Prezzo di acquisto</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="purchasePrice"
                    [formControl]="settingsControl.purchasePrice">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Stato di acquisto</mat-label>
                <mat-select [formGroup]="addGeneratorForm" name="purchaseStatus"
                    [formControl]="settingsControl.purchaseStatus">
                    <mat-option *ngFor="let status of purchaseStatuses" [value]="status.value">
                        {{status.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Anno di acquisto</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="purchaseYear"
                    [formControl]="settingsControl.purchaseYear">
            </mat-form-field>
        </div>
        <br>

        <div>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Indirizzo</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="address"
                    [formControl]="settingsControl.address">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>CAP</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="postCode"
                    [formControl]="settingsControl.postCode">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Città</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="city"
                    [formControl]="settingsControl.city">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Provincia</mat-label>
                <input matInput placeholder="" [formGroup]="addGeneratorForm" name="province"
                    [formControl]="settingsControl.province">
            </mat-form-field>

            <br>

            <!--<mat-form-field appearance="outline" class="form-input">
            <mat-label>Regione/Stato</mat-label>
            <input matInput placeholder="" [formGroup]="addGeneratorForm" name="region"
                [formControl]="settingsControl.region">
        </mat-form-field>-->
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Paese</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addGeneratorForm"
                    name="country" [formControl]="settingsControl.country" [matAutocomplete]="auto">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                        {{ country }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>

        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
            [ngClass]="{'save-button': !this.selectedGenerator}"
            [disabled]="!addGeneratorForm.valid || !showSubmitButton() || !getTasksArray().valid">Salva</button>
        <button *ngIf="selectedGenerator" mat-raised-button color="error" type="button" class="float-right"
            (click)="resetForm()">Annulla</button>
    </form>
    <mat-card class="add-tasks-container" *ngIf="!this.selectedGenerator && showMaintenances">
        <div class="title text-center">Aggiungi Manutenzioni predefinite</div>
        <form class="mb-5" [formGroup]="maintenanceMonthsForm" name="maintenanceForm" (ngSubmit)=submit() #f="ngForm"
            novalidate>
            <div class="months-div">
                <div class="month-div" *ngFor="let month of months; let i = index" formArrayName="maintenanceMonths">
                    <mat-checkbox class="month" [formControlName]="i" [value]="month">{{ month }}</mat-checkbox>
                </div>
            </div>
        </form>
        <app-add-tasks *ngIf="showTasks" [tasksForms]="tasksForms"></app-add-tasks>
    </mat-card>
</div>