<div class="form-container">
    <div *ngIf="!selectedTechnician" class="title text-center">INSERISCI TECNICO</div>
    <div *ngIf="selectedTechnician" class="title text-center">MODIFICA TECNICO</div>
    <form name="addTechnicianForm" (ngSubmit)="selectedTechnician ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Nome*</mat-label>
            <input matInput placeholder="" [formGroup]="addTechnicianForm" name="firstName"
                [formControl]="settingsControl.firstName">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Cognome*</mat-label>
            <input matInput placeholder="" [formGroup]="addTechnicianForm" name="lastName"
                [formControl]="settingsControl.lastName">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="" [formGroup]="addTechnicianForm" name="phone"
                [formControl]="settingsControl.phone">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Email*</mat-label>
            <input matInput placeholder="" [formGroup]="addTechnicianForm" name="phone"
                [formControl]="settingsControl.email">
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
            [disabled]="!addTechnicianForm.valid || !showSubmitButton()">Salva</button>
        <button *ngIf="selectedTechnician" mat-raised-button color="error" type="button" class="float-right"
            (click)="resetForm()">Annulla</button>
    </form>
</div>