import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectFilterForm: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  settingsControl = {
    code: new FormControl(''),
    category: new FormControl(''),
    brand: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectFilterForm = formBuilder.group({
      code: this.settingsControl.code,
      category: this.settingsControl.category,
      brand: this.settingsControl.brand,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterFiltersEvent.next(content);
    });
  }

  filter(ev, from) {
    let value = ev.target ? ev.target.value : ev.value;
    this.filterSubject.next({value, from});
  }
}
