import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { EventBusService } from '@services/event-bus.service';
import { Client, ClientContact, Generator, Technician } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { TechniciansService } from '@services/technicians/technicians.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CloneMaintenancesModalComponent } from './clone-maintenances-modal/clone-maintenances-modal.component';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-select-maintenance',
  templateUrl: './select-maintenance.component.html',
  styleUrls: ['./select-maintenance.component.scss']
})
export class SelectMaintenanceComponent implements OnInit, OnDestroy {


  private filterSubject: Subject<any> = new Subject();
  selectMaintenanceForm: FormGroup;
  settingsControl = {
    clientId: new FormControl(''),
    generatorId: new FormControl(''),
    technicianId: new FormControl(''),
    contactId: new FormControl(''),
  };
  // clients: Client[] = [];
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[] = [];
  // generators: Generator[] = [];
  // filteredGenerators: BehaviorSubject<Generator[]> = new BehaviorSubject([]);
  filteredGenerators: Generator[] = [];
  filterGenerators = {};
  filterClients = {};
  technicians: Technician[] = [];
  filteredTechnicians: Observable<Technician[]>;
  contacts: ClientContact[] = [];
  // filteredContacts: BehaviorSubject<ClientContact[]> = new BehaviorSubject([]);;
  filteredContacts: ClientContact[] = [];
  showInfoSnackbar = false;

  private autocompleteClientsSubject: Subject<string> = new Subject();
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteTechniciansSubject: Subject<string> = new Subject();
  private autocompleteContactsSubject: Subject<string> = new Subject();

  constructor(
    private dialogService: MatDialog,
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
    private readonly clientsService: ClientsService,
    private readonly generatorsService: GeneratorsService,
    private readonly techniciansService: TechniciansService,
    private readonly maintenancesService: MaintenancesService,
    private snackBarService: MatSnackBar,
  ) {
    this.selectMaintenanceForm = formBuilder.group({
      clientId: this.settingsControl.clientId,
      generatorId: this.settingsControl.generatorId,
      technicianId: this.settingsControl.technicianId,
      contactId: this.settingsControl.contactId,
    });

    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.filteredClients = clients;
      this.contacts = [];
      this.filteredClients.forEach((client) => {
        this.contacts = [...new Set([...this.contacts, ...client.contacts])]
      });
    });
    this.generatorsService.getGeneratorsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
      // this.filteredGenerators.next(generators);
      this.filteredGenerators = generators;
    });
    this.techniciansService.getTechnicians().subscribe((technicians: Technician[]) => {
      this.technicians = technicians;
      this.filteredTechnicians = of(this.technicians);
    });

    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filterGenerators = {};
      } else {
        this.filterGenerators = { serialNumber: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.generatorsService.getGeneratorsList({ serialNumber: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredGenerators = generators;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });


    this.autocompleteTechniciansSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filteredTechnicians = of(this.technicians);
        return;
      }
      this.filteredTechnicians = of(this._filterTechnicians(value));
    });

    this.autocompleteContactsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filteredContacts = this.contacts;
        return;
      }
      this.filteredContacts = this._filterContacts(value);
    });

    this.selectMaintenanceForm.get('clientId').valueChanges.subscribe((client: Client) => {
      if (!client) {
        this.filteredContacts = this.contacts;
      } else {
        this.filteredContacts = client.contacts;
      }
    });

    this.selectMaintenanceForm.valueChanges.subscribe((val) => {
      const filter = { clientId: val.clientId.id, generatorId: val.generatorId.id, technicianId: val.technicianId.id, contactId: val.contactId.id };
      this.eventBusService.filterMaintenancesEvent.next(filter);
      if (!val.clientId && !val.generatorId && !val.technicianId && !val.contactId) {
        this.snackBarService.dismiss();
        this.showInfoSnackbar = false;
      } else if (!this.showInfoSnackbar) {
        this.showInfoSnackbar = true;
        this.snackBarService.open('Filtri attivi', '', {
          duration: undefined,
          verticalPosition: 'top',
          panelClass: ['maintenance-info-filters']
        });
      }
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe(content => {
      this.eventBusService.filterMaintenancesEvent.next(content);
    });
  }

  ngOnDestroy() {
    if (this.showInfoSnackbar) {
      this.snackBarService.dismiss();
    }
  }

  filter(ev, from) {
    this.filterSubject.next({ value: ev.target.value, from });
  }

  moreGenerators() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredGenerators.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredGenerators[this.filteredGenerators.length - 1].id;
      this.generatorsService.moreGenerators(this.filterGenerators, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  private _filterTechnicians(value: string): Technician[] {
    const filterValue = value.toLowerCase();
    return this.technicians.filter(el => el.lastName.toLowerCase().includes(filterValue));
  }

  private _filterContacts(value: string): ClientContact[] {
    const filterValue = value.toLowerCase();
    return this.contacts.filter(el => el.lastName.toLowerCase().includes(filterValue));
  }

  autocompleteGenerators(ev) {
    this.autocompleteGeneratorsSubject.next(ev.target.value);
  }

  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  autocompleteTechnicians(ev) {
    this.autocompleteTechniciansSubject.next(ev.target.value);
  }

  autocompleteContacts(ev) {
    this.autocompleteContactsSubject.next(ev.target.value);
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }

  displayFnGenerators(generator: Generator): string {
    return generator && generator.serialNumber ? generator.serialNumber : '';
  }

  displayFnTechnicians(technician: Technician): string {
    return technician && technician.firstName ? technician.firstName + ' ' + technician.lastName : '';
  }

  displayFnContacts(contact: ClientContact): string {
    return contact && contact.firstName ? contact.firstName + ' ' + contact.lastName : '';
  }

  async openModalCloneMaintenances() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'clone-maintenances-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    dialogConfig.data = {};
    const dialogRef = this.dialogService.open(CloneMaintenancesModalComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .subscribe(async (res) => {
        if (res) {
          await this.maintenancesService.cloneMaintenances({ from: res.fromDate, to: res.toDate, translateBy: res.translateBy }).toPromise();
        }
      });
  }
}
