import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {
  @Input() pdfUrl: string = null;
  currentPdf: Blob;
  constructor(
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.getPDF(this.pdfUrl).subscribe(val => {
      this.currentPdf = val;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('pdfUrl' in changes && changes.pdfUrl.currentValue) {
      this.getPDF(this.pdfUrl).subscribe(val => {
        this.currentPdf = val;
      });
    }
  }

  getPDF(url) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(url, { responseType: 'blob' }
    );
  }

}
