import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { WorkReport, Client, Generator, Technician, CreateWorkReportInput, UpdateWorkReportInput, Ddt, Maintenance } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { EventBusService } from '@services/event-bus.service';
import { WorkReportsService } from '@services/workReports/workReports.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { TechniciansService } from '@services/technicians/technicians.service';
import { Constants } from '@shared/constants';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-new-work-report',
  templateUrl: './new-workReport.component.html',
  styleUrls: ['./new-workReport.component.scss']
})
export class NewWorkReportComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedWorkReport: WorkReport = null;
  addWorkReportForm: FormGroup;
  // generators: Generator[] = [];
  maintenances: Maintenance[] = [];
  // clients: Client[] = [];
  technicians: Technician[] = [];
  filteredGenerators: Observable<Generator[]>;
  filteredMaintenances: Observable<Maintenance[]>;
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[];
  operationTypes = Constants.OPERATION_TYPES;
  originalTasks = Constants.MAINTENANCES_TASKS;
  filterClients = {};
  tasks: any[] = Constants.MAINTENANCES_TASKS;
  selectedMaintenanceId: string = null;
  settingsControl = {
    clientId: new FormControl('', [Validators.required]),
    technicianIds: new FormControl(null, [Validators.required]),
    maintenanceId: new FormControl('', [Validators.required]),
    tankFuelLevel: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    cisternFuelLevel: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    lunchBreak: new FormControl(false, [Validators.required]),
    operationType: new FormControl('', [Validators.required]),
    positiveOperation: new FormControl(false, [Validators.required]),
    registerCompiled: new FormControl(false, [Validators.required]),
    registerNotCompiledReason: new FormControl('', []),
    totalKm: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    generatorHours: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    workHours: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    note: new FormControl(null, []),
    tasks: new FormControl(null, []),
  };
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteMaintenancesSubject: Subject<string> = new Subject();
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly maintenancesService: MaintenancesService,
    private readonly techniciansService: TechniciansService,
    private readonly workReportsService: WorkReportsService,
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    @Optional() public dialogRef?: MatDialogRef<NewWorkReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.route.queryParams
      .subscribe(params => {
        if (params.maintenanceId) {
          this.selectedMaintenanceId = params.maintenanceId;
        }
      }
      );

    this.addWorkReportForm = formBuilder.group({
      clientId: this.settingsControl.clientId,
      totalKm: this.settingsControl.totalKm,
      note: this.settingsControl.note,
      technicianIds: this.settingsControl.technicianIds,
      maintenanceId: this.settingsControl.maintenanceId,
      cisternFuelLevel: this.settingsControl.cisternFuelLevel,
      tankFuelLevel: this.settingsControl.tankFuelLevel,
      lunchBreak: this.settingsControl.lunchBreak,
      operationType: this.settingsControl.operationType,
      positiveOperation: this.settingsControl.positiveOperation,
      registerCompiled: this.settingsControl.registerCompiled,
      registerNotCompiledReason: this.settingsControl.registerNotCompiledReason,
      generatorHours: this.settingsControl.generatorHours,
      workHours: this.settingsControl.workHours,
      tasks: this.settingsControl.tasks,
    });
    if (dialogData && dialogData.selectedWorkReport) {
      this.selectedWorkReport = dialogData.selectedWorkReport;
    }

    if (dialogData && dialogData.selectedMaintenanceId) {
      this.selectedMaintenanceId = dialogData.selectedMaintenanceId;
    }

    // this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //   this.generators = generators;
    //   this.filteredGenerators = of(this.generators);
    // });

    // this.clientsService.getClients().subscribe((clients: Client[]) => {
    //   this.clients = clients;
    //   this.filteredClients = of(this.clients);
    // });

    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.filteredClients = clients;
    });
    this.techniciansService.getTechnicians().subscribe((technicians: Technician[]) => {
      this.technicians = technicians;
    });

    // this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredGenerators = of(this.generators);
    //     return;
    //   }
    //   this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //     this.filteredGenerators = of(this._filterGenerators(value));
    //   });
    // });
    //   this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
    //     if (!value) {
    //       this.filteredClients = of(this.clients);
    //       return;
    //     }
    //     this.clientsService.getClients().subscribe((clients: Client[]) => {
    //       this.filteredClients = of(this._filterClients(value));
    //     });
    //   });
    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
  }


  ngOnInit(): void {

    // this.maintenancesService.getMaintenancesList().subscribe(async (maintenances: Maintenance[]) => {
    // this.maintenances = maintenances;
    // this.filteredMaintenances = of(this.maintenances);
    this.subscriptions.push(
      this.addWorkReportForm.get('clientId').valueChanges.subscribe(val => {
        this.getMaintenances();
      }),
      this.addWorkReportForm.get('maintenanceId').valueChanges.subscribe((val: Maintenance) => {
        if (!val) {
          return;
        }
        this.maintenancesService.getMaintenanceById(val.id).subscribe((maintenance: Maintenance) => {
          this.tasks = this.originalTasks.slice();
          // this.addWorkReportForm.get('tasks').reset();
          if (maintenance) {
            this.fillTasks(maintenance.tasks || []);
            if (maintenance.technicians && maintenance.technicians.length > 0) {
              this.addWorkReportForm.get('technicianIds').setValue(maintenance.technicians.map(el => el.id));
            }
          }
        })
      })
    )
    // if (this.selectedWorkReport) {
    //   // this.fillWorkReport();
    // }
    if (this.selectedMaintenanceId) {
      // const tmpMaintenance = this.maintenances.filter(man => man.id === this.selectedMaintenanceId)[0];

      this.maintenancesService.getMaintenanceById(this.selectedMaintenanceId).subscribe((tmpMaintenance) => {
        this.preCompileWorkReport(tmpMaintenance as Maintenance);
      });
    }
    // });

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedWorkReport' in changes) {
      this.selectedWorkReport = changes.selectedWorkReport.currentValue;
      this.fillWorkReport();
    }

  }


  moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }



  // private _filterGenerators(value: string): Generator[] {
  //   if (value === '') {
  //     this.filteredGenerators = of(this.generators);
  //     return;
  //   }
  //   const filterValue = value.toLowerCase();
  //   return this.generators.filter(el => el.serialNumber.toLowerCase().includes(filterValue));
  // }

  // private _filterClients(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  autocompleteGenerators(ev) {
    this.autocompleteGeneratorsSubject.next(ev.target.value);
  }

  autocompleteMaintenances(ev) {
    this.autocompleteMaintenancesSubject.next(ev.target.value);
  }
  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  displayFnGenerators(generator: Generator): string {
    return generator && generator.serialNumber ? generator.serialNumber : '';
  }

  displayFnMaintenances(maintenance): string {
    if (!maintenance) {
      return '';
    }
    let date: any = new Date(maintenance.startDate);
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let day: any = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }
    date = day + '/' + month + '/' + year;
    return maintenance && maintenance.generator ? (maintenance.generator.generatorCode + ' - ' + date) : '';
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }


  submit() {
    const payload = this.addWorkReportForm.value;
    payload.clientId = payload.clientId.id;
    if (payload.maintenanceId) {
      payload.maintenanceId = payload.maintenanceId.id;
    }
    if (this.selectedMaintenanceId) {
      payload.maintenanceId = this.selectedMaintenanceId;
    }

    if (payload.totalKm) {
      payload.totalKm = parseFloat(payload.totalKm);
    }
    if (payload.generatorHours) {
      payload.generatorHours = parseFloat(payload.generatorHours);
    }
    if (payload.workHours) {
      payload.workHours = parseFloat(payload.workHours);
    }
    if (payload.cisternFuelLevel) {
      payload.cisternFuelLevel = parseFloat(payload.cisternFuelLevel);
    }
    if (payload.tankFuelLevel) {
      payload.tankFuelLevel = parseFloat(payload.tankFuelLevel);
    }
    if (payload.maintenanceId === null) {
      delete payload.maintenanceId;
    }
    console.log(payload.tasks)
    if (payload.tasks && payload.tasks.length > 0) {
      payload.tasks = payload.tasks.map(key => this.tasks.filter(el => el.value === key)[0]);
    }
    console.log(payload.tasks)
    let mutation: Observable<any> = this.workReportsService.addNewWorkReport(payload as CreateWorkReportInput);
    let message = 'Buono inserito con successo';

    if (this.selectedWorkReport) {
      payload.workReportId = this.selectedWorkReport.id;
      mutation = this.workReportsService.updateWorkReport(payload as UpdateWorkReportInput);
      message = 'Buono aggiornato con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedWorkReport) {
          this.addWorkReportForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  preCompileWorkReport(maintenance: Maintenance) {
    this.addWorkReportForm.get('clientId').setValue(maintenance.generator.client);
    this.addWorkReportForm.get('maintenanceId').setValue(maintenance);
  }

  fillTasks(tasks: any[]) {
    let values = [];
    if (tasks.length === 0) {
      return;
    }
    tasks.forEach((task) => {
      const alreadyInIndex = this.tasks.findIndex(t => t.value === task.operation);
      if (task.operation) { // coming from maintenances
        if (alreadyInIndex === -1 || (alreadyInIndex > -1 && task.description)) {
          const name = Constants.TASK_OPERATIONS.filter(el => el.value === task.operation)[0].name;
          values.push(task.operation);
          if (alreadyInIndex > -1) {
            this.tasks.splice(alreadyInIndex, 1);
          }
          this.tasks = [
            {
              ...task,
              name,
              value: task.operation,
            },
            ...this.tasks,
          ];
        } else { // alreadyIn, check it
          values.push(task.operation);
        }
      } else {
        values = tasks.map(el => el.value);
        // this.tasks = [...this.tasks, tasks];
      }
    });
    this.addWorkReportForm.get('tasks').setValue(values);
  }

  fillWorkReport() {
    this.addWorkReportForm.patchValue({ // once here it is sure that all the maintenances have been added to the select. Now let's filter them
      ...this.selectedWorkReport,
      clientId: this.selectedWorkReport.client,
      maintenanceId: this.selectedWorkReport.maintenance,
      technicianIds: this.selectedWorkReport.technicians.map(el => el.id),
      // technicianIds: ["ckjsqukbf2452k3mkpnvwf2qe", "ckp46cglh798621hrrvl4anrjhm"],
    });
    this.fillTasks(this.selectedWorkReport.tasks || [])
    if (this.selectedWorkReport.maintenance) {
      this.addWorkReportForm.get('maintenanceId').setValue(this.selectedWorkReport.maintenance);
    }
    // console.log(this.selectedWorkReport.maintenance, this.maintenances)
  }

  formatDate(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }
    return day + '/' + month + '/' + year;
  }

  async getMaintenances() {
    this.addWorkReportForm.get('maintenanceId').reset();
    let clientId: any = this.addWorkReportForm.get('clientId').value;
    if (clientId) {
      clientId = clientId.id;
    }
    /*let generatorId = null;
    if (this.selectedWorkReport && this.selectedWorkReport.maintenance && this.selectedWorkReport.maintenance.generator) {
      generatorId = this.selectedWorkReport.maintenance.generator.id;
    }*/
    this.maintenancesService.getMaintenancesList({ clientId }, null, null).subscribe((maintenances: Maintenance[]) => {
      this.maintenances = maintenances;
      this.filteredMaintenances = of(this.maintenances);
    });
  }

}
