import { Component, OnInit, SimpleChanges } from '@angular/core';

import { RentalsService } from '@services/rentals/rentals.service';
import { Rental } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { environment } from '@env/environment';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-rentals',
  templateUrl: './rentals.component.html',
  styleUrls: ['./rentals.component.scss']
})
export class RentalsComponent extends BaseComponent implements OnInit {
  // rentals: Rental[] = [];
  filteredRentals: Rental[] = [];
  selectedRental: Rental = null;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly rentalsService: RentalsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.rentalsService.getRentals({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((rentals: Rental[]) => {
        // this.rentals = rentals;
        this.filteredRentals = rentals;
        // this.filterRentals(this.currentFilter);
        // if (this.selectedRental) {
        //   const id = this.selectedRental.id;
        //   this.selectedRental = this.filteredRentals.filter((el) => el.id === id)[0];
        // }
        this.eventBusService.showSpinnerEvent.next(false);
      }),
      this.eventBusService.filterRentalsEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterRentals()
      })
    );

  }

  ngOnInit(): void {
  }

  selectRental(el) {
    this.selectedRental = el;
  }

  filterRentals() {
   this.eventBusService.showSpinnerEvent.next(true);
    this.selectedRental = null;
    this.rentalsService.getRentals(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((rentals: Rental[]) => {
      this.filteredRentals = rentals;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredRentals.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredRentals[this.filteredRentals.length - 1].id;
      this.rentalsService.moreRentals(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // TODO: remove
  // filterRentals(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredRentals = this.rentals;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredRentals = this.rentals.filter((el) => {
  //     if (filter.from === 'status') {
  //       return el.status === filter.value;
  //     }
  //     return true;
  //   });
  // }

}
