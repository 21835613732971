<div class="form-container">
    <div *ngIf="!selectedFilter" class="title text-center">INSERISCI FILTRO</div>
    <div *ngIf="selectedFilter" class="title text-center">MODIFICA FILTRO</div>
    <form name="addFilterForm" (ngSubmit)="selectedFilter ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input filter-category">
            <mat-label>Categoria</mat-label>
            <mat-select name="food" [formGroup]="addFilterForm" name="category"
                [formControl]="settingsControl.category">
                <mat-option *ngFor="let cat of filterCategories" [value]="cat.value">
                    {{ cat.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input filter-brand">
            <mat-label>Marca</mat-label>
            <mat-select name="food" [formGroup]="addFilterForm" name="brand" [formControl]="settingsControl.brand">
                <mat-option *ngFor="let brand of filterBrands" [value]="brand.value">
                    {{ brand.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice*</mat-label>
            <input matInput placeholder="" [formGroup]="addFilterForm" name="brand"
                [formControl]="settingsControl.code">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Prezzo</mat-label>
            <input matInput placeholder="" [formGroup]="addFilterForm" name="brand"
                [formControl]="settingsControl.price">
        </mat-form-field>

        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
            [disabled]="!addFilterForm.valid || !showSubmitButton()">Salva</button>
        <button *ngIf="selectedFilter" mat-raised-button color="error" type="button" class="float-right"
            (click)="resetForm()">Annulla</button>
    </form>
</div>