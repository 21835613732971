import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from '@app/graphql.module';
import { AppMaterialModule } from '@app/app-material.module';
import { MatIconRegistry } from '@angular/material/icon';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { SharedModule } from '@shared/shared.module';
import { AddComponent } from './add.component';
import { NewClientComponent } from './new-client/new-client.component';
import { NewClientContactComponent } from './new-client-contact/new-client-contact.component';
import { NewGeneratorComponent } from './new-generator/new-generator.component';
import { NewMaintenanceComponent } from './new-maintenance/new-maintenance.component';
import { AddTasksComponent } from './add-tasks/add-tasks.component';
import { NewRentalComponent } from './new-rental/new-rental.component';
import { NewDDTComponent } from './new-ddt/new-ddt.component';
import { NewContractComponent } from './new-contract/new-contract.component';
import { AddGoodsComponent } from './new-ddt/add-goods/add-goods.component';
import { NewWorkReportComponent } from './new-workReport/new-workReport.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    AppMaterialModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
  ],
  declarations: [
   AddComponent,
   NewClientComponent,
   NewClientContactComponent,
   NewGeneratorComponent,
   NewMaintenanceComponent,
   AddTasksComponent,
   NewRentalComponent,
   NewDDTComponent,
   NewContractComponent,
   AddGoodsComponent,
   NewWorkReportComponent,
  ],
  exports: [
   NewClientComponent,
   NewClientContactComponent,
   NewGeneratorComponent,
   NewMaintenanceComponent,
   AddTasksComponent,
   NewRentalComponent,
   NewDDTComponent,
   NewContractComponent,
   NewWorkReportComponent,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'it-IT'},
  ],
})
export class AddModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
