import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Filter } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { FiltersService } from '@services/filters/filters.service';
import { Observable } from 'rxjs';

import { Constants } from '@shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-filter',
  templateUrl: './new-filter.component.html',
  styleUrls: ['./new-filter.component.scss']
})
export class NewFilterComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedFilter: Filter = null;
  addFilterForm: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  settingsControl = {
    category: new FormControl('', [Validators.required]),
    brand: new FormControl('', [Validators.required]),
    code: new FormControl('', [Validators.required]),
    price: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly filtersService: FiltersService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
  ) {
    super(dialog);
    this.addFilterForm = formBuilder.group({
      category: this.settingsControl.category,
      brand: this.settingsControl.brand,
      code: this.settingsControl.code,
      price: this.settingsControl.price,
    });
  }

  ngOnInit(): void {
    if (this.selectedFilter) {
      /*if (this.selectedFilter.productionYear) {
        this.selectedFilter.productionYear = new Date(this.selectedFilter.productionYear).getFullYear();
      }*/
      /*if (this.selectedFilter.purchaseYear) {
        this.selectedFilter.purchaseYear = new Date(this.selectedFilter.purchaseYear).getFullYear();
      }*/
      this.addFilterForm.patchValue(this.selectedFilter);
    }/* else {
      // FIXME: remove
      this.addFilterForm.patchValue({
        category: 'OIL',
        brand: 'TECNOCAR',
        code: 'NHGFP9901',
      });
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedFilter' in changes) {
      this.selectedFilter = changes.selectedFilter.currentValue;
      this.addFilterForm.patchValue(this.selectedFilter);
    }
  }

  submit() {
    const payload = this.addFilterForm.value;
    if (payload.price) {
      payload.price = parseFloat(payload.price);
    }
    let mutation: Observable<any>;
    let message = 'Filtro inserito con successo';
    if (this.selectedFilter) { // update
      payload.filterId = this.selectedFilter.id; // manually add the clientCode
      mutation = this.filtersService.updateFilter(payload);
      message = 'Filtro aggiornato con successo';
    } else {
      mutation = this.filtersService.addNewFilter(payload);
      message = 'Filtro inserito con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedFilter) {
          this.addFilterForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedFilter) { // form for adding a client
      return true;
    } else {
      const formValue = this.addFilterForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedFilter[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    this.addFilterForm.patchValue(this.selectedFilter);
  }

}
