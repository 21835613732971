import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-select-ddt',
  templateUrl: './select-ddt.component.html',
  styleUrls: ['./select-ddt.component.scss']
})
export class SelectDDTComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectDDTForm: FormGroup;
  ddtStatuses = Constants.RENTAL_STATUS;
  settingsControl = {
    carrierName: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectDDTForm = formBuilder.group({
      status: this.settingsControl.carrierName,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterDDTsEvent.next(content);
    });
  }

  filter(ev, from) {
    this.filterSubject.next({value: ev.target.value, from});
  }
}
