import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { EventBusService } from '@services/event-bus.service';
import { FilterGeneratorLinksService } from '@services/generators/filter-generator-links.service';
import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-add-tasks',
  templateUrl: './add-tasks.component.html',
  styleUrls: ['./add-tasks.component.scss']
})
export class AddTasksComponent extends BaseComponent implements OnInit {
  @Input() tasksForms: FormGroup = null;
  taskOperations = Constants.TASK_OPERATIONS;
 
  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
    private readonly maintenancesService: MaintenancesService,
    private readonly filterGeneratorLinksService: FilterGeneratorLinksService,
  ) {
    super();
  }

  ngOnInit(): void {
    if (!this.tasksForms) {
      this.tasksForms = this.formBuilder.group({
        tasksArray: this.formBuilder.array([])
      });
    }
    
  }

  ngOnChanges(changes: SimpleChanges) {

    /*if ('selectedGenerator' in changes && changes.selectedGenerator.previousValue) {
      this.tasksForms = [];
      this.currentFiltersPerCategory = [];
      this.selectedGenerator = changes.selectedGenerator.currentValue;
      for (const cat of this.filterCategories) {
        this.tasksForms.push(
          this.formBuilder.group({
            tasksArray: this.formBuilder.array([])
          })
        );
        this.currentFiltersPerCategory.push([]);
      }
      this.fillFilters();
    }*/
  }

  getTasksArray() {
    return this.tasksForms.get('tasksArray') as FormArray;
  }

  addItem() {
    this.getTasksArray().push(
      this.formBuilder.group({
        operation: ['', [Validators.required]],
        description: ['', []],
        completed: [false, []],
      })
    );
  }

  async removeItem(index: number) {
    this.getTasksArray().removeAt(index);
  }

  async submit(maintenances) {

    const tasks = this.getTasksArray().value;
    try {
      let payload = {};
      for (const man of maintenances) {
        payload = {};
        // payload.maintenanceId
        // await this.maintenancesService.updateMaintenance()
      }
    } catch (err) {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    /*
    for (const el of formValue) {
      el.generatorId = this.selectedGenerator.id;
      el.quantity = parseFloat(el.quantity);
      let message = '';
      try {
        if (this.alreadySaved(el.filterId)) {
          el.filterId = el.filterId;
          await this.filterGeneratorLinksService.updateFilterGeneratorLink(el).toPromise();
          message = 'Filtro aggiornato con successo';
          this.makeFiltersList(index);
        } else {
          await this.filterGeneratorLinksService.addNewFilterGeneratorLink(el).toPromise();
          message = 'Filtro aggiunto con successo';
        }
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
      } catch (err) {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    }
    */
  }

}
