<div class="form-container">
    <div *ngIf="!selectedInvoice" class="title text-center">INSERISCI FATTURA</div>
    <div *ngIf="selectedInvoice" class="title text-center">MODIFICA FATTURA</div>
    <form name="addInvoiceForm" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Numero*</mat-label>
            <input matInput placeholder="" [formGroup]="addInvoiceForm" name="number"
                [formControl]="settingsControl.number">
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Totale</mat-label>
            <input matInput placeholder="" [formGroup]="addInvoiceForm" name="amount"
                [formControl]="settingsControl.amount">
        </mat-form-field>
        <br>
    </form>
    <app-add-rentals [rentalsForms]="rentalsForms" [value]="selectedRentals"></app-add-rentals>
    <br>
    <app-add-work-reports class="mt-3" [workReportsForms]="workReportsForms" [value]="selectedWorkReports">
    </app-add-work-reports>
    <button mat-raised-button color="primary" type="button" class="float-right ml-3 buttons"
    [disabled]="!addInvoiceForm.valid || !rentalsForms.valid || !workReportsForms.valid"
    (click)="selectedInvoice ? askConfirmation() : submit()">Salva</button>
    <button *ngIf="selectedInvoice && dialogRef" mat-raised-button color="error" type="button"
        class="float-right buttons cancel-button" (click)="closeDialog()">Annulla</button>
</div>