<div class="main-container h-100 w-100">
    <div class="no-ddt" *ngIf="!selectedDDT">SELEZIONA UN NOLEGGIO DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-ddt w-100 mt-3" *ngIf="selectedDDT">
        <mat-tab-group (selectedTabChange)="activateTab($event.index)">
            <mat-tab label="PDF" class="h-100">
                <ng-template matTabContent>
                    <app-pdf-viewer *ngIf="toShow" [pdfUrl]="getPDFUrl()"></app-pdf-viewer>
                </ng-template>
            </mat-tab>
            <mat-tab label="DETTAGLI">
                <mat-card class="card">
                    <app-new-ddt [(selectedDDT)]="selectedDDT"></app-new-ddt>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>