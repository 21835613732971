<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="row row-div">
            <div class="title col-xl-3">
                <mat-icon svgIcon="hammer-wrench" class="float-left"></mat-icon>
                <div class="item-div">MANUTENZIONI</div>
            </div>
            <div class="form-div col-xl-9 col-12">
                <form name="selectMaintenanceForm" #f="ngForm" novalidate>
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Cliente</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            [formGroup]="selectMaintenanceForm" name="clientId" [formControl]="settingsControl.clientId"
                            [matAutocomplete]="auto" (keyup)="autocompleteClients($event)">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionsScroll)="moreClients()">
                            <mat-option *ngFor="let client of filteredClients" [value]="client">
                                {{ client.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Generatore</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="selectMaintenanceForm"
                        name="generatorId" [formControl]="settingsControl.generatorId" [matAutocomplete]="autoGenerators"
                        (keyup)="autocompleteGenerators($event)">
                    <mat-autocomplete #autoGenerators="matAutocomplete" [displayWith]="displayFnGenerators" (optionsScroll)="moreGenerators()">
                        <mat-option *ngFor="let generator of filteredGenerators" [value]="generator">
                            {{ generator.generatorCode }} - {{ generator.serialNumber }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Cognome Tecnico</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            [formGroup]="selectMaintenanceForm" name="clientId" [formControl]="settingsControl.technicianId"
                            [matAutocomplete]="autoTechnicians" (keyup)="autocompleteTechnicians($event)">
                        <mat-autocomplete #autoTechnicians="matAutocomplete" [displayWith]="displayFnTechnicians">
                            <mat-option *ngFor="let technician of filteredTechnicians | async" [value]="technician">
                                {{ technician.firstName }} {{ technician.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Cognome Responsabile</mat-label>
                        <input type="text" placeholder="Pick one" aria-label="Number" matInput
                            [formGroup]="selectMaintenanceForm" name="clientId" [formControl]="settingsControl.contactId"
                            [matAutocomplete]="autoContacts" (keyup)="autocompleteContacts($event)">
                        <mat-autocomplete #autoContacts="matAutocomplete" [displayWith]="displayFnContacts">
                            <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
                                {{ contact.firstName }} {{ contact.lastName }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                    <button class="clone-maintenances-btn" mat-stroked-button color="primary" (click)="openModalCloneMaintenances()">Copia manutenzioni</button>
                    <button class="clone-maintenances-icon" mat-fab color="primary" aria-label="Copia manutenzioni" (click)="openModalCloneMaintenances()">
                        <mat-icon>content_copy</mat-icon>
                      </button>
                </form>
            </div>
        </div>
    </mat-card>
</div>