<div class="main-container h-100 w-100">
    <div class="no-generator" *ngIf="!selectedGenerator">SELEZIONA UN GENERATORE DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-generator w-100 mt-3" *ngIf="selectedGenerator">
        <mat-tab-group>
            <mat-tab label="SPECIFICHE">
                <mat-card class="card">
                    <app-new-generator [(selectedGenerator)]="selectedGenerator"></app-new-generator>
                </mat-card>
            </mat-tab>
            <mat-tab label="FILTRI">
                <app-generator-filter-links [(selectedGenerator)]="selectedGenerator"></app-generator-filter-links>
            </mat-tab>
            <mat-tab label="MANUTENZIONI">
                <app-maintenances-of-generator [(selectedGenerator)]="selectedGenerator"></app-maintenances-of-generator>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>