<div class="logo-div">
  <img class="logo" src="assets/imgs/logo.png" alt="">
</div>

<div class="menu">
  <mat-list>
    <div mat-list-item>
      <div class="pointer item" [routerLink]="['home']" [ngClass]="{'selected-link': currentLink === 'home'}" matTooltip="Home" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="home"></mat-icon>
        </div>
        <div class="item-div">Homepage</div>
      </div>
      <div class="pointer item" [routerLink]="['rentals']" [ngClass]="{'selected-link': currentLink === 'rentals'}" matTooltip="Noleggi" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="clipboard-flow"></mat-icon>
        </div>
        <div class="item-div">Noleggi</div>
      </div>
      <div class="pointer item" [routerLink]="['contracts']" [ngClass]="{'selected-link': currentLink === 'contracts'}" matTooltip="Contratti" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="file-document-edit"></mat-icon>
        </div>
        <div class="item-div">Contratti</div>
      </div>
      <div class="pointer item" [routerLink]="['workReports']" [ngClass]="{'selected-link': currentLink === 'workReports'}" matTooltip="Buoni di lavoro" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="file-chart"></mat-icon>
        </div>
        <div class="item-div">Buoni di lavoro</div>
      </div>
      <div class="pointer item" [routerLink]="['clients']" [ngClass]="{'selected-link': currentLink === 'clients'}" matTooltip="Clienti" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="account"></mat-icon>
        </div>
        <div class="item-div">Clienti</div>
      </div>
      <div class="pointer item" [routerLink]="['ddts']" [ngClass]="{'selected-link': currentLink === 'ddts'}" matTooltip="DDT" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="truck-delivery"></mat-icon>
        </div>
        <div class="item-div">DDT</div>
      </div>
      <div class="pointer item" [routerLink]="['generators']" [ngClass]="{'selected-link': currentLink === 'generators'}" matTooltip="Gruppi elettrogeni" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="flash-circle"></mat-icon>
        </div>
        <div class="item-div">Gruppi elettrogeni</div>
      </div>
      <div class="pointer item" [routerLink]="['maintenances']" [ngClass]="{'selected-link': currentLink === 'maintenances'}" matTooltip="Manutenzioni" matTooltipPosition="right">
        <div class="icon-div">
          <mat-icon svgIcon="hammer-wrench"></mat-icon>
        </div>
        <div class="item-div">Manutenzioni</div>
      </div>
    </div>
    <div class="pointer item" [routerLink]="['other']" [ngClass]="{'selected-link': currentLink === 'other'}" matTooltip="Altri dati" matTooltipPosition="right">
      <div class="icon-div">
        <mat-icon svgIcon="database"></mat-icon>
      </div>
      <div class="item-div" >Altri dati</div>
    </div>
  </mat-list>
  <mat-list class="w-100 logout-item" (click)="logout()">
  <div class="pointer item" matTooltip="Logout" matTooltipPosition="right">
    <div class="icon-div">
      <mat-icon svgIcon="logout"></mat-icon>
    </div>
    <div class="item-div">Logout</div>
  </div>
  </mat-list>
</div>