<div class="form-container">
    <div class="title text-center" *ngIf="!selectedRental">INSERISCI NOLEGGIO</div>
    <div class="title text-center" *ngIf="selectedRental">MODIFICA NOLEGGIO</div>
    <form name="addRentalForm" (ngSubmit)="selectedRental ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Generatore*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addRentalForm"
                    name="generatorId" [formControl]="settingsControl.generatorId" [matAutocomplete]="auto"
                    (keyup)="autocompleteGenerators($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnGenerators" (optionsScroll)="moreGenerators()">
                    <mat-option *ngFor="let generator of filteredGenerators" [value]="generator">
                        {{ generator.generatorCode }} - {{ generator.serialNumber }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Cliente*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addRentalForm"
                    name="clientId" [formControl]="settingsControl.clientId" [matAutocomplete]="autoCompleteClients"
                    (keyup)="autocompleteClients($event)">
                <mat-autocomplete #autoCompleteClients="matAutocomplete" [displayWith]="displayFnClients"
                    (optionsScroll)="moreClients()">
                    <mat-option *ngFor="let client of filteredClients" [value]="client">
                        {{ client.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>


            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Data uscita</mat-label>
                <input matInput [matDatepicker]="pickerOut" [formGroup]="addRentalForm" name="outDate"
                    [formControl]="settingsControl.outDate" [disabled]="addRentalForm.value.status === 'IN'">
                <mat-datepicker-toggle matSuffix [for]="pickerOut"></mat-datepicker-toggle>
                <mat-datepicker #pickerOut></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Data entrata</mat-label>
                <input matInput [matDatepicker]="pickerIn" [formGroup]="addRentalForm" name="inDate"
                    [formControl]="settingsControl.inDate" [disabled]="addRentalForm.value.status === 'OUT'">
                <mat-datepicker-toggle matSuffix [for]="pickerIn"></mat-datepicker-toggle>
                <mat-datepicker #pickerIn></mat-datepicker>
            </mat-form-field>

            <br>
        </div>
        <div class="shared-form">
            <mat-radio-group aria-label="Select an option" [formGroup]="addRentalForm" name="status"
                [formControl]="settingsControl.status">
                <mat-radio-button value="OUT">Uscita</mat-radio-button>
                <mat-radio-button value="IN">Entrata</mat-radio-button>
            </mat-radio-group>
        </div>

        <br>
        <div class="leave-form" *ngIf="addRentalForm.value.status === 'OUT'">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>DDT</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addRentalForm"
                    name="ddtId" [formControl]="settingsControl.ddtId" [matAutocomplete]="autoDDTs"
                    (keyup)="autocompleteDDTs($event)">
                <mat-autocomplete #autoDDTs="matAutocomplete" [displayWith]="displayFnDDTs" (optionsScroll)="moreDDTs()">
                    <mat-option *ngFor="let ddt of filteredDDTs" [value]="ddt">
                        {{ ddt.client.name }} - {{ ddt.date | date: 'dd/MM/yyyy' }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>N. ore uscita</mat-label>
                <input matInput placeholder="" [formGroup]="addRentalForm" name="outHours"
                    [formControl]="settingsControl.outHours">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Accessori</mat-label>
                <mat-select [formControl]="settingsControl.accessoryIds" multiple>
                    <mat-option *ngFor="let accessory of accessories" [value]="accessory.id">
                        {{ accessory.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <div class="enter-form" *ngIf="addRentalForm.value.status === 'IN'">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>N. ore entrata</mat-label>
                <input matInput placeholder="" [formGroup]="addRentalForm" name="inHours"
                    [formControl]="settingsControl.inHours">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input textarea">
                <mat-label>Note</mat-label>
                <textarea matInput placeholder="" [formGroup]="addRentalForm" name="note"
                    [formControl]="settingsControl.note" rows="5" cols="40"></textarea>
            </mat-form-field>
        </div>
        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right"
            [disabled]="!addRentalForm.valid ">Salva</button>
    </form>
</div>