import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { GraphQLModule } from '@app/graphql.module';
import { AppMaterialModule } from '@app/app-material.module';
import { MatIconRegistry } from '@angular/material/icon';

import { OtherComponent } from './other.component';
import { NewFilterComponent } from './filters/new-filter/new-filter.component';
import { FiltersComponent } from './filters/filters.component';
import { FilterPageComponent } from './filters/filter-page/filter-page.component';
import { NewAccessoryComponent } from './accessories/new-accessory/new-accessory.component';
import { AccessoryPageComponent } from './accessories/accessory-page/accessory-page.component';
import { AccessoriesComponent } from './accessories/accessories.component';
import { NewTechnicianComponent } from './technicians/new-technician/new-technician.component';
import { TechnicianPageComponent } from './technicians/technician-page/technician-page.component';
import { TechniciansComponent } from './technicians/technicians.component';
import { CarriersComponent } from './carriers/carriers.component';
import { NewCarrierComponent } from './carriers/new-carrier/new-carrier.component';
import { CarrierPageComponent } from './carriers/carrier-page/carrier-page.component';
import { NewInvoiceComponent } from './invoices/new-invoice/new-invoice.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoicePageComponent } from './invoices/invoice-page/invoice-page.component';
import { AddRentalsComponent } from './invoices/add-rentals/add-rentals.component';
import { AddWorkReportsComponent } from './invoices/add-workReports/add-workReports.component';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    AppMaterialModule,
  ],
  declarations: [
    OtherComponent,
    FiltersComponent,
    FilterPageComponent,
    NewFilterComponent,
    NewAccessoryComponent,
    AccessoryPageComponent,
    AccessoriesComponent,
    NewTechnicianComponent,
    TechnicianPageComponent,
    TechniciansComponent,
    NewCarrierComponent,
    CarrierPageComponent,
    CarriersComponent,
    NewInvoiceComponent,
    InvoicesComponent,
    InvoicePageComponent,
    AddRentalsComponent,
    AddWorkReportsComponent,
  ],
  exports: [
  ],
  providers: [],
})
export class OtherModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
