import { Component, OnInit, SimpleChanges } from '@angular/core';

import { ClientsService } from '@services/clients/clients.service';
import { Client, ClientContact } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent extends BaseComponent implements OnInit {
  // clients: Client[] = [];
  filteredClients: Client[] = [];
  selectedClient: Client = null;
  currentFilter: {[key: string]: { value, from }} = {};
  constructor(
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.clients = [...clients, ...clients, ...clients, ...clients];
        // this.clients = clients;
        this.filteredClients = clients;
        // this.filterClients(this.currentFilter);
        if (this.selectedClient) {
          const id = this.selectedClient.id;
          this.selectedClient = this.filteredClients.filter((el) => el.id === id)[0];
        }
        this.eventBusService.showSpinnerEvent.next(false);
      }),
      this.eventBusService.filterClientsEvent.subscribe((filter) => {
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterClients()
      })
    );

  }

  ngOnInit(): void {
  }

  selectClient(el) {
    this.selectedClient = el;
  }

  filterClients() {
    this.eventBusService.showSpinnerEvent.next(true);
    this.selectedClient = null;
    this.clientsService.getClients(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      this.filteredClients = clients;
      this.eventBusService.showSpinnerEvent.next(false);
    });

  }

  loadMore() {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // TODO: remove
  // filterClients(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredClients = this.clients;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredClients = this.clients.filter((el) => {
  //     if (filter.from === 'clientCode') {
  //       return el.clientCode === parseInt(filter.value, 10);
  //     }
  //     if (filter.from === 'name') {
  //       return el.name.toLowerCase().includes(filter.value.toLowerCase());
  //     }
  //     return true;
  //   });
  // }

}
