import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filter-page',
  templateUrl: './filter-page.component.html',
  styleUrls: ['./filter-page.component.scss']
})
export class FilterPageComponent implements OnInit {
  @Input() selectedFilter;
  constructor() {

  }

  ngOnInit(): void {
  }

}
