<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="title">
            <mat-icon svgIcon="flash-circle" class="float-left"></mat-icon>
            <div class="item-div">GENERATORI</div>
        </div>
        <div class="form-div">
            <form name="selectClientForm" #f="ngForm" novalidate>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Matricola generatore</mat-label>
                    <input matInput (keyup)="filter($event, 'serialNumber')" placeholder="" [formGroup]="selectGeneratorForm"
                        name="name" [formControl]="settingsControl.serialNumber">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input generator-status">
                    <mat-label>Stato</mat-label>
                    <mat-select (selectionChange)="filter($event, 'status')" name="food" [formGroup]="selectGeneratorForm" name="status" [formControl]="settingsControl.status">
                        <mat-option [value]="''">
                            Tutti
                        </mat-option>
                        <mat-option *ngFor="let status of generatorStatuses" [value]="status.value">
                            {{status.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>kVA</mat-label>
                    <input matInput (keyup)="filter($event, 'kVA')" placeholder="" [formGroup]="selectGeneratorForm"
                        name="name" [formControl]="settingsControl.kVA">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Marca generatore</mat-label>
                    <input matInput (keyup)="filter($event, 'brand')" placeholder="" [formGroup]="selectGeneratorForm"
                        name="name" [formControl]="settingsControl.brand">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Cliente</mat-label>
                    <input matInput (keyup)="filter($event, 'clientName')" placeholder="" [formGroup]="selectGeneratorForm"
                        name="name" [formControl]="settingsControl.clientName">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </mat-card>
</div>