<div class="main-container w-100 h-100">
    <div class="select-user-div">
        <app-select-ddt></app-select-ddt>
    </div>
    <div class="row no-margin ddts-container">
        <div class="col-3  h-100 ddt-card-div"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
        >
           <mat-card class="ddt-card pointer" *ngFor="let ddt of filteredDDTs"  [ngClass]="{selected: selectedDDT && selectedDDT.id === ddt.id}" (click)="selectDDT(ddt)">
               {{ ddt.ddtCode }} - {{ ddt.client.name }} - {{ ddt.date | date: 'dd/MM/yyyy' }}
            <!--<button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="download(ddt.id)">
                <mat-icon>cloud_download</mat-icon>
              </button>-->
            </mat-card>
        </div>
        <div class="col-9 h-100">
           <app-ddt-page [(selectedDDT)]="selectedDDT"></app-ddt-page> 
        </div>
    </div>
</div>