import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-select-workReport',
  templateUrl: './select-workReport.component.html',
  styleUrls: ['./select-workReport.component.scss']
})
export class SelectWorkReportComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectWorkReportForm: FormGroup;
  settingsControl = {
    clientName: new FormControl(''),
    generatorCode: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectWorkReportForm = formBuilder.group({
      clientName: this.settingsControl.clientName,
      generatorCode: this.settingsControl.generatorCode,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterWorkReportsEvent.next(content);
    });
  }

  filter(ev, from) {
    let value = ev.target ? ev.target.value : ev.value;
    if (from === 'generatorCode') {
      value = +value;
    }
    this.filterSubject.next({value, from});
  }
}
