import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';
import { GeneratorStatus } from '@app/graphql/generated/graphql';

@Component({
  selector: 'app-select-generator',
  templateUrl: './select-generator.component.html',
  styleUrls: ['./select-generator.component.scss']
})
export class SelectGeneratorComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectGeneratorForm: FormGroup;
  generatorStatuses = Constants.GENERATOR_STATES;
  settingsControl = {
    serialNumber: new FormControl(''),
    status: new FormControl(GeneratorStatus.Available),
    kVA: new FormControl(''),
    brand: new FormControl(''),
    clientName: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectGeneratorForm = formBuilder.group({
      serialNumber: this.settingsControl.serialNumber,
      status: this.settingsControl.status,
      kW: this.settingsControl.status,
      clientName: this.settingsControl.clientName,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterGeneratorsEvent.next(content);
    });
    // TODO: check me
    this.filter({target: { value: GeneratorStatus.Available }}, 'status');
  }

  filter(ev, from) {
    let value = ev.target ? ev.target.value : ev.value;
    if (from === 'kVA') {
      value = +value;
    }
    this.filterSubject.next({value, from});
  }
}
