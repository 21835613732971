import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from '@shared/shared.module';
import { GraphQLModule } from '@app/graphql.module';
import { AppMaterialModule } from '@app/app-material.module';
import { MatIconRegistry } from '@angular/material/icon';

import { AddModule } from '@pages/add/add.module';
import { WorkReportPageComponent } from './workReport-page/workReport-page.component';
import { WorkReportsComponent } from './workReports.component';


@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GraphQLModule,
    AppMaterialModule,
    AddModule,
  ],
  declarations: [
    WorkReportPageComponent,
    WorkReportsComponent,
  ],
  providers: [],
  exports: [
  ],
})
export class WorkReportsModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg'));
  }
}
