import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-select-technician',
  templateUrl: './select-technician.component.html',
  styleUrls: ['./select-technician.component.scss']
})
export class SelectTechnicianComponent implements OnInit {


  private filterSubject: Subject<any> = new Subject();
  selectTechnicianForm: FormGroup;
  settingsControl = {
    lastName: new FormControl(''),
    email: new FormControl(''),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
  ) {
    this.selectTechnicianForm = formBuilder.group({
      lastName: this.settingsControl.lastName,
      email: this.settingsControl.email,
    });
  }

  ngOnInit(): void {
    this.filterSubject.pipe(debounceTime(500)).subscribe( content => {
      this.eventBusService.filterTechniciansEvent.next(content);
    });
  }

  filter(ev, from) {
    this.filterSubject.next({value: ev.target.value, from});
  }
}
