import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { InvoiceRentalLink, Rental } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { RentalsService } from '@services/rentals/rentals.service';
import { BaseComponent } from '@shared/base/base.component';
import { Constants } from '@shared/constants';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-rentals',
  templateUrl: './add-rentals.component.html',
  styleUrls: ['./add-rentals.component.scss']
})
export class AddRentalsComponent extends BaseComponent implements OnInit {
  @Input() rentalsForms: FormGroup = null;
  @Input() value: InvoiceRentalLink[] = null;
  private autocompleteRentalsSubject: Subject<string> = new Subject();
  filterRentals = {};
  filteredRentals: Rental[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private readonly eventBusService: EventBusService,
    private readonly rentalsService: RentalsService,
  ) {
    super();
    this.subscriptions.push(
      //   this.rentalsService.getRentals().subscribe((rentals: Rental[]) => {
      //     this.rentals = rentals;
      //   }),
      // );
        this.rentalsService.getRentalsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((rentals: Rental[]) => {
          this.eventBusService.showSpinnerEvent.next(false);
          this.filteredRentals = rentals;
        }),
      );

    this.autocompleteRentalsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterRentals === '') {
        return;
      } else if (!value) {
        this.filterRentals = {};
      } else {
        this.filterRentals = { rentalAmount: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.rentalsService.getRentalsList({ rentalAmount: +value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((rentals: Rental[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredRentals = rentals;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
  }

  ngOnInit(): void {
    if (!this.rentalsForms) {
      this.rentalsForms = this.formBuilder.group({
        rentalsArray: this.formBuilder.array([])
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('value' in changes && changes.value.currentValue) {
      const array = this.getRentalsArray();
      array.clear();
      console.log(changes.value)
      for (const el of changes.value.currentValue) {
        this.getRentalsArray().push(
          this.formBuilder.group({
            rentalId: [el.rental, [Validators.required]],
            amount: [el.amount, [Validators.required]],
          })
        );
      }
    }
  }

  moreRentals() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredRentals.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredRentals[this.filteredRentals.length - 1].id;
      this.rentalsService.moreRentalsList(this.filterRentals, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  getRentalsArray() {
    return this.rentalsForms.get('rentalsArray') as FormArray;
  }

  addItem() {
    this.getRentalsArray().push(
      this.formBuilder.group({
        rentalId: ['', [Validators.required]],
        amount: ['', [Validators.required]],
      })
    );
  }

  async removeItem(index: number) {
    this.getRentalsArray().removeAt(index);
  }

autocompleteRentals(ev) {
    this.autocompleteRentalsSubject.next(ev.target.value);
  }

  displayFnRentals(rental: Rental): string {
    console.log(rental)
    if (!rental) {
      return '';
    }
    return rental ?
      `${rental.generator.serialNumber} - ${rental.client.name} -
      ${moment(rental.outDate).format('DD/MM/YYYY')}` : rental.client.name;
  }
}
