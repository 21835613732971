import { Component, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

import { MaintenancesService } from '@services/maintenances/maintenances.service';
import { Maintenance } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';
import { environment } from '@env/environment';

@Component({
  selector: 'app-maintenances',
  templateUrl: './maintenances.component.html',
  styleUrls: ['./maintenances.component.scss']
})
export class MaintenancesComponent extends BaseComponent implements OnInit {
  maintenances: Maintenance[] = [];
  filteredMaintenances: Maintenance[] = [];
  selectedMaintenance: Maintenance = null;
  currentFilter: any = {};
  fromDate: Date;
  toDate: Date;
  constructor(
    private readonly maintenancesService: MaintenancesService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.fromDate = moment().startOf('month').add(-7, 'days').set({hour:0,minute:0,second:0,millisecond:0}).toDate(); // less one week
    this.toDate = moment().endOf('month').add(7, 'days').set({hour:0,minute:0,second:0,millisecond:0}).toDate(); // add one week
  }
  
  ngOnInit(): void {
      console.log(this.fromDate, this.toDate)
    this.subscriptions.push(
       //FIXME:
      this.maintenancesService.getMaintenancesList({}, this.fromDate, this.toDate).subscribe((maintenances: Maintenance[]) => {
        this.maintenances = maintenances;
        this.filteredMaintenances = maintenances;
        // this.filterMaintenances(this.currentFilter);
        if (this.selectedMaintenance) {
          const id = this.selectedMaintenance.id;
          this.selectedMaintenance = this.filteredMaintenances.filter((el) => el.id === id)[0];
        }
      }),
      this.eventBusService.filterMaintenancesEvent.subscribe((filter) => {
        // console.log(filter)
        // this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.currentFilter = filter;
        this.filterMaintenances();
      })
    );
  }

  selectMaintenance(el) {
    this.selectedMaintenance = el;
  }

  // filterMaintenances() {
  // this.eventBusService.showSpinnerEvent.next(true);
  //   this.selectedMaintenance = null;
  //   console.log(this.currentFilter)
  //   this.maintenancesService.getMaintenancesList(this.currentFilter, this.fromDate, this.toDate).subscribe((maintenances: Maintenance[]) => {
  //     this.filteredMaintenances = maintenances;
  //     this.eventBusService.showSpinnerEvent.next(false);
  //   });

  // }

  filterMaintenances() {
    this.filteredMaintenances = this.maintenances;
    console.log(this.currentFilter)
    if (this.currentFilter.clientId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => {
        return man.generator.client && man.generator.client.id === this.currentFilter.clientId;
      });
    }
    if (this.currentFilter.generatorId) {
      console.log(this.currentFilter.generatorId, this.filteredMaintenances)
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => man.generator.id === this.currentFilter.generatorId);
    }
    if (this.currentFilter.technicianId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => {
        console.log(man.technicians)
        return man.technicians && man.technicians.some(tec => tec.id === this.currentFilter.technicianId)
      });
    }
    if (this.currentFilter.contactId) {
      this.filteredMaintenances = this.filteredMaintenances.filter((man) => man.generator.contacts.some(con => con.id === this.currentFilter.contactId));
    }
    console.log(this.filteredMaintenances)
    // this.fillCalendar();
  }

  loadMore() {
    // if (this.filteredMaintenances.length > 0) {
    this.eventBusService.showSpinnerEvent.next(true);
    // const lastId = this.filteredMaintenances[this.filteredMaintenances.length - 1].id;
    console.log(this.fromDate, this.toDate)
    this.maintenancesService.moreMaintenances({}, this.fromDate, this.toDate).then(() => this.filterMaintenances());
    // }
  }

  changeIntervalDate(data) {
    this.fromDate = data.fromDate;
    this.toDate = data.toDate;
    this.loadMore();
  }
  // TODO: remove
  // filterMaintenances(filter: { value, from }) {
  //   if (!filter.from || !filter.value) {
  //     this.filteredMaintenances = this.maintenances;
  //     return;
  //   }
  //   this.currentFilter = filter;
  //   this.filteredMaintenances = this.maintenances.filter((el) => {
  //     /*if (filter.from === 'rentalAmount') {
  //       return el.rentalAmount === filter.value;
  //     }*/
  //     return true;
  //   });
  // }

  download(id) {
    window.open(environment.apiUrlPdf + '/pdfs/maintenances/' + id, '_blank');
    return true;
  }



}
