<div class="main-container w-100 h-100">
    <div class="select-user-div">
        <app-select-workReport></app-select-workReport>
    </div>
    <div class="row no-margin workReports-container">
        <div class="col-3 h-100 workReport-card-div"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="loadMore()"
        >
            <mat-card class="workReport-card pointer"
                [ngClass]="{selected: selectedWorkReport && selectedWorkReport.id === workReport.id}"
                *ngFor="let workReport of filteredWorkReports" (click)="selectWorkReport(workReport)">
                {{ workReport.workReportCode }} - {{ workReport.client.name }} 
                <span *ngIf="workReport.maintenance">- {{ workReport.maintenance.generator.generatorCode }} - {{ workReport.maintenance.startDate | date: 'dd/MM/yyyy' }}</span>
                <!--<button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon" (click)="download(workReport.id)">
                <mat-icon>cloud_download</mat-icon>
              </button> -->
            </mat-card>
        </div>
        <div class="col-9 h-100">
            <app-workReport-page [(selectedWorkReport)]="selectedWorkReport"></app-workReport-page>
        </div>
    </div>
</div>