import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '@pages/home/home.component';
import { LoginComponent } from '@app/login/login.component';
import { SignupComponent } from '@app/signup/signup.component';
import { AuthGuard } from '@services/auth/auth.guard';
import { NoAuthGuard } from '@services/auth/no-auth.guard';
import { ClientsComponent } from '@pages/clients/clients.component';
import { AddRoutingModule } from '@pages/add/add-routing.module';
import { GeneratorsComponent } from '@pages/generators/generators.component';
import { OtherComponent } from '@pages/other/other.component';
import { RentalsComponent } from '@pages/rentals/rentals.component';
import { DDTsComponent } from '@pages/ddts/ddts.component';
import { ContractsComponent } from '@pages/contracts/contracts.component';
import { WorkReportsComponent } from '@pages/workReports/workReports.component';
import { MaintenancesComponent } from '@pages/maintenances/maintenances.component';



const routes: Routes = [
  { path: '', canActivate: [AuthGuard], pathMatch: 'full', redirectTo: '/home' },
  { path: 'login', canActivate: [NoAuthGuard], component: LoginComponent},
  { path: 'signup', canActivate: [NoAuthGuard], component: SignupComponent},
  {
    path: '', canActivate: [AuthGuard], children: [
      { path: 'home', component: HomeComponent },
      { path: 'rentals', component: RentalsComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'ddts', component: DDTsComponent},
      { path: 'other', component: OtherComponent },
      { path: 'generators', component: GeneratorsComponent },
      { path: 'maintenances', component: MaintenancesComponent},
      { path: 'workReports', component: WorkReportsComponent},
      { path: 'contracts', component: ContractsComponent},
    ]
  },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [
    AddRoutingModule,
    RouterModule.forRoot(routes, { useHash: true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
