import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import {InMemoryCache, ApolloLink, FieldPolicy} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from '@env/environment';
import { setContext } from '@apollo/client/link/context';

const uri = environment.apiUrl;
/*export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  return {
    link: httpLink.create({uri}),
    cache: new InMemoryCache(),
  };
}*/

export function createApollo(httpLink: HttpLink) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-9'
    }
  }));

  const auth = setContext((operation, context) => {
    const token = JSON.parse(sessionStorage.getItem('token'));

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
    }
  });

  const link = ApolloLink.from([basic, auth, httpLink.create({ uri })]);
  // const cache = new InMemoryCache();
  const queryMergeLogic = (params) => {
    return {
      keyArgs: [...params],
      merge(existing, incoming) {
        if (!existing) {
          return incoming;
        }
        const existingNodes = existing.edges || [];
        const incomingNodes = incoming.edges || [];
        const edges = [...new Set([...existingNodes,...incomingNodes])];
        return { ...incoming, edges };
      }
    } as FieldPolicy<any>;
  }

  const mutationMergeLogic = (param) => {
    return {
      keyArgs: [],
      merge(_, incoming, { cache }) {
        cache.modify({
          fields: {
            [param](existing) {
              if (!existing) {
                return [{ ...incoming }];
              }
              const existingNodes = existing.edges || [];
              const incomingNodes = incoming.edges || [{ ...incoming }];
              const edges = [...new Set([...existingNodes, ...incomingNodes])];
              return { ...incoming, edges };
            },
          },
        })
      },
    } as FieldPolicy<any>;
  }

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ddts: queryMergeLogic(['carrierName']),
          generators: queryMergeLogic(['kVA', 'clientName', 'brand', 'serialNumber', 'status']),
          rentals: queryMergeLogic(['status']),
          filters: queryMergeLogic(['code', 'category', 'brand']),
          clients: queryMergeLogic(['name', 'clientCode']),
          contracts: queryMergeLogic(['rentalAmount']),
          workReports: queryMergeLogic(['clientName', 'generatorCode']),
          // workReports: mergeLogic(['clientName']),
          // maintenances: mergeLogic(['fromDate', 'toDate', 'generatorId', 'clientId']),
          maintenances: {
            keyArgs: ['generatorId', 'clientId'],
            merge(existing, incoming) {
              if (!existing) {
                return incoming;
              }
              return incoming;
            }
          },
        }
      },
      Mutation: {
        fields: {
          createGenerator: mutationMergeLogic('generators'),
          updateGenerator: mutationMergeLogic('generators'),
          createRental: mutationMergeLogic('rentals'),
          updateRental: mutationMergeLogic('rentals'),
          createClient: mutationMergeLogic('clients'),
          updateClient: mutationMergeLogic('clients'),
          createContract: mutationMergeLogic('contracts'),
          updateContract: mutationMergeLogic('contracts'),
          createDDT: mutationMergeLogic('ddts'),
          updateDDT: mutationMergeLogic('ddts'),
          createInvoice: mutationMergeLogic('invoices'),
          updateInvoice: mutationMergeLogic('invoices'),
          createMaintenance: mutationMergeLogic('maintenances'),
          updateMaintenance: mutationMergeLogic('maintenances'),
          createWorkReport: mutationMergeLogic('workReports'),
          updateWorkReport: mutationMergeLogic('workReports'),
          createClientContact: mutationMergeLogic('clientContacts'),
          updateClientContact: mutationMergeLogic('clientContacts'),
        }
      }
    }
  })

  return {
    link,
    cache
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule { }
