<div class="main-container w-100">
    <div class="no-filter" *ngIf="getTasksArray().controls.length === 0">Nessun task aggiunto</div>
    <form [formGroup]="tasksForms" *ngIf="getTasksArray().controls.length > 0" class="w-100">
        <div formArrayName="tasksArray">
            <div *ngFor="let item of getTasksArray().controls; let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline" class="form-input filter-brand">
                    <mat-label>Operazione*</mat-label>
                    <mat-select name="operation" formControlName="operation">
                        <mat-option *ngFor="let operation of taskOperations" [value]="operation.value">
                            {{ operation.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input textarea">
                    <mat-label>Note</mat-label>
                    <textarea matInput placeholder="" name="description" formControlName="description" rows="1"
                        cols="40"></textarea>
                </mat-form-field>
                <mat-checkbox class="example-margin" formControlName="completed">Completata</mat-checkbox>
                <button class="remove-btn" (click)="removeItem(i)" mat-button color="warn">
                    <mat-icon class="icons" color="warn" svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>
    </form>
    <div class="form-buttons-div">
        <button mat-flat-button color="primary" class="add-btn" (click)="addItem()">
            <mat-icon class="icons" svgIcon="plus"></mat-icon>
            AGGIUNGI TASK
        </button>
    </div>
</div>