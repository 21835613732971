import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppTranslateModule } from '@app/app-translate.module';
import { RouterModule } from '@angular/router';

import { BaseComponent } from './base/base.component';
import { AuthGuard } from '@services/auth/auth.guard';
import { NoAuthGuard } from '@services/auth/no-auth.guard';
import { SelectClientComponent } from './select-client/select-client.component';
import { AppMaterialModule } from '@app/app-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SelectGeneratorComponent } from './select-generator/select-generator.component';
import { SelectFilterComponent } from './select-filter/select-filter.component';
import { SelectAccessoryComponent } from './select-accessory/select-accessory.component';
import { SelectTechnicianComponent } from './select-technician/select-technician.component';
import { SelectCarrierComponent } from './select-carrier/select-carrier.component';
import { SelectInvoiceComponent } from './select-invoice/select-invoice.component';
import { SelectRentalComponent } from './select-rental/select-rental.component';
import { SelectDDTComponent } from './select-ddt/select-ddt.component';
import { SelectContractComponent } from './select-contract/select-contract.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { AskConfirmComponent } from './ask-confirm-modal/ask-confirm-modal.component';
import { CustomTableComponent } from './custom-table/custom-table.component';
import { SelectWorkReportComponent } from './select-workReport/select-workReport.component';
import { SelectMaintenanceComponent } from './select-maintenance/select-maintenance.component';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CloneMaintenancesModalComponent } from './select-maintenance/clone-maintenances-modal/clone-maintenances-modal.component';
import { OptionsScrollDirective } from '@directives/options-scroll.directive';



@NgModule({
  imports: [
    AppTranslateModule,
    RouterModule,
    CommonModule,
    AppMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxExtendedPdfViewerModule,
    NgxMatDatetimePickerModule,
    InfiniteScrollModule,
  ],
  declarations: [
    BaseComponent,
    SelectClientComponent,
    SelectGeneratorComponent,
    SelectFilterComponent,
    SelectAccessoryComponent,
    SelectTechnicianComponent,
    SelectCarrierComponent,
    SelectInvoiceComponent,
    SelectRentalComponent,
    SelectDDTComponent,
    SelectContractComponent,
    SelectWorkReportComponent,
    SelectMaintenanceComponent,
    PdfViewerComponent,
    AskConfirmComponent,
    CustomTableComponent,
    OptionsScrollDirective,
    CloneMaintenancesModalComponent,
  ],
  exports: [
    AppTranslateModule,
    RouterModule,
    NgxExtendedPdfViewerModule,
    BaseComponent,
    SelectClientComponent,
    SelectGeneratorComponent,
    SelectFilterComponent,
    SelectAccessoryComponent,
    SelectTechnicianComponent,
    SelectCarrierComponent,
    SelectInvoiceComponent,
    SelectRentalComponent,
    SelectDDTComponent,
    SelectContractComponent,
    SelectWorkReportComponent,
    PdfViewerComponent,
    SelectMaintenanceComponent,
    AskConfirmComponent,
    CustomTableComponent,
    NgxMatDatetimePickerModule,
    InfiniteScrollModule,
    OptionsScrollDirective,
  ],
  providers: [
    AuthGuard,
    NoAuthGuard,
  ]
})
export class SharedModule { }
