import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateFilterGeneratorLinkGQL,
  CreateFilterGeneratorLinkInput,
  DeleteFilterGeneratorLinkGQL,
  DeleteFilterGeneratorLinkInput,
  GeneratorsDocument,
  UpdateFilterGeneratorLinkGQL,
  UpdateFilterGeneratorLinkInput
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class FilterGeneratorLinksService {

  constructor(
    private readonly createFilterGeneratorLinkService: CreateFilterGeneratorLinkGQL,
    private readonly deleteFilterGeneratorLinkService: DeleteFilterGeneratorLinkGQL,
    private readonly updateFilterGeneratorLinkService: UpdateFilterGeneratorLinkGQL,
  ) { }

  /*mutations*/
  addNewFilterGeneratorLink(payload: CreateFilterGeneratorLinkInput) {
    return this.createFilterGeneratorLinkService.mutate({ data: payload }, {refetchQueries: [{query: GeneratorsDocument}]});
  }

  updateFilterGeneratorLink(payload: UpdateFilterGeneratorLinkInput) {
    return this.updateFilterGeneratorLinkService.mutate({ data: payload }, {refetchQueries: [{query: GeneratorsDocument}]}).pipe(map(val => {
      return val.data.updateFilterGeneratorLink;
    }));
  }

  deleteFilterGeneratorLink(payload: DeleteFilterGeneratorLinkInput) {
    return this.deleteFilterGeneratorLinkService.mutate({ data: payload }, {refetchQueries: [{query: GeneratorsDocument}]}).pipe(map(val => {
      return val.data.deleteFilterGeneratorLink;
    }));
  }

}
