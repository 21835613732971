import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventBusService } from '@services/event-bus.service';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.scss']
})
export class RightSidebarComponent implements OnInit {

  constructor(
    private readonly eventBusService: EventBusService,
  ) { }

  ngOnInit(): void {
  }

  rightSidebarClick() {
    this.eventBusService.rightSidebarClick.next();
    return;
  }

}
