<div class="form-container">
    <div class="title text-center" *ngIf="!selectedContract">INSERISCI CONTRATTO</div>
    <div class="title text-center" *ngIf="selectedContract">MODIFICA CONTRATTO</div>
    <form name="addContractForm" (ngSubmit)="selectedContract ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <div class="shared-form">

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Cliente*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addContractForm"
                    name="clientId" [formControl]="settingsControl.clientId" [matAutocomplete]="auto"
                    (keyup)="autocompleteClients($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients" (optionsScroll)="moreClients()">
                    <mat-option *ngFor="let client of filteredClients" [value]="client">
                        {{ client.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Generatori</mat-label>
                <mat-select [formControl]="settingsControl.generatorIds" multiple>
                    <mat-option *ngFor="let generator of generators" [value]="generator.id">
                       {{ generator.generatorCode }} - {{ generator.serialNumber }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- <mat-form-field appearance="outline" class="form-input">
                <mat-label>Data noleggio*</mat-label>
                <input matInput [matDatepicker]="picker" [formGroup]="addContractForm" name="rentalDate"
                    [formControl]="settingsControl.rentalDate">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                </mat-form-field> -->
                <mat-form-field class="form-input" appearance="outline">
                    <mat-label>Data/ora noleggio*</mat-label>
                    <input matInput [ngxMatDatetimePicker]="picker" placeholder="Data/ora noleggio*"
                        [formControl]="settingsControl.rentalDate" name="rentalDate">
                    <mat-datepicker-toggle matSuffix [for]="picker">
                    </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #picker>
                    </ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-checkbox [formGroup]="addContractForm" name="status" class="checkbox"
                    [formControl]="settingsControl.extendedRental">Più di 30 giorni?</mat-checkbox>
                </div>
                <div class="shared-form">
                
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>N. giorni noleggio*</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="rentalDays"
                            [formControl]="settingsControl.rentalDays">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input" *ngIf="settingsControl.extendedRental.value === false">
                        <mat-label>Totale noleggio</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="rentalAmount"
                            [formControl]="settingsControl.rentalAmount">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Accessori</mat-label>
                        <mat-select [formControl]="settingsControl.accessoryIds" multiple>
                            <mat-option *ngFor="let accessory of accessories" [value]="accessory.id">
                                {{ accessory.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Deposito cauzionale</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="securityDepositAmount"
                            [formControl]="settingsControl.securityDepositAmount">
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label>Valore ass. generatore</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="generatorInsuranceValue"
                            [formControl]="settingsControl.generatorInsuranceValue">
                    </mat-form-field>
                
                    <mat-form-field appearance="outline" class="form-input" *ngIf="settingsControl.extendedRental.value === false">
                        <mat-label>Termini di pagamento</mat-label>
                        <mat-select [formControl]="settingsControl.paymentTerms">
                            <mat-option *ngFor="let term of paymentTerms" [value]="term.value">
                                {{ term.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input">
                        <mat-label *ngIf="settingsControl.extendedRental.value === true">Modalità di pagamento mensile*</mat-label>
                        <mat-label *ngIf="settingsControl.extendedRental.value === false">Modalità di pagamento*</mat-label>
                        <mat-select [formControl]="settingsControl.paymentMode">
                            <mat-option *ngFor="let mode of paymentModes" [value]="mode.value">
                                {{ mode.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input" *ngIf="settingsControl.extendedRental.value === true">
                        <mat-label>Totale mensile</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="monthlyPaymentsAmount"
                            [formControl]="settingsControl.monthlyPaymentsAmount">
                    </mat-form-field>
                    <mat-form-field appearance="outline" class="form-input shipping-address">
                        <mat-label>Indirizzo spedizione*</mat-label>
                        <input matInput placeholder="" [formGroup]="addContractForm" name="shippingAddress"
                            [formControl]="settingsControl.shippingAddress">
                    </mat-form-field>
                </div>
                <br>
                <button mat-raised-button color="primary" type="submit" class="float-right"
                    [disabled]="!addContractForm.valid ">Salva</button>
                </form>
                </div>