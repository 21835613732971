import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-page',
  templateUrl: './invoice-page.component.html',
  styleUrls: ['./invoice-page.component.scss']
})
export class InvoicePageComponent implements OnInit {
  @Input() selectedInvoice;
  constructor() {

  }

  ngOnInit(): void {
  }

}
