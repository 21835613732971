import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateDdtGQL,
  UpdateDdtGQL,
  CreateDdtInput,
  UpdateDdtInput,
  DdTsDocument,
  DdTsGQL,
  DdtDetailFragment,
  Ddt
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class DDTsService {

  constructor(
    private readonly ddtsService: DdTsGQL,
    private readonly createDDTService: CreateDdtGQL,
    private readonly updateDDTService: UpdateDdtGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  getDDTs(filter?: any, first?: number, after?: string) {
    return this.ddtsService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.ddts.edges.map(el => {return { ...el.node, ddtCode: this.formatDDTCode(el.node)} as unknown as Ddt});
    }));
  }

  moreDDTs(filter?: any, first?: number, after?: string) {
    return this.ddtsService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  /*mutations*/
  addNewDDT(payload: CreateDdtInput) {
    // return this.createDDTService.mutate({ data: payload }, {refetchQueries: [{query: DdTsDocument}]});
    return this.createDDTService.mutate({ data: payload });
  }

  updateDDT(payload: UpdateDdtInput) {
    return this.updateDDTService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateDDT;
    }));
  }

  formatDDTCode(ddt: DdtDetailFragment) {
    return `${ddt.ddtCode}/${new Date(ddt.date).getFullYear()}`;
  }

}
