<ngx-extended-pdf-viewer
  [src]="currentPdf"
  backgroundColor="#ffffff"
  [height]="'100%'"
  [useBrowserLocale]="true"
  [handTool]="false"
  [showHandToolButton]="true"
  [zoom]="'75%'"
>
</ngx-extended-pdf-viewer>

