import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateInvoiceRentalLinkGQL,
  CreateInvoiceRentalLinkInput,
  DeleteInvoiceRentalLinkGQL,
  DeleteInvoiceRentalLinkInput,
  InvoicesDocument,
  UpdateInvoiceRentalLinkGQL,
  UpdateInvoiceRentalLinkInput
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class InvoiceRentalLinksService {

  constructor(
    private readonly createInvoiceRentalLinkService: CreateInvoiceRentalLinkGQL,
    private readonly updateInvoiceRentalLinkService: UpdateInvoiceRentalLinkGQL,
    private readonly deleteInvoiceRentalLinkService: DeleteInvoiceRentalLinkGQL,
  ) { }

  /*mutations*/
  addNewInvoiceRentalLink(payload: CreateInvoiceRentalLinkInput) {
    return this.createInvoiceRentalLinkService.mutate({ data: payload });
  }

  updateInvoiceRentalLink(payload: UpdateInvoiceRentalLinkInput) {
    return this.updateInvoiceRentalLinkService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateInvoiceRentalLink;
    }));
  }

  deleteInvoiceRentalLink(payload: DeleteInvoiceRentalLinkInput) {
    // return this.deleteInvoiceRentalLinkService.mutate({ data: payload }, {refetchQueries: [{query: InvoicesDocument}]}).pipe(map(val => {
    return this.deleteInvoiceRentalLinkService.mutate({ data: payload }).pipe(map(val => {
      return val.data.deleteInvoiceRentalLink;
    }));
  }

}
