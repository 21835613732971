import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CreateWorkReportGQL,
  UpdateWorkReportGQL,
  CreateWorkReportInput,
  UpdateWorkReportInput,
  WorkReportsGQL,
  WorkReportsDocument,
  MaintenanceFullDetailFragmentDoc,
  MaintenancesDocument,
  WorkReportsListGQL,
  WorkReportGQL,
  WorkReportDocument
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class WorkReportsService {

  constructor(
    private readonly workReportsService: WorkReportsGQL,
    private readonly workReportsListService: WorkReportsListGQL,
    private readonly workReportService: WorkReportGQL,
    private readonly createWorkReportService: CreateWorkReportGQL,
    private readonly updateWorkReportService: UpdateWorkReportGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  // getWorkReports() {
  //   return this.workReportsService.watch().valueChanges.pipe(map(val => {
  //     return val.data.workReports.edges.map(el => el.node);
  //   }));
  // }

  getWorkReportsList(filter?: any, first?: number, after?: string) {
    return this.workReportsListService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.workReports.edges.map(el => el.node);
    }));
  }

  moreWorkReports(filter?: any, first?: number, after?: string) {
    console.log(filter, first, after)
    return this.workReportsListService.watch().fetchMore({variables: {...filter, first, after}}).then(() => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  getWorkReportById(id: string) {
    return this.workReportService.watch({id}).valueChanges.pipe(map(val => {
      return val.data.workReport;
    }));
  }

  /*mutations*/
  addNewWorkReport(payload: CreateWorkReportInput) {
    // return this.createWorkReportService.mutate({ data: payload }, {refetchQueries: [{query: WorkReportsDocument}, {query: MaintenancesDocument}]});
    return this.createWorkReportService.mutate({ data: payload });
  }

  updateWorkReport(payload: UpdateWorkReportInput) {
    // return this.updateWorkReportService.mutate({ data: payload }, {refetchQueries: [{query: WorkReportDocument, variables: {id: payload.workReportId}}]}).pipe(map(val => {
    return this.updateWorkReportService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateWorkReport;
    }));
  }

}
