<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="title">
            <mat-icon svgIcon="flash-circle" class="float-left"></mat-icon>
            <div class="item-div">FILTRI</div>
        </div>
        <div class="form-div">
            <form name="selectFilterForm" #f="ngForm" novalidate>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Codice filtro</mat-label>
                    <input matInput (keyup)="filter($event, 'code')" placeholder="" [formGroup]="selectFilterForm"
                        name="name" [formControl]="settingsControl.code">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input filter-category">
                    <mat-label>Categoria</mat-label>
                    <mat-select (selectionChange)="filter($event, 'category')" [formGroup]="selectFilterForm" name="category" [formControl]="settingsControl.category">
                        <mat-option [value]="''">
                            Tutti
                        </mat-option>
                        <mat-option *ngFor="let cat of filterCategories" [value]="cat.value">
                            {{ cat.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input filter-brand">
                    <mat-label>Marca</mat-label>
                    <mat-select (selectionChange)="filter($event, 'brand')" [formGroup]="selectFilterForm" name="brand" [formControl]="settingsControl.brand">
                        <mat-option [value]="''">
                            Tutti
                        </mat-option>
                        <mat-option *ngFor="let brand of filterBrands" [value]="brand.value">
                            {{ brand.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </mat-card>
</div>