import { Component, OnInit, SimpleChanges } from '@angular/core';

import { EventBusService } from '@services/event-bus.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { BaseComponent } from '@shared/base/base.component';
import { Generator, GeneratorStatus } from '@app/graphql/generated/graphql';
import { Constants } from '@shared/constants';

@Component({
  selector: 'app-clients',
  templateUrl: './generators.component.html',
  styleUrls: ['./generators.component.scss']
})
export class GeneratorsComponent extends BaseComponent implements OnInit {
  // generators: Generator[] = [];
  filteredGenerators: Generator[] = [];
  selectedGenerator: Generator = null;
  currentFilter: {[key: string]: { value, from }} = {};
  maintenanceStatus = GeneratorStatus.Managed;
  constructor(
    private readonly generatorsService: GeneratorsService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.generatorsService.getGeneratorsList({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
        // this.generators = generators;
        this.filteredGenerators = generators;
        // this.filterGenerators();
        if (this.selectedGenerator) {
          const id = this.selectedGenerator.id;
          this.selectedGenerator = this.filteredGenerators.filter((el) => el.id === id)[0];
        }
        this.eventBusService.showSpinnerEvent.next(false);
      }),
      this.eventBusService.filterGeneratorsEvent.subscribe((filter) => {
        console.log(filter)
        this.currentFilter[filter.from] = filter.value ? filter.value : undefined;
        this.filterGenerators();
      })
    );

  }

  ngOnInit(): void {
  }

  selectGenerator(el) {
    this.generatorsService.getGeneratorById(el.id).subscribe((generator: any) => {
      this.selectedGenerator = generator;
    });
  }

  filterGenerators() {
    this.eventBusService.showSpinnerEvent.next(true);
    this.selectedGenerator = null;
    this.generatorsService.getGeneratorsList(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
      this.filteredGenerators = generators;
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  loadMore() {
    if (this.filteredGenerators.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredGenerators[this.filteredGenerators.length - 1].id;
      this.generatorsService.moreGenerators(this.currentFilter, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  // filterGenerators() {
  //   this.filteredGenerators = this.generators;
  //   // tslint:disable-next-line: forin
  //   for (const key in this.currentFilter) {
  //     const filter = this.currentFilter[key];
  //     this.filteredGenerators = this.filteredGenerators.filter((el) => {
  //       if (!filter.from || !filter.value) {
  //         return true;
  //       }
  //       if (filter.from === 'status') {
  //         return el.status === filter.value;
  //       }
  //       if (filter.from === 'serialNumber') {
  //         return el.serialNumber.toLowerCase().includes(filter.value.toLowerCase());
  //       }
  //       if (filter.from === 'kW') {
  //         const perc = 0.2;
  //         let res = filter.value * (1 + perc) >= el.kW;
  //         res = res && filter.value * (1 - perc) <= el.kW;
  //         return res;
  //       }
  //       if (filter.from === 'kVA') {
  //         const perc = 0.2;
  //         let res = filter.value * (1 + perc) >= el.kVA;
  //         res = res && filter.value * (1 - perc) <= el.kVA;
  //         return res;
  //       }
  //       if (filter.from === 'brand') {
  //         return el.brand.toLowerCase().includes(filter.value.toLowerCase());
  //       }
  //       if (filter.from === 'client') {
  //         return el.client.name.toLowerCase().includes(filter.value.toLowerCase());
  //       }
  //       return true;
  //     });
  //   }
  //   this.selectedGenerator = null;
  // }

}
