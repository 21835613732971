import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  GeneratorsGQL,
  CreateGeneratorGQL,
  CreateGeneratorInput,
  UpdateGeneratorGQL,
  UpdateGeneratorInput,
  GeneratorsDocument,
  GeneratorGQL,
  GeneratorsListGQL,
  GeneratorsListDocument
} from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';

@Injectable({
  providedIn: 'root'
})
export class GeneratorsService {

  constructor(
    private readonly generatorsService: GeneratorsGQL,
    private readonly generatorsListService: GeneratorsListGQL,
    private readonly generatorService: GeneratorGQL,
    private readonly createGeneratorService: CreateGeneratorGQL,
    private readonly updateGeneratorService: UpdateGeneratorGQL,
    private readonly eventBusService: EventBusService,
  ) { }

  /*queries*/
  // getGenerators(filter?: any, first?: number, after?: string) {
  //   return this.generatorsService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
  //     return val.data.generators.edges.map(el => el.node);
  //   }));
  // }

  getGeneratorsList(filter?: any, first?: number, after?: string) {
    return this.generatorsListService.watch({...filter, first, after}).valueChanges.pipe(map(val => {
      return val.data.generators.edges.map(el => el.node);
    }));
  }

  moreGenerators(filter?: any, first?: number, after?: string) {
    return this.generatorsListService.watch().fetchMore({variables: {...filter, first, after}}).then((data) => {
      this.eventBusService.showSpinnerEvent.next(false);
    });
  }

  getGeneratorById(id: string) {
    return this.generatorService.watch({id}, { fetchPolicy: 'network-only' }).valueChanges.pipe(map(val => {
      return val.data.generator;
    }));
  }

  /*mutations*/
  addNewGenerator(payload: CreateGeneratorInput) {
    // return this.createGeneratorService.mutate({ data: payload }, {refetchQueries: [{query: GeneratorsListDocument}]}).pipe(map(val => {
    return this.createGeneratorService.mutate({ data: payload }).pipe(map(val => {
      return val.data.createGenerator;
    }));
  }

  updateGenerator(payload: UpdateGeneratorInput) {
    return this.updateGeneratorService.mutate({ data: payload }).pipe(map(val => {
    // return this.updateGeneratorService.mutate({ data: payload }, {refetchQueries: [{query: GeneratorsDocument}]}).pipe(map(val => {
      return val.data.updateGenerator;
    }));
  }

}
