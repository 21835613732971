<div class="form-container">
    <div *ngIf="!selectedAccessory" class="title text-center">INSERISCI ACCESSORIO</div>
    <div *ngIf="selectedAccessory" class="title text-center">MODIFICA ACCESSORIO</div>
    <form name="addAccessoryForm" (ngSubmit)="selectedAccessory ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Nome*</mat-label>
            <input matInput placeholder="" [formGroup]="addAccessoryForm" name="name"
                [formControl]="settingsControl.name">
        </mat-form-field>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice seriale*</mat-label>
            <input matInput placeholder="" [formGroup]="addAccessoryForm" name="serialNumber"
                [formControl]="settingsControl.serialNumber">
        </mat-form-field>

        <br>
        <mat-form-field appearance="outline" class="form-input textarea">
            <mat-label>Note</mat-label>
            <textarea matInput placeholder="" [formGroup]="addAccessoryForm" name="note"
                [formControl]="settingsControl.note" rows="5" cols="40"></textarea>
        </mat-form-field>
        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
            [disabled]="!addAccessoryForm.valid || !showSubmitButton()">Salva</button>
        <button *ngIf="selectedAccessory" mat-raised-button color="error" type="button" class="float-right"
            (click)="resetForm()">Annulla</button>
    </form>
</div>