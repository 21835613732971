import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  CarriersGQL,
  CreateCarrierGQL,
  UpdateCarrierGQL,
  CreateCarrierInput,
  UpdateCarrierInput,
  CarriersDocument
} from '@app/graphql/generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class CarriersService {

  constructor(
    private readonly carriersService: CarriersGQL,
    private readonly createCarrierService: CreateCarrierGQL,
    private readonly updateCarrierService: UpdateCarrierGQL,
  ) { }

  /*queries*/
  getCarriers() {
    return this.carriersService.watch().valueChanges.pipe(map(val => {
      return val.data.carriers.edges.map(el => el.node);
    }));
  }

  /*mutations*/
  addNewCarrier(payload: CreateCarrierInput) {
    return this.createCarrierService.mutate({ data: payload }, {refetchQueries: [{query: CarriersDocument}]});
    // return this.createFilterService.mutate({ data: payload });
  }

  updateCarrier(payload: UpdateCarrierInput) {
    return this.updateCarrierService.mutate({ data: payload }).pipe(map(val => {
      return val.data.updateCarrier;
    }));
  }

}
