<div class="main-container h-100 w-100">
    <div class="no-rental" *ngIf="!selectedRental">SELEZIONA UN NOLEGGIO DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-rental w-100 mt-3" *ngIf="selectedRental">
        <mat-tab-group (selectedTabChange)="tabChanged($event)">
            <mat-tab label="DETTAGLI">
                <mat-card class="card">
                    <app-new-rental [(selectedRental)]="selectedRental"></app-new-rental>
                </mat-card>
            </mat-tab>
            <mat-tab label="FATTURE ASSOCIATE">
                 <mat-card class="card">
                    <app-custom-table class="mt-3" [columns]="{displayedColumns: invoicesDisplayedColumns, columnLabels: invoicesColumnLabels}" [title]="'FATTURE PER IL NOLEGGIO'" [data]="invoices" [modalConfig]='invoicesModalConfig'></app-custom-table>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>