<div class="main-container w-100">
    <div class="no-filter" *ngIf="getRentalsArray().controls.length === 0">Nessun noleggio</div>
    <form [formGroup]="rentalsForms" *ngIf="getRentalsArray().controls.length > 0" class="w-100">
        <div formArrayName="rentalsArray">
            <div *ngFor="let item of getRentalsArray().controls; let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline" class="form-input filter-brand">
                    <mat-label>Noleggio*</mat-label>
                    <input type="text" placeholder="Pick one" aria-label="Number" matInput name="rentalId"
                        formControlName="rentalId" [matAutocomplete]="auto" (keyup)="autocompleteRentals($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnRentals"
                        (optionsScroll)="moreRentals()">
                        <mat-option *ngFor="let rental of filteredRentals" [value]="rental">
                            {{ rental.generator.serialNumber }} - {{ rental.client.name }} -
                            {{ rental.outDate | date: 'dd/MM/yyyy' }}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Totale</mat-label>
                    <input matInput placeholder="" name="amount" formControlName="amount">
                </mat-form-field>
                <button class="remove-btn" (click)="removeItem(i)" mat-button color="warn">
                    <mat-icon class="icons" color="warn" svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>
    </form>
    <div class="form-buttons-div">
        <button mat-flat-button color="primary" class="add-btn" (click)="addItem()">
            <mat-icon class="icons" svgIcon="plus"></mat-icon>
            AGGIUNGI NOLEGGIO
        </button>
    </div>
</div>