<div class="main-container h-100 w-100">
    <div class="select-technician-div">
        <app-select-technician></app-select-technician>
    </div>

    <div class="row no-margin technicians-container">
        <div class="col-3">
            <button class="w-100 add-technician-button" mat-button (click)="createNewTechnician = true; selectedTechnician = null">
                <mat-icon class="new-technician-icon" svgIcon="plus"></mat-icon>
                INSERISCI TECNICO
            </button>
        </div>
        <div class="col-9">
        </div>
        <div class="col-3 h-100 technician-card-div">
            <mat-card class="technician-card pointer" *ngFor="let technician of filteredAccessories" [ngClass]="{selected: selectedTechnician && selectedTechnician.id === technician.id}" (click)="selectTechnician(technician)">
                {{ technician.firstName }} - {{ technician.lastName }}
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="createNewTechnician">
            <mat-card class="card">
                <app-new-technician></app-new-technician>
            </mat-card>
        </div>
        <div class="col-9 h-100" *ngIf="!createNewTechnician">
            <app-technician-page [(selectedTechnician)]="selectedTechnician"></app-technician-page>
        </div>
    </div>
</div>