import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-technician-page',
  templateUrl: './technician-page.component.html',
  styleUrls: ['./technician-page.component.scss']
})
export class TechnicianPageComponent implements OnInit {
  @Input() selectedTechnician;
  constructor() {

  }

  ngOnInit(): void {
  }

}
