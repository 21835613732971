<div class="form-container">
    <div *ngIf="!selectedCarrier" class="title text-center">INSERISCI VETTORE</div>
    <div *ngIf="selectedCarrier" class="title text-center">MODIFICA VETTORE</div>
    <form name="addCarrierForm"  (ngSubmit)="selectedCarrier ? askConfirmation() : submit()" #f="ngForm" novalidate>
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Ragione sociale*</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="name"
                [formControl]="settingsControl.name">
            <mat-hint
                *ngIf="addCarrierForm.controls['name'].errors?.minlength && addCarrierForm.controls['name'].touched">
                La ragione sociale deve essere lunga almeno due caratteri</mat-hint>
        </mat-form-field>


        <mat-form-field appearance="outline" class="form-input">
            <mat-label>P.IVA*</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="VATnumber"
                [formControl]="settingsControl.VATnumber">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice registrazione albo</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="regAlboCode"
                [formControl]="settingsControl.regAlboCode">
        </mat-form-field>

        <mat-form-field *ngIf="selectedCarrier" appearance="outline" class="form-input">
            <mat-label>Codice vettore</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="carrierCode"
                [formControl]="settingsControl.carrierCode">
        </mat-form-field>
        <br>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="phone"
                [formControl]="settingsControl.phone">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Email*</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="phone"
                [formControl]="settingsControl.email">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>PEC</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="pec" [formControl]="settingsControl.pec">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice SDI</mat-label>
            <input matInput placeholder="" [formGroup]="addCarrierForm" name="sdiCode"
                [formControl]="settingsControl.sdiCode">
        </mat-form-field>

        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
            [disabled]="!addCarrierForm.valid || !showSubmitButton()">Salva</button>
        <button *ngIf="selectedCarrier" mat-raised-button color="error" type="button" class="float-right"
            (click)="resetForm()">Annulla</button>
    </form>
</div>