<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="title">
            <mat-icon svgIcon="account" class="float-left"></mat-icon>
            <div class="item-div">TECNICI</div>
        </div>
        <div class="form-div">
            <form name="selectTechnicianForm" #f="ngForm" novalidate>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Cognome tecnico</mat-label>
                    <input matInput (keyup)="filter($event, 'lastName')" placeholder="" [formGroup]="selectTechnicianForm" name="lastName"
                        [formControl]="settingsControl.lastName">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Email tecnico</mat-label>
                    <input matInput (keyup)="filter($event, 'email')" placeholder="" [formGroup]="selectTechnicianForm" name="email"
                        [formControl]="settingsControl.email">
                    <mat-icon matSuffix>search</mat-icon>
                </mat-form-field>
            </form>
        </div>
    </mat-card>
</div>