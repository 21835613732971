<div class="form-container form-container-maintenance">
    <div class="title text-center" *ngIf="!selectedMaintenance">INSERISCI MANUTENZIONE</div>
    <form name="addMaintenanceForm" #f="ngForm" novalidate>
        <mat-form-field *ngIf="!selectedMaintenance" appearance="outline" class="form-input">
            <mat-label>Generatore*</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addMaintenanceForm"
                name="generatorId" [formControl]="settingsControl.generatorId" [matAutocomplete]="auto"
                (keyup)="autocompleteGenerators($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"(optionsScroll)="moreGenerators()">
                <mat-option *ngFor="let generator of filteredGenerators" [value]="generator">
                       {{ generator.generatorCode }} - {{ generator.serialNumber }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Tecnici*</mat-label>
            <mat-select [formControl]="settingsControl.technicianIds" multiple>
                <mat-option *ngFor="let technician of filteredTechnicians | async" [value]="technician.id">
                    {{ technician.firstName }} {{ technician.lastName }}
                </mat-option>
            </mat-select>
            <!--<input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addMaintenanceForm"
                name="technicianIds" [formControl]="settingsControl.technicianIds" [matAutocomplete]="auto"
                (keyup)="autocompleteTechnicians($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let technician of filteredTechnicians | async" [value]="technician.id">
                    {{ technician.firstName }} {{ technician.lastName }}
                </mat-option>
            </mat-autocomplete>-->
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Data manutenzione*</mat-label>
            <input matInput [matDatepicker]="pickerCiao" [formGroup]="addMaintenanceForm" name="startDate"
                [formControl]="settingsControl.startDate">
            <mat-datepicker-toggle matSuffix [for]="pickerCiao"></mat-datepicker-toggle>
            <mat-datepicker #pickerCiao></mat-datepicker>
        </mat-form-field>

        <br>
        <mat-form-field appearance="outline" class="form-input textarea">
            <mat-label>Note</mat-label>
            <textarea matInput placeholder="" [formGroup]="addMaintenanceForm" name="note"
                [formControl]="settingsControl.note" rows="5" cols="40"></textarea>
        </mat-form-field>
        <br>
    </form>
    <div class="title text-center" *ngIf="!selectedMaintenance">Aggiungi task</div>
    <div class="tasks-title text-center" *ngIf="selectedMaintenance">Task manutenzione</div>
    <app-add-tasks [tasksForms]="tasksForms"></app-add-tasks>
    <button mat-raised-button (click)="selectedMaintenance ? askConfirmation() : submit()" color="primary" type="button" class="float-right" [ngClass]="{'save-button': selectedMaintenance}"
        [disabled]="!addMaintenanceForm.valid || !tasksForms.valid">Salva</button>
</div>