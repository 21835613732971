import { Component, OnInit, Input, ViewChild, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';

import { Client, ClientContact } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { EventBusService } from '@services/event-bus.service';
import { NewClientContactComponent } from '@pages/add/new-client-contact/new-client-contact.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-client-contacts-list',
  templateUrl: './client-contacts-list.component.html',
  styleUrls: ['./client-contacts-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientContactsListComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild(MatSort) sort: MatSort;
  @Input() selectedClient: Client = null;

  displayedColumns: string[] = ['lastName', 'firstName', 'phone', 'email'];
  columnLabels = ['Cognome', 'Nome', 'Telefono', 'Email'];
  expandedElement: any | null;
  dataSource = new MatTableDataSource([]);

  constructor(
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
  ) {
    if (this.selectedClient) {
      this.dataSource = new MatTableDataSource(this.selectedClient.contacts);
      this.dataSource.data = this.dataSource.data;
      this.dataSource.sort = this.sort;
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedClient' in changes) {
      this.selectedClient = changes.selectedClient.currentValue;
      this.refreshTable();
    }
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  openContactDetail(contact: ClientContact) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'new-client-contact-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    dialogConfig.data = {
      selectedContact: contact,
      clientId: this.selectedClient.id,
    };

    const dialogRef = this.dialog.open(NewClientContactComponent, dialogConfig);

    dialogRef
      .afterClosed()
      .subscribe(async (res) => {
        if (res) {
        }
      });
  }

  refreshTable() {
      this.dataSource = new MatTableDataSource(this.selectedClient.contacts);
      this.dataSource.data = this.dataSource.data;
      this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
