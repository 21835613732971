import { Component, OnInit } from '@angular/core';
import { Invoice } from '@app/graphql/generated/graphql';
import { InvoicesService } from '@services/invoices/invoices.service';
import { EventBusService } from '@services/event-bus.service';
import { BaseComponent } from '@shared/base/base.component';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent extends BaseComponent implements OnInit {

  selectedInvoice: Invoice = null;
  invoices: Invoice[] = [];
  filteredInvoices: Invoice[] = [];
  createNewInvoice = false;
  currentInvoice: { value, from } = { value: '', from: '' };
  constructor(
    private readonly eventBusService: EventBusService,
    private readonly invoicesService: InvoicesService,
  ) {
    super();
    this.subscriptions.push(
      this.invoicesService.getInvoices().subscribe((invoices: Invoice[]) => {
        this.loadInvoices(invoices);
      }),
      this.eventBusService.filterInvoicesEvent.subscribe((invoice) => this.filterInvoices(invoice)),
      this.eventBusService.reloadInvoicesEvent.subscribe(() => {
        this.invoicesService.getInvoices().subscribe((invoices: Invoice[]) => {
          this.loadInvoices(invoices);
        });
      }),

    );

  }

  ngOnInit(): void {

  }

  selectInvoice(inv) {
    this.createNewInvoice = false;
    this.invoicesService.getInvoiceById(inv.id).subscribe((invoice: Invoice) => {
          this.selectedInvoice = invoice;
    });

  }

  filterInvoices(invoice: { value, from }) {
    if (!invoice.from || !invoice.value) {
      this.filteredInvoices = this.invoices;
      return;
    }
    this.currentInvoice = invoice;
    this.filteredInvoices = this.invoices.filter((el) => {
      if (invoice.from === 'number') {
        return el.number === invoice.value;
      }
      if (invoice.from === 'amount') {
        return el.amount === invoice.value;
      }
      return true;
    });
  }

  loadInvoices(invoices: Invoice[]) {
    this.invoices = invoices;
    this.filteredInvoices = this.invoices;
    this.filterInvoices(this.currentInvoice);
    if (this.selectedInvoice) {
      const id = this.selectedInvoice.id;
      this.selectedInvoice = this.invoices.filter((el) => el.id === id)[0];
    }
  }
}
