<div class="form-container">
    <div class="title text-center">INSERISCI ANAGRAFICA CLIENTE</div>
    <form name="addClientForm" (ngSubmit)="selectedClient ? askConfirmation() : submit()" #f="ngForm" novalidate>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Ragione sociale*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="name" [formControl]="settingsControl.name">
            <mat-hint
                *ngIf="addClientForm.controls['name'].errors?.minlength && addClientForm.controls['name'].touched">
                La ragione sociale deve essere lunga almeno due caratteri</mat-hint>
        </mat-form-field>

        
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>P.IVA*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="VATnumber"
            [formControl]="settingsControl.VATnumber">
        </mat-form-field>
        
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice fiscale</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="fiscalCode"
            [formControl]="settingsControl.fiscalCode">
        </mat-form-field>

        <mat-form-field *ngIf="selectedClient" appearance="outline" class="form-input">
            <mat-label>Codice cliente</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="clientCode"
                [formControl]="settingsControl.clientCode">
        </mat-form-field>
        <br>
        
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Indirizzo*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="address"
                [formControl]="settingsControl.address">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>CAP*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="postCode"
                [formControl]="settingsControl.postCode">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Città*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="city" [formControl]="settingsControl.city">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Provincia*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="province"
                [formControl]="settingsControl.province">
        </mat-form-field>

        <br>

        <!--<mat-form-field appearance="outline" class="form-input">
            <mat-label>Regione/Stato*</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="region"
                [formControl]="settingsControl.region">
        </mat-form-field>-->
        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Paese*</mat-label>
            <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addClientForm"
                name="country" [formControl]="settingsControl.country" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                    {{ country }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <br>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="phone"
                [formControl]="settingsControl.phone">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Email</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="phone"
                [formControl]="settingsControl.email">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>PEC</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="pec" [formControl]="settingsControl.pec">
        </mat-form-field>

        <mat-form-field appearance="outline" class="form-input">
            <mat-label>Codice SDI</mat-label>
            <input matInput placeholder="" [formGroup]="addClientForm" name="sdiCode"
                [formControl]="settingsControl.sdiCode">
        </mat-form-field>


        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right ml-3"
        [disabled]="!addClientForm.valid || !showSubmitButton()">Salva</button>
        <button *ngIf="selectedClient" mat-raised-button color="error" type="button" class="float-right" (click)="resetForm()">Annulla</button>
    </form>
</div>