<div class="main-container w-100 h-100">
    <mat-card class="w-100 h-100">
        <div class="title">
            <mat-icon svgIcon="clipboard-flow" class="float-left"></mat-icon>
            <div class="item-div">NOLEGGI</div>
        </div>
        <div class="form-div">
            <form name="selectRentalForm" #f="ngForm" novalidate>
                <mat-form-field appearance="outline" class="form-input generator-status">
                    <mat-label>Stato</mat-label>
                    <mat-select (selectionChange)="filter($event, 'status')" name="food" [formGroup]="selectRentalForm" name="status" [formControl]="settingsControl.status">
                        <mat-option [value]="''">
                            Tutti
                        </mat-option>
                        <mat-option *ngFor="let status of rentalStatuses" [value]="status.value">
                            {{status.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
        </div>
    </mat-card>
</div>