import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';

import { Rental, Client, Generator, Accessory, CreateRentalInput, UpdateRentalInput, Ddt, GeneratorStatus } from '@app/graphql/generated/graphql';
import { ClientsService } from '@services/clients/clients.service';
import { EventBusService } from '@services/event-bus.service';
import { RentalsService } from '@services/rentals/rentals.service';
import { GeneratorsService } from '@services/generators/generators.service';
import { AccessoriesService } from '@services/accessories/accessories.service';
import { Constants } from '@shared/constants';
import { DDTsService } from '@services/ddts/ddts.service';
import { SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';
import moment from 'moment';

@Component({
  selector: 'app-new-rental',
  templateUrl: './new-rental.component.html',
  styleUrls: ['./new-rental.component.scss']
})
export class NewRentalComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedRental: Rental = null;
  addRentalForm: FormGroup;
  // generators: Generator[] = [];
  // ddts: Ddt[] = [];
  // clients: Client[] = [];
  accessories: Accessory[] = [];
  // filteredGenerators: Observable<Generator[]>;
  filteredGenerators: Generator[] = [];
  filterGenerators: any = { status: GeneratorStatus.Available };
  // filteredDDTs: Observable<Ddt[]>;
  filteredDDTs: Ddt[] = [];
  filterDDTs = {};
  // filteredClients: Observable<Client[]>;
  filteredClients: Client[] = [];
  filterClients = {};
  settingsControl = {
    generatorId: new FormControl('', [Validators.required]),
    clientId: new FormControl('', [Validators.required]),
    inDate: new FormControl('', []),
    outDate: new FormControl('', []),
    ddtId: new FormControl(undefined, []),
    inHours: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    outHours: new FormControl(null, [Validators.pattern('^[0-9]+(.[0-9]{0,2})?$')]),
    note: new FormControl(null, []),
    accessoryIds: new FormControl([], []),
    status: new FormControl('', [Validators.required]),
  };
  private autocompleteGeneratorsSubject: Subject<string> = new Subject();
  private autocompleteDDTsSubject: Subject<string> = new Subject();
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly generatorsService: GeneratorsService,
    private readonly ddtsService: DDTsService,
    private readonly accessoriesService: AccessoriesService,
    private readonly rentalsService: RentalsService,
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewRentalComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addRentalForm = formBuilder.group({
      generatorId: this.settingsControl.generatorId,
      clientId: this.settingsControl.clientId,
      status: this.settingsControl.status,
      ddtId: this.settingsControl.ddtId,
      inHours: this.settingsControl.inHours,
      outHours: this.settingsControl.outHours,
      note: this.settingsControl.note,
      inDate: this.settingsControl.inDate,
      outDate: this.settingsControl.outDate,
      accessoryIds: this.settingsControl.accessoryIds,
    });
    if (dialogData && dialogData.selectedRental) {
      this.selectedRental = dialogData.selectedRental;
    }
  }

  ngOnInit(): void {

    this.eventBusService.showSpinnerEvent.next(true);
    // this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //   this.generators = generators.filter(el => el.status === GeneratorStatus.Available);
    //   this.filteredGenerators = of(this.generators);
    // });
    // this.ddtsService.getDDTs().subscribe((ddts: Ddt[]) => {
    //   this.ddts = ddts;
    //   this.filteredDDTs = of(this.ddts);
    // });
    // this.clientsService.getClients().subscribe((clients: Client[]) => {
    //   this.clients = clients;
    //   this.filteredClients = of(this.clients);
    // });
    this.ddtsService.getDDTs({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((ddts: Ddt[]) => { 
      this.filteredDDTs = ddts;
      this.eventBusService.showSpinnerEvent.next(false);
    })
    this.generatorsService.getGeneratorsList({status: GeneratorStatus.Available}, 6).subscribe((generators: Generator[]) => {
      // this.filteredGenerators.next(generators);
      this.filteredGenerators = generators.filter(el => el.status === GeneratorStatus.Available); // workaround since graphql caches nodes with 'status' as param in graphql.module.ts
      this.filteredGenerators = this.filteredGenerators.filter((value, index, self) =>
          index === self.findIndex((g) => (
              g.serialNumber === value.serialNumber
      ))
)
    });
    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.filteredClients = clients;
    });
    this.accessoriesService.getAccessories().subscribe((accessories: Accessory[]) => {
      this.accessories = accessories;
    });

    // this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredGenerators = of(this.generators);
    //     return;
    //   }
    //   this.generatorsService.getGenerators().subscribe((generators: Generator[]) => {
    //     this.filteredGenerators = of(this._filterGenerators(value));
    //   });
    // });
    // this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     this.filteredClients = of(this.clients);
    //     return;
    //   }
    //   this.clientsService.getClients().subscribe((clients: Client[]) => {
    //     this.filteredClients = of(this._filterClients(value));
    //   });
    // });
    this.autocompleteDDTsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filterDDTs = {};
      } else {
        this.filterDDTs = { carrierName: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.ddtsService.getDDTs({ carrierName: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((ddts: Ddt[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredDDTs = ddts;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    this.autocompleteGeneratorsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (!value) {
        this.filterGenerators = { status: GeneratorStatus.Available };
      } else {
        this.filterGenerators = { serialNumber: value, status: GeneratorStatus.Available };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.generatorsService.getGeneratorsList(this.filterGenerators, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((generators: Generator[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredGenerators = generators;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });

    if (this.selectedRental) {
      this.fillRental();
    } else {
      this.addRentalForm.get('status').valueChanges.subscribe(val => {
        if (val === 'IN') {
          this.addRentalForm.patchValue({
            ddtId: null,
            outHours: null,
            accessoryIds: [],
          });
        } else {
          this.addRentalForm.patchValue({
            note: '',
            inHours: null,
          });
        }
      });
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedRental' in changes) {
      this.selectedRental = changes.selectedRental.currentValue;
      this.fillRental();
    }

  }



  // private _filterGenerators(value: string): Generator[] {
  //   if (value === '') {
  //     this.filteredGenerators = of(this.generators);
  //     return;
  //   }
  //   const filterValue = value.toLowerCase();
  //   return this.generators.filter(el => el.serialNumber.toLowerCase().includes(filterValue));
  // }

  // private _filterClients(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  autocompleteGenerators(ev) {
    this.autocompleteGeneratorsSubject.next(ev.target.value);
  }

  autocompleteDDTs(ev) {
    this.autocompleteDDTsSubject.next(ev.target.value);
  }
  autocompleteClients(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  displayFnGenerators(generator: Generator): string {
    return generator && generator.serialNumber ? `${generator.generatorCode} - ${generator.serialNumber}` : '';
  }

  displayFnDDTs(ddt: Ddt): string {
    if (!ddt) {
      return '';
    }
    // let date: any = new Date(ddt.date);
    // const year = date.getFullYear();
    // let month: any = date.getMonth() + 1;
    // let day: any = date.getDate();
    // if (day < 10) {
    //   day = '0' + day;
    // }

    // if (month < 10) {
    //   month = '0' + month;
    // }
    // date = day + '/' + month + '/' + year;
    console.log(ddt)
    const date = moment(ddt.date).format('DD/MM/YYYY');
    return ddt && ddt.client && ddt.client.name ? (ddt.client.name + ' - ' + date) : '';
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }


  submit() {
    this.eventBusService.showSpinnerEvent.next(true);
    const payload = this.addRentalForm.value;
    payload.generatorId = payload.generatorId.id;
    payload.clientId = payload.clientId.id;
    if (payload.ddtId) {
      payload.ddtId = payload.ddtId.id;
    }
    if (payload.inHours) {
      payload.inHours = parseFloat(payload.inHours);
    }
    if (payload.outHours) {
      payload.outHours = parseFloat(payload.outHours);
    }
    if (payload.ddtId === null) {
      delete payload.ddtId;
    }
    let mutation: Observable<any> = this.rentalsService.addNewRental(payload as CreateRentalInput);
    let message = 'Noleggio inserito con successo';

    if (this.selectedRental) {
      payload.rentalId = this.selectedRental.id;
      mutation = this.rentalsService.updateRental(payload as UpdateRentalInput);
      message = 'Noleggio aggiornato con successo';
    }

    mutation.subscribe(
      (val) => {
        this.eventBusService.showSpinnerEvent.next(false);
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedRental) {
          this.addRentalForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.showSpinnerEvent.next(false);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  fillRental() {
    this.addRentalForm.patchValue({
      ...this.selectedRental,
      clientId: this.selectedRental.client,
      ddtId: this.selectedRental.ddt,
      generatorId: this.selectedRental.generator,
      accessoryIds: this.selectedRental.accessories.map(el => el.id),
    });

  }

  formatDate(date) {
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }

    if (month < 10) {
      month = '0' + month;
    }
    return day + '/' + month + '/' + year;
  }

  moreClients() {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }

  moreGenerators() {
    if (this.filteredGenerators.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredGenerators[this.filteredGenerators.length - 1].id;
      this.generatorsService.moreGenerators(this.filterGenerators, 6, lastId);
    }
  }

  moreDDTs() {
    if (this.filteredDDTs.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      const lastId = this.filteredDDTs[this.filteredDDTs.length - 1].id;
      this.ddtsService.moreDDTs(this.filterDDTs, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }


}
