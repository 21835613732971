import { Component, OnInit, Optional, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable, of, from, Subject } from 'rxjs';

import { ClientsService } from '@services/clients/clients.service';
import { Client, CreateClientContactInput, ClientContact, UpdateClientContactInput } from '@app/graphql/generated/graphql';
import { debounceTime } from 'rxjs/operators';
import { EventBusService } from '@services/event-bus.service';
import { Constants } from '@shared/constants';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

export function checkClientId(controlName: string, self) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const id = control.value.id;
    let res = false;
    if (self.dialogData && self.dialogData.clientId) {
      control.setErrors(null);
      return;
    }
    const clients = self.filteredClients;
    for (const el of clients) {
      if (el.id === id) {
        res = true;
        break;
      }
    }
    if (res) {
      control.setErrors(null);
    } else {
      control.setErrors({ wrongId: true });
    }
  };
}

@Component({
  selector: 'app-new-client-contact',
  templateUrl: './new-client-contact.component.html',
  styleUrls: ['./new-client-contact.component.scss']
})
export class NewClientContactComponent extends SubmitWithConfirmationComponent implements OnInit {
  selectedContact: ClientContact = null;
  addContactForm: FormGroup;
  // clients: Client[] = [];
  filteredClients: Client[] = [];
  filterClients = {};
  settingsControl = {
    clientId: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required, Validators.email]),
  };
  clientId: string;
  private autocompleteClientsSubject: Subject<string> = new Subject();

  constructor(
    private formBuilder: FormBuilder,
    private readonly clientsService: ClientsService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
    @Optional() public dialogRef?: MatDialogRef<NewClientContactComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData?,
  ) {
    super(dialog);
    this.addContactForm = formBuilder.group({
      clientId: this.settingsControl.clientId,
      firstName: this.settingsControl.firstName,
      lastName: this.settingsControl.lastName,
      phone: this.settingsControl.phone,
      email: this.settingsControl.email,
    },
      {
        validator: checkClientId('clientId', this)
      }
    );
    if (dialogData && dialogData.selectedContact) {
      this.selectedContact = dialogData.selectedContact;
    }

    if (dialogData && dialogData.clientId) {
      this.clientId = dialogData.clientId;
    }
  }

  ngOnInit(): void {
    this.eventBusService.showSpinnerEvent.next(true);
    if (this.selectedContact) {
      this.addContactForm.patchValue({
        clientId: this.dialogData.clientId,
        firstName: this.selectedContact.firstName,
        lastName: this.selectedContact.lastName,
        phone: this.selectedContact.phone,
        email: this.selectedContact.email,
      });
    }
    // this.clientsService.getClients().subscribe((clients: Client[]) => {
    //   this.clients = clients;
    //   this.filteredClients = of(this.clients);
    // });
    /*this.filteredClients = this.settingsControl.clientId.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
      );*/
    // this.autocompleteSubject.pipe(debounceTime(500)).subscribe(value => {
    //   if (!value) {
    //     return;
    //   }
    //   this.clientsService.getClients().subscribe((clients: Client[]) => {
    //     this.filteredClients = of(this._filter(value));
    //   });
    // });
    this.clientsService.getClients({}, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
      // this.clients = clients;
      this.eventBusService.showSpinnerEvent.next(false);
      this.filteredClients = clients;
    });

    this.autocompleteClientsSubject.pipe(debounceTime(500)).subscribe(value => {
      if (value === '' && this.filterClients === '') {
        return;
      } else if (!value) {
        this.filterClients = {};
      } else {
        this.filterClients = { name: value };
      }
      this.eventBusService.showSpinnerEvent.next(true);
      this.clientsService.getClients({ name: value }, Constants.PAGINATION_ELEMENTS_NUMBER).subscribe((clients: Client[]) => {
        // this.filteredGenerators.next(generators);
        this.filteredClients = clients;
        this.eventBusService.showSpinnerEvent.next(false);
      });
    });
  }

  // private _filter(value: string): Client[] {
  //   const filterValue = value.toLowerCase();
  //   return this.clients.filter(el => el.name.toLowerCase().includes(filterValue));
  // }

  autocomplete(ev) {
    this.autocompleteClientsSubject.next(ev.target.value);
  }

  displayFnClients(client: Client): string {
    return client && client.name ? client.name : '';
  }

  submit() {
    const selectedClient: Client = this.addContactForm.get('clientId').value;
    const payload = this.addContactForm.value;
    payload.clientId = selectedClient.id;
    let mutation: Observable<any> = this.clientsService.addNewClientContact(payload as CreateClientContactInput);
    let message = 'Responsabile inserito con successo';
    if (this.selectedContact && this.clientId) {
      mutation = this.clientsService.updateClientContact(payload as UpdateClientContactInput);
      message = 'Responsabile aggiornato con successo';
      payload.clientContactId = this.selectedContact.id;
    }

    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedContact) {
          this.addContactForm.reset();
        }
        if (this.dialogRef) {
          this.dialogRef.close();
        }
      },
      (error) => {
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  moreClients() {
    // if (this.filteredGenerators.getValue().length > 0) {
    if (this.filteredClients.length > 0) {
      this.eventBusService.showSpinnerEvent.next(true);
      // const lastId = this.filteredGenerators.getValue()[this.filteredGenerators.getValue().length - 1].id;
      const lastId = this.filteredClients[this.filteredClients.length - 1].id;
      this.clientsService.moreClients(this.filterClients, Constants.PAGINATION_ELEMENTS_NUMBER, lastId);
    }
  }
}
