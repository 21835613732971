import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Invoice, Rental } from '@app/graphql/generated/graphql';
import { NewInvoiceComponent } from '@pages/other/invoices/new-invoice/new-invoice.component';
import { EventBusService } from '@services/event-bus.service';
import { InvoicesService } from '@services/invoices/invoices.service';
import { BaseComponent } from '@shared/base/base.component';

@Component({
  selector: 'app-rental-page',
  templateUrl: './rental-page.component.html',
  styleUrls: ['./rental-page.component.scss']
})
export class RentalPageComponent extends BaseComponent implements OnInit {
  @Input() selectedRental: Rental = null;
  currentTab = 0;
  invoices: Invoice[] = [];


  invoicesDisplayedColumns: string[] = ['number', 'amount'];
  invoicesColumnLabels = ['Numero', 'Totale'];
  invoicesModalConfig: { dialogConfig: MatDialogConfig, component: any, key: string } = null;
  constructor(
    private readonly invoicesService: InvoicesService,
    private readonly eventBusService: EventBusService,
  ) {
    super();
    this.subscriptions.push(
      this.eventBusService.reloadInvoicesEvent.subscribe(() => {

        this.invoicesService.getInvoices(undefined, this.selectedRental.id).subscribe(res => {
          if (res && res.length > 0) {
            this.invoices = this.formatInvoices(res as Invoice[]);
          }
          this.setModal();
        });
      })
    );
  }

  ngOnInit(): void {
  }

  setModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.panelClass = 'new-client-contact-modal';
    dialogConfig.height = 'auto';
    dialogConfig.width = '70%';

    switch (this.currentTab) {
      case 0:
        break;
      case 1:
        dialogConfig.data = {
        };
        this.invoicesModalConfig = {
          dialogConfig,
          key: 'selectedInvoice',
          component: NewInvoiceComponent
        };
        break;
      default:
        break;
    }
  }

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    this.currentTab = tabChangeEvent.index;
    this.setModal();
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedRental' in changes && changes.selectedRental.currentValue) {
      this.selectedRental = changes.selectedRental.currentValue;
      this.invoices = [];
      this.invoicesService.getInvoices(undefined, this.selectedRental.id).subscribe(res => {
        if (res && res.length > 0) {
          this.invoices = this.formatInvoices(res as Invoice[]);
        }
        this.setModal();
      });
    }
  }


  formatInvoices(invoices: Invoice[]) {
    return JSON.parse(JSON.stringify(invoices)).map((el: any) => {
      el.amount = new CurrencyPipe('en-US').transform(el.amount, 'EUR');
      return el;
    });
  }


}
