<div class="main-container h-100 w-100">
    <div class="no-contract" *ngIf="!selectedContract">SELEZIONA UN NOLEGGIO DALLA LISTA PER VEDERLO IN DETTAGLIO</div>
    <div class="with-contract w-100 mt-3" *ngIf="selectedContract">
        <mat-tab-group  (selectedTabChange)="activateTab($event.index)">
           <mat-tab label="PDF" class="h-100">
                <ng-template matTabContent>
                    <app-pdf-viewer *ngIf="toShow" [pdfUrl]="getPDFUrl()"></app-pdf-viewer>
            </ng-template>
            </mat-tab>
            <mat-tab label="DETTAGLI">
                <mat-card class="card">
                    <app-new-contract [(selectedContract)]="selectedContract"></app-new-contract>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>