import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Ddt } from '@app/graphql/generated/graphql';
import { environment } from '@env/environment';

@Component({
  selector: 'app-ddt-page',
  templateUrl: './ddt-page.component.html',
  styleUrls: ['./ddt-page.component.scss']
})
export class DDTPageComponent implements OnInit {
  @Input() selectedDDT: Ddt = null;
  pdfUrl: string = null;
  public toShow = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedDDT' in changes && changes.selectedDDT.currentValue) {
      this.selectedDDT = changes.selectedDDT.currentValue;
      this.pdfUrl = environment.apiUrlPdf + '/pdfs/ddts/' + this.selectedDDT.id;
    }
  }

  getPDFUrl() {
    return this.pdfUrl;
  }


  activateTab(tab: number): void {
    this.hideOtherPDFs();
    setTimeout(() => {
      this.toShow = true;
    }, 100);
  }

  hideOtherPDFs(): void {
    this.toShow = false;
  }

}
