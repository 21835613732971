<div class="main-container w-100 h-100">
    <div class="select-user-div">
        <app-select-rental></app-select-rental>
    </div>
    <div class="row no-margin rentals-container">
        <div class="col-3 h-100 rental-card-div" infiniteScroll [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50" [scrollWindow]="false" (scrolled)="loadMore()">
            <mat-card class="rental-card pointer" *ngFor="let rental of filteredRentals" (click)="selectRental(rental)"
                [ngClass]="{selected: selectedRental && selectedRental.id === rental.id}">{{
                rental.generator.generatorCode}} - {{ rental.client.name }} - {{ rental.outDate | date: 'dd/MM/yyyy' }}
            </mat-card>
        </div>
        <div class="col-9 h-100">
            <app-rental-page [(selectedRental)]="selectedRental"></app-rental-page>
        </div>
    </div>
</div>