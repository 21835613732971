<div class="main-container w-100">
    <div class="no-filter" *ngIf="getGoodsArray().controls.length === 0">Non hai ancora aggiunto nulla</div>
    <form [formGroup]="goodsForms" *ngIf="getGoodsArray().controls.length > 0" class="w-100">
        <div formArrayName="goodsArray">
            <div *ngFor="let item of getGoodsArray().controls; let i = index" [formGroupName]="i">
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Descrizione</mat-label>
                    <input matInput placeholder="" name="description" formControlName="description">
                </mat-form-field>
                <mat-form-field appearance="outline" class="form-input">
                    <mat-label>Quantità</mat-label>
                    <input matInput placeholder="" name="quantity" formControlName="quantity">
                </mat-form-field>
                <button class="remove-btn" (click)="removeItem(i)" mat-button color="warn">
                    <mat-icon class="icons" color="warn" svgIcon="delete"></mat-icon>
                </button>
            </div>
        </div>
    </form>
    <div class="form-buttons-div">
        <button mat-flat-button color="primary" class="add-btn" (click)="addItem()">
            <mat-icon class="icons" svgIcon="plus"></mat-icon>
            AGGIUNGI ALTRO
        </button>
    </div>
</div>