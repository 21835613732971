import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Accessory } from '@app/graphql/generated/graphql';
import { EventBusService } from '@services/event-bus.service';
import { AccessoriesService } from '@services/accessories/accessories.service';
import { Observable } from 'rxjs';

import { Constants } from '@shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SubmitWithConfirmationComponent } from '@shared/submit-with-confirmation/submit-with-confirmation.component';

@Component({
  selector: 'app-new-accessory',
  templateUrl: './new-accessory.component.html',
  styleUrls: ['./new-accessory.component.scss']
})
export class NewAccessoryComponent extends SubmitWithConfirmationComponent implements OnInit {
  @Input() selectedAccessory: Accessory = null;
  addAccessoryForm: FormGroup;
  filterCategories = Constants.FILTER_CATEGORIES;
  filterBrands = Constants.FILTER_BRANDS;
  settingsControl = {
    name: new FormControl('', [Validators.required]),
    serialNumber: new FormControl('', [Validators.required]),
    note: new FormControl('', []),
  };

  constructor(
    private formBuilder: FormBuilder,
    private readonly techniciansService: AccessoriesService,
    private readonly eventBusService: EventBusService,
    private dialog: MatDialog,
  ) {
    super(dialog);
    this.addAccessoryForm = formBuilder.group({
      name: this.settingsControl.name,
      serialNumber: this.settingsControl.serialNumber,
      note: this.settingsControl.note,
    });
  }

  ngOnInit(): void {
    if (this.selectedAccessory) {
      this.addAccessoryForm.patchValue(this.selectedAccessory);
    } /* else {
      // FIXME: remove
      this.addAccessoryForm.patchValue({
        name: 'test',
        serialNumber: 'EXGHC65',
        note: 'Ecco una nota importante',
      });
    }*/
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('selectedAccessory' in changes) {
      this.selectedAccessory = changes.selectedAccessory.currentValue;
      this.addAccessoryForm.patchValue(this.selectedAccessory);
    }
  }

  submit() {
    const payload = this.addAccessoryForm.value;
    let mutation: Observable<any>;
    let message = 'Filtro inserito con successo';
    if (this.selectedAccessory) { // update
      payload.accessoryId = this.selectedAccessory.id; // manually add the clientCode
      mutation = this.techniciansService.updateAccessory(payload);
      message = 'Accessorio aggiornato con successo';
    } else {
      mutation = this.techniciansService.addNewAccessory(payload);
      message = 'Accessorio inserito con successo';
    }
    mutation.subscribe(
      (val) => {
        this.eventBusService.snackBarRequestEvent.next({
          message,
          style: Constants.SNACKBAR_SUCCESS
        });
        if (!this.selectedAccessory) {
          this.addAccessoryForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.eventBusService.snackBarRequestEvent.next({
          errorCode: 'Si è verificato un problema',
          style: Constants.SNACKBAR_ERROR
        });
      }
    );

  }

  showSubmitButton() {
    if (!this.selectedAccessory) { // form for adding a client
      return true;
    } else {
      const formValue = this.addAccessoryForm.value;
      // tslint:disable-next-line: forin
      for (const key in formValue) {
        const val = formValue[key];
        if (this.selectedAccessory[key] !== val) {
          return true;
        }
      }
      return false;
    }
  }

  resetForm() {
    this.addAccessoryForm.patchValue(this.selectedAccessory);
  }

}
