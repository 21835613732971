import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Client } from '@app/graphql/generated/graphql';

@Component({
  selector: 'app-generator-page',
  templateUrl: './generator-page.component.html',
  styleUrls: ['./generator-page.component.scss']
})
export class GeneratorPageComponent implements OnInit {
  @Input() selectedGenerator: Client = null;
  constructor() { }

  ngOnInit(): void {
  }


}
