<div class="form-container">
    <div class="title text-center" *ngIf="!selectedWorkReport">INSERISCI BUONO DI LAVORO</div>
    <div class="title text-center" *ngIf="selectedWorkReport">MODIFICA BUONO DI LAVORO</div>
    <form name="addWorkReportForm" (ngSubmit)="selectedWorkReport ? askConfirmation() : submit()" #f="ngForm"
        novalidate>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input" *ngIf="selectedWorkReport">
                <mat-label>Codice Buono di lavoro*</mat-label>
                <input matInput placeholder=""  disabled="true" [value]="selectedWorkReport.workReportCode">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Cliente*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addWorkReportForm"
                    name="clientId" [formControl]="settingsControl.clientId" [matAutocomplete]="auto"
                    (keyup)="autocompleteClients($event)">
                <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnClients"
                    (optionSelected)="getMaintenances()" (optionsScroll)="moreClients()">
                    <mat-option *ngFor="let client of filteredClients" [value]="client">
                        {{ client.name }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input" *ngIf="settingsControl.clientId.value !== ''">
                <mat-label>Maintenance*</mat-label>
                <input type="text" placeholder="Pick one" aria-label="Number" matInput [formGroup]="addWorkReportForm"
                    name="maintenanceId" [formControl]="settingsControl.maintenanceId"
                    [matAutocomplete]="autoMaintenances" (keyup)="autocompleteMaintenances($event)">
                <mat-autocomplete #autoMaintenances="matAutocomplete" [displayWith]="displayFnMaintenances">
                    <mat-option *ngFor="let maintenance of filteredMaintenances | async" [value]="maintenance">
                        {{ maintenance.generator.generatorCode }} - {{ maintenance.startDate | date: 'dd/MM/yyyy' }}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Km Totali</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="totalKm"
                    [formControl]="settingsControl.totalKm">
            </mat-form-field>
        </div>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Tecnico*</mat-label>
                <mat-select [formControl]="settingsControl.technicianIds" multiple>
                    <mat-option *ngFor="let technician of technicians" [value]="technician.id">
                        {{ technician.firstName }} {{ technician.lastName }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Livello gasolio cisterna</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="cisternFuelLevel"
                    [formControl]="settingsControl.cisternFuelLevel">
            </mat-form-field>

            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Livello gasolio tanica</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="tankFuelLevel"
                    [formControl]="settingsControl.tankFuelLevel">
            </mat-form-field>
        </div>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>N. ore gruppo</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="generatorHours"
                    [formControl]="settingsControl.generatorHours">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>N. ore di lavoro</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="workHours"
                    [formControl]="settingsControl.workHours">
            </mat-form-field>
            <mat-form-field appearance="outline" class="form-input">
                <mat-label>Tipo intervento*</mat-label>
                <mat-select [formControl]="settingsControl.operationType">
                    <mat-option *ngFor="let op of operationTypes" [value]="op.value">
                        {{ op.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="shared-form">
            <mat-checkbox [formGroup]="addWorkReportForm" name="status" class="checkbox"
                [formControl]="settingsControl.lunchBreak">Pausa pranzo</mat-checkbox>

            <mat-checkbox [formGroup]="addWorkReportForm" name="status" class="checkbox"
                [formControl]="settingsControl.registerCompiled">Registro compilato</mat-checkbox>

            <mat-checkbox [formGroup]="addWorkReportForm" name="status" class="checkbox"
                [formControl]="settingsControl.positiveOperation">Operazione con successo</mat-checkbox>
            <mat-form-field appearance="outline" class="form-input"
                *ngIf="settingsControl.registerCompiled.value !== true">
                <mat-label>Motivo registro non compilato</mat-label>
                <input matInput placeholder="" [formGroup]="addWorkReportForm" name="registerNotCompiledReason"
                    [formControl]="settingsControl.registerNotCompiledReason">
            </mat-form-field>
        </div>
        <div class="shared-form">
           
            <mat-form-field appearance="outline" class="form-input tasks-mat-form-field">
                <mat-label>Task</mat-label>
                <mat-select [formControl]="settingsControl.tasks" multiple>
                    <mat-option *ngFor="let task of tasks" [value]="task.value">
                        <span *ngIf="!task.description">{{ task.name }}</span>
                        <span *ngIf="task.description">
                            {{ task.name }}
                            <mat-icon svgIcon="information-outline" [matTooltip]="task.description" color="primary">
                            </mat-icon>
                        </span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="shared-form">
            <mat-form-field appearance="outline" class="form-input textarea">
                <mat-label>Note</mat-label>
                <textarea matInput placeholder="" [formGroup]="addWorkReportForm" name="note"
                    [formControl]="settingsControl.note" rows="5" cols="40"></textarea>
            </mat-form-field>
        </div>
        <br>
        <button mat-raised-button color="primary" type="submit" class="float-right"
            [disabled]="!addWorkReportForm.valid ">Salva</button>
    </form>
</div>